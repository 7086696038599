import GPX from '../lib/GPX/Gpx'

export type GeoFile = GeoJsonFile | GpxFile

type GeoJsonFile = {
  _id?: string
  content: Object
  filename: string
  visible: boolean
}

export type GpxFile = {
  _id?: string
  content: GPX
  contentStr: string
  filename: string
  visible: boolean
}

export function isGpxFile(file: GeoFile): file is GpxFile {
  return file.content instanceof GPX
}
