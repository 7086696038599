import L, { LatLngExpression, PolylineOptions } from 'leaflet'
import React, { useContext, useEffect, useState } from 'react'
import '../leaflet-plugins/leaflet-textpath'
import LeafletMarker from '../leaflet-react/components/Marker'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface BalladRouteProps {
  latlngs: LatLngExpression[]
  options?: PolylineOptions
  text?: string
  centerAndZoom: boolean
}
export default function BalladRoute({
  latlngs,
  text,
  options,
  centerAndZoom,
}: BalladRouteProps) {
  const { state } = useContext(LeafletContext)
  const [polyline, setPolyline] = useState<L.Polyline | undefined>(undefined)
  const textColor = options?.color

  useEffect(() => {
    if (state.map === undefined) return
    if (latlngs.length === 0) return

    const poly = L.polyline(latlngs, options).addTo(state.map)
    setPolyline(poly)

    return () => {
      poly.remove()
      setPolyline(undefined)
    }
  }, [latlngs, options, state.map])

  useEffect(() => {
    if (!polyline) return

    if (text) {
      // @ts-ignore
      polyline.setText(text, {
        repeat: true,
        attributes: {
          ...(textColor && { fill: textColor }),
        },
      })
    }

    return () => {
      // @ts-ignore
      polyline && polyline.setText()
    }
  }, [text, polyline, textColor])

  useEffect(() => {
    if (state.map === undefined) return
    if (polyline === undefined) return

    if (centerAndZoom) {
      state.map.fitBounds(polyline.getBounds())
    }
  }, [centerAndZoom, polyline, state.map])

  return (
    <>
      <LeafletMarker
        key='route-end'
        latlng={latlngs[latlngs.length - 1]}
        icon={{
          iconUrl: '/route-end.png',
          iconSize: [17, 17],
          iconAnchor: [5, 5],
        }}
      />
      <LeafletMarker
        key='route-start'
        latlng={latlngs[0]}
        icon={{
          iconUrl: '/route-start.png',
          iconSize: [17, 17],
          iconAnchor: [8, 8],
        }}
      />
    </>
  )
}

BalladRoute.displayname = 'LeafletBalladRoute'

BalladRoute.defaultProps = {
  centerAndZoom: true,
  text: '      ►      ',
  options: { color: 'black' },
}
