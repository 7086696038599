import { useMemo, useState } from 'react'
import { getStatus } from '../../../@digimap/lib/ballad'
import {
  Ballad,
  PublicTransportStationsGroupedByLines,
} from '../../../@digimap/types'
import isBalladDistanceBetween from '../lib/isBalladDistanceBetween'
import isBalladStartingOrEndingAt from '../lib/isBalladStartingOrEndingAt'

const useFilterableBallads = (
  allBallads: Ballad[],
  publicTransportGroupedByLines: PublicTransportStationsGroupedByLines,
  distanceSelectOptions: { label: string; value: string }[],
  displayDraft: boolean,
  balladStatus?: string,
  balladType?: string
) => {
  const [distanceFilter, setDistanceFilter] = useState<string>(
    distanceSelectOptions[0].value
  )

  const [publicTransportLineFilter, setPublicTransportLineFilter] = useState<
    null | string
  >(null)

  const ballads = useMemo(() => {
    const lineStationsFilter =
      publicTransportGroupedByLines[publicTransportLineFilter || ''] || null
    const filteredBallads = allBallads.filter((ballad) => {
      if (!displayDraft && ballad.draft === true) return false
      if (balladType && ballad.type !== balladType) return false
      if (balladStatus && getStatus(ballad) !== balladStatus) return false
      if (
        lineStationsFilter &&
        lineStationsFilter.every(
          (id) => !isBalladStartingOrEndingAt(ballad, id)
        )
      )
        return false
      if (distanceFilter && !isBalladDistanceBetween(ballad, distanceFilter))
        return false
      return true
    })
    return filteredBallads
  }, [
    allBallads,
    balladType,
    balladStatus,
    publicTransportGroupedByLines,
    publicTransportLineFilter,
    displayDraft,
    distanceFilter,
  ])

  return {
    ballads,
    setDistanceFilter,
    distanceFilter,
    setPublicTransportLineFilter,
    publicTransportLineFilter,
  }
}
export default useFilterableBallads
