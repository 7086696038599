import React from 'react'
import List from '@mui/material/List'
import { GeoFile, isGpxFile } from '../../../types/geoFiles'

import GeoFilesListItem from './GeoFilesListItem'
import GeoJson from '../../../components/leaflet/GeoJson'
import Gpx from '../../../components/leaflet/Gpx'
import { Feature } from 'geojson'
interface GeoFilesListProps {
  geoFiles: GeoFile[]
  onGeoFileVisibilityChange: (filename: string, visible: boolean) => void
}
export default function GeoFilesList({
  geoFiles,
  onGeoFileVisibilityChange,
}: GeoFilesListProps) {
  return (
    <>
      <List>
        {geoFiles.map((geoFile) => (
          <GeoFilesListItem
            key={geoFile.filename}
            content={geoFile.content}
            visible={geoFile.visible}
            filename={geoFile.filename}
            onToggleVisible={onGeoFileVisibilityChange}
          />
        ))}
      </List>
      {geoFiles.map((geoFile) => {
        if (!isGpxFile(geoFile)) {
          return (
            <GeoJson
              key={geoFile.filename}
              content={geoFile.content as Feature}
              visible={geoFile.visible}
              centerAndZoom
            />
          )
        } else {
          return (
            <Gpx
              key={geoFile.filename}
              content={geoFile.contentStr}
              visible={geoFile.visible}
              centerAndZoom
            />
          )
        }
      })}
    </>
  )
}
