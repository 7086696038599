enum ACTION_TYPES {
  SET_MAP_POINT = 'SET_MAP_POINT',
}

type MapPointsState = google.maps.LatLngLiteral[]

export const initialState: MapPointsState = []

export function addPointOnMap(
  { lat, lng }: google.maps.LatLngLiteral,
  index?: number
) {
  return {
    type: ACTION_TYPES.SET_MAP_POINT,
    point: {
      lat,
      lng,
    },
    index,
  }
}

type MapPointsAction = {
  type: ACTION_TYPES.SET_MAP_POINT
  point: google.maps.LatLngLiteral
  index?: number
}

const setMapPoint = (
  points: google.maps.LatLngLiteral[],
  { lat, lng }: google.maps.LatLngLiteral,
  index?: number
) => {
  if (typeof index !== 'number') {
    index = points.length
  }
  const gap = index < points.length ? 0 : index - points.length
  const fillArray = Array(gap).fill(null)
  return [
    ...points.slice(0, index),
    ...fillArray,
    {
      ...points[index],
      ...{
        lat,
        lng,
      },
    },
    ...points.slice(index + 1),
  ]
}

function reducer(
  state: MapPointsState = initialState,
  action: MapPointsAction
): MapPointsState {
  switch (action.type) {
    case ACTION_TYPES.SET_MAP_POINT:
      return setMapPoint(state, action.point, action.index)
    default:
      return state
  }
}

export default reducer
