/*
 * Encoding and decoding of paths are handled by the static methods encodePath() and decodePath in the google.maps.geometry.encoding namespace. See the Encoded Polyline Algorithm Format for information about the encoding scheme.
 */
import polyline from '@mapbox/polyline'

export function decodePath(encoded: string): google.maps.LatLngLiteral[] {
  return polyline.decode(encoded).map(([lat, lng]) => ({ lat, lng }))
}

export function encodePath(
  latLngPoints: (google.maps.LatLngLiteral | google.maps.LatLng)[]
): string {
  return polyline.encode(
    latLngPoints.map(({ lat, lng }) => {
      return [
        typeof lat === 'function' ? lat() : lat,
        typeof lng === 'function' ? lng() : lng,
      ]
    })
  )
}
