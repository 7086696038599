export default function simplifyPath(
  points: google.maps.LatLngLiteral[],
  minDelta: number = 0.0001
) {
  let last: google.maps.LatLngLiteral
  return points.filter(p => {
    if (!last) {
      last = p
      return true
    }
    const latDiff = p.lat - last.lat
    const lngDiff = p.lng - last.lng
    if (Math.sqrt(latDiff * latDiff + lngDiff * lngDiff) > minDelta) {
      last = p
      return true
    }
    return false
  })
}
