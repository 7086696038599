import Link from '@mui/material/Link'
import React, { useContext } from 'react'
import {
  PublicTransportStationFeature,
  PublicTransportStationWithStartEndFeature,
} from '../../@digimap/types/publicTransport'
import { UserContext } from '../../state/user/UserProvider'
import { getCityMapperLink } from './lib/getCityMapperLink'

export default function CityMapperLink({
  feature,
  type,
}: {
  feature:
    | PublicTransportStationWithStartEndFeature
    | PublicTransportStationFeature
  type: 'inbound' | 'outbound'
}) {
  const { from } = useContext(UserContext)

  return (
    <Link
      href={getCityMapperLink({
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
        name: feature.properties.name,
        ...(from && {
          fromLat: from.lat,
          fromLng: from.lng,
        }),
        type,
      })}
      target='_blank'>
      {feature.properties.name} / {feature.properties.line}
    </Link>
  )
}
