/* global google */
import { useContext, useEffect, useRef } from 'react'
import { Overlay } from '../../@digimap/types'
import { CDN_ENDPOINT } from '../../constants/endpoints'
import { GoogleMapContext } from '../googlemaps-react/contexts/GoogleMapContext'
import ImageOverlayLoader, { ImageOverlayClass } from './ImageOverlayClass'

interface ImageOverlayProps {
  image: Overlay
}

function ImageOverlay({ image }: ImageOverlayProps) {
  const { state } = useContext(GoogleMapContext)
  const { filename } = image

  let overlayRef = useRef<ImageOverlayClass | undefined>()

  useEffect(() => {
    if (state.map === undefined) return
    if (!Array.isArray(image.bounds)) return

    const [sw, ne] = image.bounds

    const bounds = new google.maps.LatLngBounds(
      { lat: sw[0], lng: sw[1] },
      { lat: ne[0], lng: ne[1] }
    )
    const ImageOverlayClass = ImageOverlayLoader()
    overlayRef.current = new ImageOverlayClass(
      bounds,
      `${CDN_ENDPOINT}/${filename}`,
      state.map
    )
    state.map.fitBounds(bounds)

    return () => {
      if (overlayRef && overlayRef.current) {
        overlayRef.current.setMap(null)
      }
    }
  }, [filename, image, state.map])

  return null
}

export default ImageOverlay
