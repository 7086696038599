import React, { useCallback, useContext, useMemo } from 'react'
import { PublicTransportContext } from '../state/publicTransport/PublicTransportProvider'

const defaultValue = ''

interface PublicTransportLineFormControlSelectProps {
  onChange: (line: string) => void
  value: string | null
}
export default function PublicTransportLineFormControlSelect(
  props: PublicTransportLineFormControlSelectProps
) {
  const { groupedByLines } = useContext(PublicTransportContext)
  const lines = useMemo(() => {
    return Object.keys(groupedByLines).sort()
  }, [groupedByLines])
  const { onChange } = props

  const onChangeMemo = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <select
      style={{ width: 120, padding: 4 }}
      value={props.value || defaultValue}
      onChange={onChangeMemo}>
      <option aria-label='All Lines' value={defaultValue}>
        All Lines
      </option>
      {lines.map((line) => {
        return (
          <option key={line} value={line}>
            {line}
          </option>
        )
      })}
    </select>
  )
}
