import React from 'react'

interface FilesDropZoneProps {
  dropHandler: (files: FileList) => void
  children: string
}
function FilesDropZone(props: FilesDropZoneProps) {
  const color = 'grey'
  return (
    <div
      style={{
        display: 'block',
        border: `2px dashed ${color}`,
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.border = `2px solid ${color}`
      }}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.currentTarget.style.border = `2px dashed ${color}`
      }}
      onDragOver={(e: React.DragEvent) => e.preventDefault()}
      onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget.style.border = `2px dashed ${color}`
        props.dropHandler(e.dataTransfer.files)
      }}>
      {props.children}
    </div>
  )
}

export default FilesDropZone
