/* global google */

export interface ImageOverlayClass extends google.maps.OverlayView {}

function ImageOverlayLoader() {
  class ImageOverlay extends google.maps.OverlayView {
    private bounds_: google.maps.LatLngBounds
    private image_: string
    private div_: HTMLElement | null
    constructor(
      bounds: google.maps.LatLngBounds,
      image: string,
      map: google.maps.Map
    ) {
      super()
      this.bounds_ = bounds
      this.image_ = image

      // Define a property to hold the image's div. We'll
      // actually create this div upon receipt of the onAdd()
      // method so we'll leave it null for now.
      this.div_ = null

      // Explicitly call setMap on this overlay.
      this.setMap(map)
    }

    private modifyOpacity(e: KeyboardEvent) {
      if (e.keyCode >= 48 && e.keyCode <= 57) {
        this.setOpacity((e.keyCode - 48) / 10)
      }
    }
    private setOpacity(opacity: string | number) {
      if (this.div_) {
        this.div_.style.opacity =
          typeof opacity === 'string' ? opacity : opacity.toString()
      }
    }
    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      const div = document.createElement('div')
      div.style.opacity = '0.5'
      div.style.borderStyle = 'none'
      div.style.borderWidth = '0px'
      div.style.position = 'absolute'

      const img = document.createElement('img')
      img.src = this.image_
      img.style.width = '100%'
      img.style.height = '100%'
      img.style.position = 'absolute'

      div.appendChild(img)

      this.div_ = div

      document.addEventListener('keyup', this.modifyOpacity.bind(this), false)

      // Add the element to the "overlayLayer" pane.
      this.getPanes().overlayLayer.appendChild(div)
    }
    // The onRemove() method will be called automatically from the API if
    // we ever set the overlay's map property to 'null'.
    onRemove() {
      if (this.div_ && this.div_.parentNode) {
        this.div_.parentNode.removeChild(this.div_)
        document.removeEventListener('keyup', this.modifyOpacity)
        this.div_ = null
      }
    }
    draw() {
      if (this.div_) {
        const overlayProjection = this.getProjection()
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds_.getSouthWest()
        )
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds_.getNorthEast()
        )
        // Resize the image's div to fit the indicated dimensions.

        this.div_.style.left = sw.x + 'px'
        this.div_.style.top = ne.y + 'px'
        this.div_.style.width = ne.x - sw.x + 'px'
        this.div_.style.height = sw.y - ne.y + 'px'
        //div.style.transform = "rotate(45deg)";
      }
    }
  }
  return ImageOverlay
}
export default ImageOverlayLoader
