import { decode } from '@mapbox/polyline'
import L from 'leaflet'
import React, { useContext, useEffect, useState } from 'react'
import { isFullyOverlayed, isRouted } from '../../@digimap/lib/ballad'
import { Ballad } from '../../@digimap/types'
import getUnionBounds from '../../lib/getUnionBounds'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface BalladSilhouetteProps {
  ballad: Ballad
  isHighlighted: boolean
  isCompletedByUser: boolean
  onClick?: (ballad: Ballad) => void
  onMouseOver?: (ballad: Ballad) => void
  onMouseOut?: (ballad: Ballad) => void
}

/** Leaflet Ballad Silhouette = display a rectangle, a polyline or a polygon based on track or bounds
 * 
|   |   |
|---|---|
| Dumb vs Smart | dumb even though business logic involved to determine shape |
| Controlled vs Uncontrolled | Not totally controlled (isHighlighted) |
| Presentational vs Container | Renders nulls, uses only ref |
| useContext? | Yes (Leaflet) |
| Stateless? | Stateless (uses only ref) |
| Pure (Deterministic)?  |  No (isHighlighted) |
| Memoized? | No |
 */

function BalladSilhouette({
  ballad,
  isHighlighted,
  onClick,
  onMouseOut,
  onMouseOver,
  isCompletedByUser,
}: BalladSilhouetteProps) {
  const fillOpacity = 0.2
  const fillOpacityHover = 0.0

  const {
    state: { map },
  } = useContext(LeafletContext)

  let [silhouetteRef, setSilhouette] = useState<
    L.Polygon | L.Polyline | L.Rectangle | undefined
  >()

  useEffect(() => {
    if (!map) return
    if (!silhouetteRef) return

    silhouetteRef.addTo(map)

    // @todo: should it be setting isHighlighted
    silhouetteRef.on('mouseover', () => {
      onMouseOver && onMouseOver(ballad)
    })
    silhouetteRef.on('mouseout', () => {
      onMouseOut && onMouseOut(ballad)
    })

    onClick &&
      silhouetteRef.on('click', () => {
        onClick(ballad)
      })

    return () => {
      if (silhouetteRef) {
        silhouetteRef.off()
        silhouetteRef.remove()
      }
    }
  }, [silhouetteRef, onClick, onMouseOver, onMouseOut, map, ballad])

  useEffect(() => {
    const silhouetteColor = ballad.draft ? '#ff5726' : '#3e51b5'
    if (ballad.images === undefined) return

    const shapeOptions = {
      fillColor: silhouetteColor,
      fillOpacity,
      weight: 1,
      color: silhouetteColor,
      opacity: 1,
    }

    let silhouette: L.Polygon | L.Polyline | L.Rectangle

    if (isRouted(ballad)) {
      silhouette = L[ballad.loop ? 'polygon' : 'polyline'](
        decode(ballad.track as string),
        {
          ...shapeOptions,
          weight: ballad.loop ? 1 : 3,
        }
      )
      setSilhouette(silhouette)
    } else if (isFullyOverlayed(ballad)) {
      const unionBounds = getUnionBounds(ballad)
      if (unionBounds !== null) {
        silhouette = L.rectangle(unionBounds, shapeOptions)
        setSilhouette(silhouette)
      }
    }

    return () => {
      setSilhouette(undefined)
    }
  }, [ballad])

  useEffect(() => {
    if (silhouetteRef) {
      silhouetteRef.setStyle({
        dashArray: isCompletedByUser ? undefined : '4',
      })
    }
  }, [isCompletedByUser, silhouetteRef])

  useEffect(() => {
    silhouetteRef &&
      silhouetteRef.setStyle({
        fillOpacity: isHighlighted ? fillOpacityHover : fillOpacity,
      })
  }, [isHighlighted, silhouetteRef])

  return null
}

BalladSilhouette.defaultProps = {
  isHighlighted: false,
  isCompletedByUser: true,
  onClick: null,
  onMouseOut: null,
  onMouseOver: null,
}

BalladSilhouette.displayName = 'BalladSilhouette'

export default React.memo(BalladSilhouette)
