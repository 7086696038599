import React, { useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { getBalladByIdOrSlug } from '../../@digimap/lib/ballad'
import { BalladContext } from '../../state/ballads/BalladProvider'
import OverlayPositioner from './components/OverlayPositioner/OverlayPositioner'

export interface AdminOverlayContainerProps
  extends RouteComponentProps<{
    idOrSlug: string
    index: string
  }> {}
export default function AdminOverlayContainer(
  props: AdminOverlayContainerProps
) {
  const { ballads } = useContext(BalladContext)
  const ballad = getBalladByIdOrSlug(props.match.params.idOrSlug, ballads)
  const overlayIndex = Number(props.match.params.index) - 1
  const overlay = ballad && ballad.images[overlayIndex]

  if (!overlay) return <Redirect to='/not-found' />

  return <OverlayPositioner overlay={overlay} />
}
