import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { GoogleMapProvider } from './components/googlemaps-react/contexts/GoogleMapContext'
import { LeafletProvider } from './components/leaflet-react/contexts/LeafletContext'
import GlobalStyles from './GlobalStyles'
import AdminBalladDetailsContainer from './pages/AdminBalladDetailsPage/AdminBalladDetailsContainer'
import AdminBalladFormContainer from './pages/AdminBalladFormPage/AdminBalladFormContainer'
import AdminOverlayContainer from './pages/AdminOverlayPage/AdminOverlayContainer'
import AdminBalladsContainer from './pages/AllBalladsPage/AdminBalladsContainer'
import HomeBalladsContainer from './pages/AllBalladsPage/HomeBalladsContainer'
import AppBarAndDrawer from './pages/AppBarAndDrawer'
import LoginPage from './pages/Auth/LoginPage'
import BalladDetailsContainer from './pages/BalladDetailsPage/BalladDetailsContainer'
import NotFoundPage from './pages/Errors/NotFoundPage'
import GeoFilesContainer from './pages/GeoFilesPage/GeoFilesContainer'
import POIsContainer from './pages/POIsPage/POIsContainer'
import BalladFetcher from './state/ballads/BalladFetcher'
import { BalladProvider } from './state/ballads/BalladProvider'
import PublicTransportFetcher from './state/publicTransport/PublicTransportFetcher'
import { PublicTransportProvider } from './state/publicTransport/PublicTransportProvider'
import UserFetcher from './state/user/UserFetcher'
import { UserProvider } from './state/user/UserProvider'
import { createTheme } from '@mui/material/styles'
import UploadGpxFormContainer from './pages/UploadGpxFormPage/UploadGpxFormContainer'
import { GpxProvider } from './state/gpx/GpxProvider'
import GpxFetcher from './state/gpx/GpxFetcher'
import AdminGpxHistoryContainer from './pages/AdminGpxHistoryPage/AdminGpxHistoryContainer'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles>
        <Router>
          <UserProvider>
            <BalladProvider>
              <PublicTransportProvider>
                <GpxProvider>
                  <UserFetcher>
                    <CssBaseline />
                    <AppBarAndDrawer />
                    <Container maxWidth={false}>
                      <LeafletProvider>
                        <GoogleMapProvider>
                          <SnackbarProvider>
                            <Switch>
                              <Route
                                exact
                                path='/login'
                                render={() => <LoginPage />}
                              />
                              <Route
                                exact
                                path='/drag'
                                render={() => <GeoFilesContainer />}
                              />
                              <Route
                                exact
                                path='/'
                                render={() => <POIsContainer />}
                              />
                              <BalladFetcher>
                                <PublicTransportFetcher>
                                  <GpxFetcher>
                                    <Switch>
                                      <Route
                                        exact
                                        path='/ballads/:balladType(hike|ride)?/:balladStatus(with-track|with-bounds|wip)?'
                                        component={HomeBalladsContainer}
                                      />
                                      <Route
                                        exact
                                        path='/ballads/:id'
                                        component={BalladDetailsContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/ballads/create'
                                        component={AdminBalladFormContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/ballads/:balladType(hike|ride)?/:balladStatus(with-track|with-bounds|wip)?'
                                        component={AdminBalladsContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/ballads/:idOrSlug'
                                        component={AdminBalladDetailsContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/ballads/:idOrSlug/edit'
                                        component={AdminBalladFormContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/ballads/:idOrSlug/overlay/:index'
                                        component={AdminOverlayContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/gpx/'
                                        component={AdminGpxHistoryContainer}
                                      />
                                      <Route
                                        exact
                                        path='/admin/gpx/create'
                                        component={UploadGpxFormContainer}
                                      />

                                      <Route
                                        path='/not-found'
                                        component={NotFoundPage}
                                      />
                                      <Route component={NotFoundPage} />
                                    </Switch>
                                  </GpxFetcher>
                                </PublicTransportFetcher>
                              </BalladFetcher>
                            </Switch>
                          </SnackbarProvider>
                        </GoogleMapProvider>
                      </LeafletProvider>
                    </Container>
                  </UserFetcher>
                </GpxProvider>
              </PublicTransportProvider>
            </BalladProvider>
          </UserProvider>
        </Router>
      </GlobalStyles>
    </ThemeProvider>
  )
}
