import { encodePath } from '../../../lib/polylineEncoding'

function getRoutes(directionsResults: google.maps.DirectionsResult[]) {
  //There will only be one route unless the DirectionsRequest was made with provideRouteAlternatives set to true.
  return directionsResults.map((directionsResult) => directionsResult.routes[0])
}

function arePointsIdentical(
  pt1: google.maps.LatLng | google.maps.LatLngLiteral | null,
  pt2: google.maps.LatLng | google.maps.LatLngLiteral | null
): boolean {
  if (pt1 === null && pt2 === null) return true
  if (pt1 === null || pt2 === null) return false
  const lat1 = typeof pt1.lat === 'function' ? pt1.lat() : pt1.lat
  const lat2 = typeof pt2.lat === 'function' ? pt2.lat() : pt2.lat
  const lng1 = typeof pt1.lng === 'function' ? pt1.lng() : pt1.lng
  const lng2 = typeof pt2.lng === 'function' ? pt2.lng() : pt2.lng
  return lat1 === lat2 && lng1 === lng2
}

function getLast<T>(arr: T[]): T | null {
  if (arr.length) {
    return arr[arr.length - 1]
  }
  return null
}

function getRouteLegsInfos(
  legs: google.maps.DirectionsLeg[]
): { waypoints: google.maps.LatLng[]; distance: number; duration: number } {
  let waypoints: google.maps.LatLng[] = []
  let duration = 0
  let distance = 0

  legs.forEach((leg) => {
    if (!arePointsIdentical(getLast(waypoints), leg.start_location)) {
      waypoints.push(leg.start_location)
    }
    waypoints.push(leg.end_location)

    duration += leg.duration.value
    distance += leg.distance.value
  })

  return {
    waypoints,
    distance,
    duration: Math.round(duration / 60),
  }
}

export function getDirectionsResultsEncodedRoute(
  directionsResults: google.maps.DirectionsResult[]
) {
  const routes = getRoutes(directionsResults)

  if (routes.length === 1) {
    return routes[0].overview_polyline
  }

  const latLngPoints = routes.reduce((acc: google.maps.LatLng[], route) => {
    const points = route.overview_path
    return [...acc, ...points]
  }, [])

  return encodePath(latLngPoints)
}

export function getDirectionsResultsDistanceAndDuration(
  directionsResults: google.maps.DirectionsResult[]
) {
  const legs = getRoutes(directionsResults).reduce(
    (acc: google.maps.DirectionsLeg[], route) => {
      return [...acc, ...route.legs]
    },
    []
  )

  return getRouteLegsInfos(legs)
}

export function getDirectionsResultsWayPoints(
  directionsResults: google.maps.DirectionsResult[]
): google.maps.LatLng[] {
  const legs = getRoutes(directionsResults).reduce(
    (acc: google.maps.DirectionsLeg[], route) => {
      return [...acc, ...route.legs]
    },
    []
  )
  let waypoints: google.maps.LatLng[] = []

  legs.forEach((leg) => {
    if (!arePointsIdentical(getLast(waypoints), leg.start_location)) {
      waypoints.push(leg.start_location)
    }
    waypoints.push(leg.end_location)
  })

  return waypoints
}
