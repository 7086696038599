import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import requestAccessToken from './services/requestAccessToken'
import FormHelperText from '@mui/material/FormHelperText'
import { getErrorMessage } from '../../lib/errors'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn() {
  const classes = useStyles()

  const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState<
    string | null
  >(null)
  const [loading, setLoading] = useState(false)

  const handleLogin = async (e: React.MouseEvent) => {
    e.preventDefault()

    try {
      const formData = new FormData(document.forms[0])
      const email = formData.get('email')
      const password = formData.get('password')
      if (typeof email === 'string' && typeof password === 'string') {
        setLoading(true)
        setAuthenticationErrorMessage(null)
        await requestAccessToken({ email, password })
        window.location.href = '/ballads'
      }
    } catch (e) {
      setAuthenticationErrorMessage(getErrorMessage(e))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>lock</Icon>
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form className={classes.form} noValidate action='' method='post'>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            autoComplete='current-password'
          />
          <Button
            type='submit'
            disabled={loading}
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={handleLogin}>
            {loading ? '...' : 'Log In'}
          </Button>
          {authenticationErrorMessage && (
            <FormHelperText error={true}>
              {authenticationErrorMessage}
            </FormHelperText>
          )}
        </form>
      </div>
    </Container>
  )
}
