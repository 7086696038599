import {
  BalladResponse,
  DeleteResponse,
  FetchNearestStationsResponse,
  InsertNoReturnResponse,
  UpdateBalladCompletedRequestBody,
  UpdateBalladDraftRequestBody,
  UpdateBalladResponse,
  UpdateBalladRouteRequestBody,
  UpdateNoReturnResponse,
  WayPointsBodyRequest,
} from '../@digimap/types/api'
import apiFetch, { apiFetchFormData } from './apiFetch'

export async function fetchBallad(_id: string) {
  return await apiFetch<void, BalladResponse>(`ballads/${_id}`)
}

export async function createBallad(formData: FormData) {
  return await apiFetchFormData<InsertNoReturnResponse>(
    'ballads/',
    'POST',
    formData
  )
}

export async function deleteBallad(_id: string) {
  return await apiFetch<void, DeleteResponse>(`ballads/${_id}`, 'DELETE')
}

export async function updateBallad(_id: string, formData: FormData) {
  return await apiFetchFormData<UpdateNoReturnResponse>(
    `ballads/${_id}`,
    'PUT',
    formData
  )
}

export async function getBalladClosestPublicTransportStations(_id: string) {
  return await apiFetch<void, FetchNearestStationsResponse>(
    `ballads/${_id}/nearest`
  )
}

export async function toggleBalladDraft(_id: string, draft: boolean) {
  return await apiFetch<UpdateBalladDraftRequestBody, UpdateBalladResponse>(
    `ballads/${_id}/draft`,
    'PUT',
    { draft }
  )
}

export async function updateBalladTrack(
  _id: string,
  track: string,
  distance: number,
  duration: number
) {
  return await apiFetch<UpdateBalladRouteRequestBody, UpdateNoReturnResponse>(
    `ballads/${_id}/track`,
    'PUT',
    {
      track,
      distance,
      duration,
    }
  )
}

export async function toggleBalladCompleted(_id: string, completed: boolean) {
  return await apiFetch<
    UpdateBalladCompletedRequestBody,
    UpdateNoReturnResponse
  >(`ballads/${_id}/completed`, 'PUT', { completed })
}

export async function updateBalladWayPoints(
  _id: string,
  wayPoints: google.maps.LatLngLiteral[]
) {
  return await apiFetch<WayPointsBodyRequest, UpdateBalladResponse>(
    `ballads/${_id}/waypoints`,
    'PUT',
    { wayPoints }
  )
}
