import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import { Gpx } from '../../../@digimap/types'

interface GpxFilesListItemProps {
  gpxFile: Gpx
  visible?: boolean
  onToggleVisible: (filename: string, visible: boolean) => void
}

export default function GpxFilesListItem({
  gpxFile,
  visible = true,
  onToggleVisible,
}: GpxFilesListItemProps) {
  const { _id, filename, name, date } = gpxFile

  const onSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onToggleVisible(filename, checked)
  }

  return (
    <ListItem key={_id} divider>
      <ListItemText primary={name} secondary={new Date(date).toDateString()} />
      <ListItemSecondaryAction>
        <Switch
          edge='end'
          onChange={onSwitch}
          checked={visible}
          inputProps={{
            'aria-labelledby': `switch-list-label-${name}`,
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
