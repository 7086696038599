/* global google */
import { useContext, useEffect } from 'react'
import {
  getIconUrl,
  getInfoTable,
  isOSMNodeElement,
  OSMElement,
} from '../../lib/overpass/helpers/osmElement'
import {} from '../../lib/overpass/overpassService'
import { GoogleMapContext } from '../googlemaps-react/contexts/GoogleMapContext'

interface GoogleMapsPOIsProps {
  POIs: OSMElement[]
}

export default function GoogleMapPOIs({ POIs }: GoogleMapsPOIsProps) {
  const { state } = useContext(GoogleMapContext)

  useEffect(() => {
    if (state.map === undefined) return
    const infowindow = new google.maps.InfoWindow({})

    const markers = POIs.map((element: any) => {
      const latLng = isOSMNodeElement(element)
        ? { lat: element.lat, lng: element.lon }
        : { lat: element.center.lat, lng: element.center.lon }

      const marker = new google.maps.Marker({
        position: latLng,
        icon: {
          url: getIconUrl(element),
          size: new google.maps.Size(32, 37),
          anchor: new google.maps.Point(16, 35),
          origin: new google.maps.Point(0, 0),
        },
      })
      if (state.map) marker.setMap(state.map)

      marker.addListener('click', function () {
        const position = marker.getPosition()
        if (position) {
          infowindow.setContent(getInfoTable(element))
          infowindow.setPosition(position)
          infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -37) })
          infowindow.open(state.map)
        }
      })

      return marker
    })

    return () => {
      markers.forEach((marker) => {
        google.maps.event.clearInstanceListeners(marker)
        marker.setMap(null)
      })
      infowindow.close()
    }
  }, [POIs, state.map])

  return null
}
