/* global google */
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import {
  getOverlayLatLngBoundsLiteral,
  isOverlayable as isOverlayPositioned,
  MAX_POINTS,
} from '../../../../@digimap/lib/overlay'
import { ImagePoint, Overlay } from '../../../../@digimap/types'
import { GoogleMapState } from '../../../../components/googlemaps-react/common/types'
import MapBox from '../../../../components/googlemaps-react/components/MapBox'
import GoogleMapsMarker from '../../../../components/googlemaps-react/components/Marker'
import SearchBox from '../../../../components/googlemaps-react/components/SearchBox'
import { GoogleMapContext } from '../../../../components/googlemaps-react/contexts/GoogleMapContext'
import ImageOverlay from '../../../../components/googlemaps/ImageOverlay'
import {
  DEFAULT_MAP_OPTIONS,
  GOOGLE_MAPS_API_KEY,
} from '../../../../constants/googleMaps'
import { getErrorMessage } from '../../../../lib/errors'
import { updateOverlayPoints } from '../../../../services'
import imagePointsReducer, {
  addPointOnImage,
  ImagePointDiff,
  initialState as imagePointsInitialState,
  movePointOnImage,
} from '../../state/imagePoints'
import mapPointsReducer, {
  addPointOnMap,
  initialState as mapPointsInitialState,
} from '../../state/mapPoints'
import OverlayImagePointsPositioner from './OverlayImagePointsPositioner'

const SEARCH_BOX_ID = 'search-box'
const getSearchBox = (
  state: GoogleMapState
): google.maps.places.SearchBox | undefined => {
  return state.objects.get(SEARCH_BOX_ID) as
    | google.maps.places.SearchBox
    | undefined
}

export interface OverlayPositionerProps {
  overlay: Overlay
}
function OverlayPage({ overlay }: OverlayPositionerProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useContext(GoogleMapContext)
  const [imagePoints, imagePointsDispatch] = useReducer(
    imagePointsReducer,
    overlay && Array.isArray(overlay.imagePoints)
      ? overlay.imagePoints
      : imagePointsInitialState
  )

  const [mapPoints, mapPointsDispatch] = useReducer(
    mapPointsReducer,
    overlay && Array.isArray(overlay.mapPoints)
      ? overlay.mapPoints
      : mapPointsInitialState
  )

  const isOverlayable = useMemo(
    () =>
      isOverlayPositioned({
        imagePoints,
        mapPoints,
        width: overlay.width,
        height: overlay.height,
      }),
    [imagePoints, mapPoints, overlay.height, overlay.width]
  )

  const bounds = useMemo(
    () =>
      isOverlayable
        ? getOverlayLatLngBoundsLiteral({
            imagePoints,
            mapPoints,
            width: overlay.width,
            height: overlay.height,
          })
        : undefined,
    [imagePoints, isOverlayable, mapPoints, overlay.height, overlay.width]
  )

  // only at start
  useEffect(() => {
    if (state.map && Array.isArray(overlay.bounds)) {
      const [sw, ne] = overlay.bounds

      const initialMapBounds = new google.maps.LatLngBounds(
        { lat: sw[0], lng: sw[1] },
        { lat: ne[0], lng: ne[1] }
      )

      const initialCenter = initialMapBounds.getCenter()
      state.map.setCenter(initialCenter)
      state.map.setZoom(12)
    }
  }, [overlay.bounds, state.map])

  return (
    <Grid container spacing={0} style={{ height: '100%' }}>
      <Grid item xs={12}>
        <Button
          onClick={async () => {
            try {
              await updateOverlayPoints({
                ...overlay,
                imagePoints,
                mapPoints,
                bounds,
              })
              enqueueSnackbar('Overlay successfully positioned', {
                variant: 'success',
              })
            } catch (e) {
              enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
            }
          }}>
          Save Points
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ overflow: 'auto', height: '100%' }}
        id='imageScrollContainer'>
        <OverlayImagePointsPositioner
          imagePoints={imagePoints}
          maxPoints={MAX_POINTS}
          overlay={overlay}
          onAdd={({ top, left }: ImagePoint) => {
            imagePointsDispatch(addPointOnImage({ top, left }))
          }}
          onMove={(diff: ImagePointDiff, index: number) =>
            imagePointsDispatch(movePointOnImage(diff, index))
          }
        />
      </Grid>
      <Grid item xs={6}>
        <SearchBox
          id={SEARCH_BOX_ID}
          placeholder='Search...'
          bindingPosition='TOP_LEFT'
          style={{
            margin: '4px',
            padding: '10px',
            width: '300px',
          }}
          onPlacesChanged={() => {
            const searchBox = getSearchBox(state)
            if (!searchBox) return
            const places = searchBox.getPlaces()
            if (places.length === 0) {
              return
            }

            const mapBounds = places.reduce(
              (
                bounds: google.maps.LatLngBounds,
                place
              ): google.maps.LatLngBounds => {
                if (!place.geometry) return bounds

                if (place.geometry.viewport) {
                  // Only geocodes have viewport.
                  return bounds.union(place.geometry.viewport)
                } else {
                  return bounds.extend(place.geometry.location)
                }
              },
              new google.maps.LatLngBounds()
            )
            state.map && state.map.fitBounds(mapBounds)
          }}
        />
        <MapBox
          apiKey={GOOGLE_MAPS_API_KEY}
          opts={DEFAULT_MAP_OPTIONS}
          onBoundsChanged={() => {
            const searchBox = getSearchBox(state)
            if (state.map && searchBox) {
              const mapBounds = state.map.getBounds()
              if (mapBounds) searchBox.setBounds(mapBounds)
            }
          }}
          onClick={(e: google.maps.MouseEvent) => {
            if (mapPoints.length < MAX_POINTS) {
              mapPointsDispatch(
                addPointOnMap({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                })
              )
            }
          }}
          usePlaces
        />
        {mapPoints.map((mapPoint, index) => (
          <GoogleMapsMarker
            key={`marker-point-${index + 1}`}
            id={`marker-point-${index + 1}`}
            opts={{
              draggable: true,
              position: mapPoint,
            }}
            onDragEnd={(e) => {
              mapPointsDispatch(
                addPointOnMap(
                  {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  },
                  index
                )
              )
            }}
          />
        ))}
        {Array.isArray(bounds) && (
          <ImageOverlay
            image={{
              ...overlay,
              imagePoints,
              mapPoints,
              bounds,
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default OverlayPage
