/* global google */
import { GoogleMapLibrary } from './types'

export const NYC_LATLNG: google.maps.LatLngLiteral = {
  lat: 40.7128,
  lng: -74.006,
}
export const NYC_POLYGON: google.maps.LatLngLiteral[] = [
  { lat: 40.718, lng: -74.006 },
  { lat: 40.718, lng: -74.01 },
  { lat: 40.7138, lng: -74.001 },
  { lat: 40.7128, lng: -74.005 },
]
export const NYC_RECTANGLE: google.maps.LatLngBoundsLiteral = {
  east: -74,
  west: -74.006,
  north: 40.718,
  south: 40.714,
}

const DEFAULT_MAP_ZOOM = 14

export const DEFAULT_INFO_WINDOW_OPTIONS: google.maps.InfoWindowOptions = {
  content: 'Hello',
  position: NYC_LATLNG,
}

export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  center: NYC_LATLNG,
  zoom: DEFAULT_MAP_ZOOM,
}

export const DEFAULT_MAP_STYLE = {
  height: '100%',
  width: '100%',
}

export const DEFAULT_MARKER_OPTIONS: google.maps.MarkerOptions = {
  position: NYC_LATLNG,
}

export const DEFAULT_POLYLINE_OPTIONS: google.maps.PolylineOptions = {
  path: NYC_POLYGON,
}

export const DEFAULT_SEARCH_BOX_OPTIONS: google.maps.places.SearchBoxOptions = {
  bounds: NYC_RECTANGLE,
}

export const GOOGLE_MAP_BASE_URI = 'https://maps.googleapis.com/maps/api/js'

export const GOOGLE_MAP_LIBRARY_NAMES: GoogleMapLibrary[] = [
  'drawing',
  'geometry',
  'places',
  'visualization',
]
