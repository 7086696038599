import React, { useReducer } from 'react'
import balladReducer, {
  BalladAction,
  BalladState,
  initialState,
} from './balladReducer'

const BalladContext = React.createContext<
  BalladState & {
    balladDispatch: React.Dispatch<BalladAction>
  }
>({
  ...initialState,
  balladDispatch: () => {},
})

function BalladProvider(props: { children: React.ReactNode }) {
  const [balladState, balladDispatch] = useReducer(balladReducer, initialState)

  return (
    <BalladContext.Provider value={{ ...balladState, balladDispatch }}>
      {props.children}
    </BalladContext.Provider>
  )
}

BalladProvider.displayName = 'BalladProvider'
BalladProvider.whyDidYouRender = {
  logOnDifferentValues: true,
}

export { BalladContext, BalladProvider }
