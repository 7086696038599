export type ImagePoint = {
  top: number
  left: number
}

export type LatLngLiteral = {
  lat: number
  lng: number
}

export type LatLngTuple = [number, number]
export type LngLatTuple = [number, number]

export type LatLngBoundsLiteral = LatLngTuple[]

export type WayPoint = LatLngLiteral & {
  name?: string
  commune?: string
}

export type Overlay = {
  _id: string
  filename: string
  width: number
  height: number
  mapPoints: LatLngLiteral[]
  imagePoints: ImagePoint[]
  bounds?: [number, number][]
}
export type Overlayable = Omit<Overlay, '_id' | 'filename'>

export enum BalladType {
  HIKE = 'hike',
  RIDE = 'ride',
}

export type Ballad = {
  _id: string
  slug: string
  name: string
  images: Overlay[]
  type: BalladType
  distance?: number
  level?: number
  loop: boolean
  draft: boolean
  wayPoints: WayPoint[]
  track?: string
  duration?: number
  publicTransportStartIds?: string[]
  publicTransportEndIds?: string[]
}

export type BaseBallad = Omit<Ballad, 'images' | 'wayPoints'>

export type NewBaseBallad = Omit<BaseBallad, '_id'>

export function isExistingBallad(
  ballad: BaseBallad | NewBaseBallad
): ballad is BaseBallad {
  return ballad.hasOwnProperty('_id') === true
}

export type BalladDatabase = Omit<Ballad, 'images'> & {
  images: string[]
}

interface OverlayDict {
  [key: string]: Overlay
}
export const convertBalladDatabaseToBallad = (
  ballads: BalladDatabase[],
  overlays: Overlay[]
): Ballad[] => {
  const overlaysByIds = overlays.reduce((dict, overlay) => {
    return { ...dict, [overlay._id]: overlay }
  }, {} as OverlayDict)

  return ballads.map((b) => {
    const newImages = b.images.map((_id) => overlaysByIds[_id])
    return { ...b, images: newImages }
  })
}

export type Gpx = {
  _id: string
  ballads?: string[]
  filename: string
  name: string
  date: string
  type: BalladType
}

export type BaseGpx = {
  _id: string
  ballads?: string[]
  filename: string
  name: string
  date: Date
  type: BalladType
}

export type NewBaseGpx = Omit<BaseGpx, '_id'>
