const THUNDERFOREST_API_KEY = process.env.REACT_APP_THUNDERFOREST_API_KEY
const GEOSERVICES_API_KEY = process.env.REACT_APP_GEOSERVICES_API_KEY

interface MapProviderValue {
  tileLayer: string
  options: L.TileLayerOptions & {
    apikey?: string
    format?: string
    style?: string
  }
}

// https://leaflet-extras.github.io/leaflet-providers/preview/
interface MapProviders {
  'OpenStreetMap.mapnik': MapProviderValue
  'Esri.WorldImagery': MapProviderValue
  'GeoportailFrance.ignMaps': MapProviderValue
  'Thunderforest.OpenCycleMap': MapProviderValue
  'Thunderforest.Outdoors': MapProviderValue
  CyclOSM: MapProviderValue
  'WaymarkedTrails.cycling': MapProviderValue
  'WaymarkedTrails.hiking': MapProviderValue
  'Thunderforest.Transport': MapProviderValue
  OpenRailwayMap: MapProviderValue
}

export const availableMapProviders: MapProviders = {
  'OpenStreetMap.mapnik': {
    tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    options: {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
  },
  'Thunderforest.OpenCycleMap': {
    tileLayer:
      'https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey={apikey}',
    options: {
      attribution:
        '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      apikey: THUNDERFOREST_API_KEY,
      maxZoom: 22,
    },
  },
  'Thunderforest.Outdoors': {
    tileLayer:
      'https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey={apikey}',
    options: {
      attribution:
        '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      apikey: THUNDERFOREST_API_KEY,
      maxZoom: 22,
    },
  },
  'Esri.WorldImagery': {
    tileLayer:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    options: {
      attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
  },
  'GeoportailFrance.ignMaps': {
    tileLayer:
      'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
    options: {
      attribution:
        '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
      bounds: [
        [-75, -180],
        [81, 180],
      ],
      minZoom: 15,
      maxZoom: 17,
      apikey: GEOSERVICES_API_KEY,
      format: 'image/jpeg',
      style: 'normal',
    },
  },
  CyclOSM: {
    tileLayer:
      'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    options: {
      maxZoom: 20,
      attribution:
        '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
  },
  'WaymarkedTrails.cycling': {
    tileLayer: 'https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png',
    options: {
      maxZoom: 18,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://waymarkedtrails.org">waymarkedtrails.org</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
  },
  'WaymarkedTrails.hiking': {
    tileLayer: 'https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png',
    options: {
      maxZoom: 18,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://waymarkedtrails.org">waymarkedtrails.org</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
  },
  'Thunderforest.Transport': {
    tileLayer:
      'https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey={apikey}',
    options: {
      attribution:
        '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      apikey: THUNDERFOREST_API_KEY,
      maxZoom: 22,
    },
  },
  OpenRailwayMap: {
    tileLayer: 'https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
    options: {
      maxZoom: 19,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
  },
}

export type MapProviderKey = keyof MapProviders

export const freeMapProviders: MapProviderKey[] = [
  'OpenStreetMap.mapnik',
  'Esri.WorldImagery',
  'CyclOSM',
]

export const limitedMapProviders: MapProviderKey[] = [
  ...freeMapProviders,
  'Thunderforest.Outdoors',
  'Thunderforest.OpenCycleMap',
  'GeoportailFrance.ignMaps',
]

export const freeMapOverlayProviders: MapProviderKey[] = [
  'OpenRailwayMap',
  'WaymarkedTrails.cycling',
  'WaymarkedTrails.hiking',
]

export const limitedMapOverlayProviders: MapProviderKey[] = [
  ...freeMapOverlayProviders,
  'Thunderforest.Transport',
]
