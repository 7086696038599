import { UpdateNoReturnResponse, GpxResponse } from '../@digimap/types/api'
import apiFetch, { apiFetchFormData } from './apiFetch'

export async function uploadAndPersistGpxFile(formData: FormData) {
  return await apiFetchFormData<UpdateNoReturnResponse>(`gpx`, 'POST', formData)
}

export async function fetchAllGpxFiles() {
  return await apiFetch<void, GpxResponse>(`gpx`, 'GET')
}
