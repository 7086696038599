import { GPXWayPoint, GPXTrkseg, GPXTrk, GPXMetadata, GPXType } from './types'

export default function gpxToString(gpx: GPXType): string {
  const str = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="${
    gpx.creator
  }">
  ${gpx.metadata ? metadataToString(gpx.metadata) : ''}
  ${gpx.trk.length ? gpx.trk.map(trkToString).join('') : ''}
</gpx>`
    .split('\n')
    .filter(c => c.trim().length)
    .join('\n')
  return str
}

function ptToString(pt: GPXWayPoint): string {
  return `
      <trkpt lon="${pt.lon}" lat="${pt.lat}">
        ${pt.ele ? `<ele>${pt.ele}</ele>` : ''}
        ${pt.name ? `<name>${pt.name}</name>` : ''}
      </trkpt>
`
}

function trkSegToString(trkSeg: GPXTrkseg): string {
  return `
    <trkseg>
      ${trkSeg.trkpt.map(ptToString).join('')}
    </trkseg>
`
}

function trkToString(trk: GPXTrk): string {
  return `
  <trk>
    ${trk.name ? `<name>${trk.name}</name>` : ''}
    ${trk.trkseg.map(trkSegToString).join('')}
  </trk>
`
}

function metadataToString(metadata: GPXMetadata): string {
  return `
  <metadata>
    ${metadata.name ? `<name>${metadata.name}</name>` : ''}
  </metadata>
`
}
