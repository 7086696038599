import React, { useCallback } from 'react'
import GPX from '../lib/GPX/Gpx'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { LatLngLiteral } from 'leaflet'

interface DownloadGpxButtonProps {
  path: LatLngLiteral[]
  name: string
  disabled: boolean
}
export default function DownloadGpxButton({
  path,
  name,
  disabled,
}: DownloadGpxButtonProps) {
  const downloadGPxFile = useCallback(() => {
    const element = document.createElement('a')
    const gpx = new GPX()
    gpx.setTrackAndName(name, path)
    const file = new Blob([gpx.toString()], {
      type: 'application/gpx+xml',
    })
    element.href = URL.createObjectURL(file)
    element.download = `${name}.gpx`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }, [name, path])

  return (
    <Button
      variant='outlined'
      size='small'
      onClick={downloadGPxFile}
      disabled={disabled}>
      <Icon>save_alt</Icon>
      Download GPX
    </Button>
  )
}
