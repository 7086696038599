import { Feature, FeatureCollection, Geometry } from 'geojson'

export function cloneFeatureCollection<G extends Geometry, P>(
  featureCollection: FeatureCollection<G, P>
): FeatureCollection<G, P> {
  return {
    type: featureCollection.type,
    features: [...featureCollection.features],
  }
}

export function createFeatureCollection<G extends Geometry, P>(
  features: Feature<G, P>[]
): FeatureCollection<G, P> {
  return {
    type: 'FeatureCollection',
    features: features,
  }
}
