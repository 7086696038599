import { useState } from 'react'

type TVisibile = { visible: boolean; key: string }

const useVisible = (initial: string[], defaultVisibility: boolean) => {
  const initialVisibleLayers = initial.map((key) => ({
    key,
    visible: defaultVisibility,
  }))
  const [visibleLayers, setVisible] =
    useState<TVisibile[]>(initialVisibleLayers)

  const setLayerVisible = (key: string, visible: boolean) => {
    const newData = visibleLayers.map((f) => {
      if (f.key === key) {
        f.visible = visible
      }
      return f
    })
    setVisible(newData)
  }

  const isVisible = (key: string) => {
    const result = visibleLayers.find((layer) => layer.key === key)
    return result === undefined ? false : result.visible
  }

  return { setLayerVisible, isVisible }
}

export default useVisible
