import React, { useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { BALLAD_STATUS } from '../../@digimap/lib/ballad'
import { Ballad } from '../../@digimap/types'
import FilterableBalladsMapList from './components/FilterableBalladsMapList'

interface AdminBalladsContainerProps
  extends RouteComponentProps<{
    balladStatus?: string
    balladType?: string
  }> {}

export default function AdminBalladsContainer(
  props: AdminBalladsContainerProps
) {
  const onTabClickMemo = useCallback(
    (balladStatus: BALLAD_STATUS | undefined) => {
      props.history.push(`/admin/ballads/${balladStatus ? balladStatus : ''}`)
    },
    [props.history]
  )

  const onBalladClickMemo = useCallback(
    (ballad: Ballad) => {
      props.history.push(`/admin/ballads/${ballad.slug}`)
    },
    [props.history]
  )

  return (
    <FilterableBalladsMapList
      onTabClick={onTabClickMemo}
      onBalladClick={onBalladClickMemo}
      balladType={props.match.params.balladType}
      balladStatus={props.match.params.balladStatus}
      showIfCompleted={false}
      displayDraft={true}
    />
  )
}
