import { PublicTransportStationsGroupedByLines } from '../../@digimap/types'
import { PublicTransportStationsGroupedByLinesResponse } from '../../@digimap/types/api'

enum ACTION_TYPES {
  RESET = 'RESET',
}

// --- ACTIONS

export const publicTransportActions = {
  reset: function (payload: PublicTransportStationsGroupedByLinesResponse) {
    return {
      type: ACTION_TYPES.RESET as ACTION_TYPES.RESET,
      payload,
    }
  },
}

// --- TYPES

export type PublicTransportAction = ReturnType<
  typeof publicTransportActions.reset
>

export type PublicTransportState = {
  groupedByLines: PublicTransportStationsGroupedByLines
}

export const initialState: PublicTransportState = { groupedByLines: {} }

function reducer(
  state: PublicTransportState = initialState,
  action: PublicTransportAction
): PublicTransportState {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return { groupedByLines: action.payload }

    default:
      return state
  }
}

export default reducer
