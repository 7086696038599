function minutesToHM(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60)
  totalMinutes %= 3600
  const minutes = (totalMinutes %= 60)
  return { hours, minutes }
}

export function formatDuration(duration: number): string {
  const { minutes, hours } = minutesToHM(duration)
  const minutesAsStr = `${minutes < 10 ? `0${minutes}` : minutes}`
  return hours ? `${hours}h${minutesAsStr}` : `${minutesAsStr}min`
}

export function formatDistance(distance: number): string {
  const km = Math.round(distance / 1000)
  return `${km} km`
}
