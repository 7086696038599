/* global google */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { PublicTransportStationFeature } from '../../../@digimap/types/publicTransport'
import CityMapperLink from '../../../components/CityMapper/CityMapperLink'
import GoogleMapsMarker from '../../../components/googlemaps-react/components/Marker'
import { GoogleMapContext } from '../../../components/googlemaps-react/contexts/GoogleMapContext'
import getPublicTransportLineColor from '../../../lib/getPublicTransportLineColor'

interface PublicTransportSuggestionsListProps {
  stations: PublicTransportStationFeature[]
  selected: PublicTransportStationFeature[]
  onAdd: (selected: PublicTransportStationFeature) => void
  onRemove: (selected: PublicTransportStationFeature) => void
}
export default function PublicTransportSuggestionsList(
  props: PublicTransportSuggestionsListProps
) {
  const { state } = useContext(GoogleMapContext)
  const { stations } = props

  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>()

  useEffect(() => {
    if (!state.map) return

    const iw = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -30),
    })
    setInfoWindow(iw)

    return () => {
      iw.close()
    }
  }, [state.map])

  return (
    <List>
      {stations.map((feature) => {
        const isSelected = props.selected.some(
          (s) => s.properties._id === feature.properties._id
        )
        return (
          <Fragment key={feature.properties.name}>
            <ListItem>
              <ListItemText
                id={`neareast-stations-${feature.properties.name}`}
                primary={<CityMapperLink feature={feature} type='outbound' />}
                secondary={`${feature.properties.line} / ${
                  feature.properties.dist
                    ? Math.round(feature.properties.dist * 100) / 100
                    : '??'
                } km`}
              />
              <ListItemSecondaryAction>
                <Switch
                  edge='end'
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    if (!checked) {
                      props.onRemove(feature)
                    } else {
                      props.onAdd(feature)
                    }
                  }}
                  checked={isSelected}
                  inputProps={{
                    'aria-labelledby': `neareast-stations-${feature.properties.name}`,
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {!isSelected && (
              <GoogleMapsMarker
                onRightClick={(e) => {
                  if (isSelected) {
                    props.onRemove(feature)
                  } else {
                    props.onAdd(feature)
                  }
                }}
                onClick={(e) => {
                  if (!state.map || !infoWindow) return
                  infoWindow.setContent(
                    '<div style="width:150px; text-align: center;">' +
                      feature.properties.name +
                      '</div>'
                  )
                  infoWindow.setPosition(e.latLng)
                  infoWindow.open(state.map)
                }}
                opts={{
                  icon: {
                    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    fillColor: getPublicTransportLineColor(
                      feature.properties.line
                    ),
                    fillOpacity: isSelected ? 1 : 0.3,
                    strokeWeight: isSelected ? 0 : 1,
                    strokeColor: getPublicTransportLineColor(
                      feature.properties.line
                    ),
                    scale: 0.7,
                  },
                  position: {
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0],
                  },
                }}
              />
            )}
          </Fragment>
        )
      })}
    </List>
  )
}
