import React, { useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { BALLAD_STATUS } from '../../@digimap/lib/ballad'
import { Ballad } from '../../@digimap/types'
import FilterableBalladsMapList from './components/FilterableBalladsMapList'

interface HomeBalladsContainerProps
  extends RouteComponentProps<{
    balladStatus?: string
    balladType: 'hike' | 'ride' | undefined
  }> {}

export default function HomeBalladsContainer(props: HomeBalladsContainerProps) {
  const onTabClickMemo = useCallback(
    (balladStatus: BALLAD_STATUS | undefined, balladType?: string) => {
      props.history.push(
        `/ballads/${balladType ? `${balladType}/` : ''}${
          balladStatus ? balladStatus : ''
        }`
      )
    },
    [props.history]
  )

  const onBalladClickMemo = useCallback(
    (ballad: Ballad) => {
      props.history.push(`/ballads/${ballad._id}`)
    },
    [props.history]
  )

  return (
    <FilterableBalladsMapList
      onTabClick={onTabClickMemo}
      onBalladClick={onBalladClickMemo}
      balladType={props.match.params.balladType}
      balladStatus={props.match.params.balladStatus}
      showIfCompleted={true}
    />
  )
}
