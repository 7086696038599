import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { getErrorMessage } from '../../lib/errors'
import { fetchBallads } from '../../services'
import { BalladContext } from './BalladProvider'
import { balladActions } from './balladReducer'

export default function BalladFetcher(props: { children: any }) {
  const { enqueueSnackbar } = useSnackbar()
  const { balladDispatch } = useContext(BalladContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAndSetBallads = async () => {
      try {
        const { ballads, overlays } = await fetchBallads()
        balladDispatch(balladActions.reset({ ballads, overlays }))
      } catch (e) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
      } finally {
        setLoading(false)
      }
    }
    fetchAndSetBallads()
    // eslint-disable-next-line
  }, [])

  if (loading) return null
  return props.children
}
