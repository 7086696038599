import L from 'leaflet'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import React, { useContext, useEffect, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import {
  limitedMapOverlayProviders,
  availableMapProviders,
  limitedMapProviders,
  MapProviderKey,
} from '../../../constants/leafletMapProviders'
import { LeafletContext } from '../contexts/LeafletContext'

type LeafletMapProps = {
  className?: string
  style?: object
  opts: L.MapOptions
  mapProviders?: MapProviderKey[]
  overlayLayersProviders?: MapProviderKey[]
}

const defaultStyle = {
  height: '100%',
}

export default function LeafletMap({
  opts,
  style = {},
  className,
  mapProviders = limitedMapProviders,
  overlayLayersProviders = limitedMapOverlayProviders,
}: LeafletMapProps) {
  const { dispatch } = useContext(LeafletContext)
  const [map, setMap] = useState<L.Map | undefined>(undefined)
  const [mapItemId] = useState(`map-${uuidv1()}`)

  useEffect(() => {
    if (map) return
    const myMap = L.map(mapItemId, opts)

    //@ts-ignore
    myMap.addControl(new L.Control.Fullscreen())
    setMap(myMap)

    const baseLayers = (mapProviders as MapProviderKey[]).reduce(
      (acc, mapProvider) => {
        return {
          ...acc,
          [mapProvider]: L.tileLayer(
            availableMapProviders[mapProvider].tileLayer,
            availableMapProviders[mapProvider].options
          ),
        }
      },
      {}
    )

    const overlayLayers = (overlayLayersProviders as MapProviderKey[]).reduce(
      (acc, mapProvider) => {
        return {
          ...acc,
          [mapProvider]: L.tileLayer(
            availableMapProviders[mapProvider].tileLayer,
            availableMapProviders[mapProvider].options
          ),
        }
      },
      {}
    )

    if (mapProviders.length > 1) {
      L.control
        .layers(baseLayers, overlayLayers, {
          position: 'bottomright',
          hideSingleBase: true,
        })
        .addTo(myMap)
    }

    // @ts-ignore
    baseLayers[mapProviders[0]].addTo(myMap)

    dispatch({ type: 'init_map', map: myMap })
    return () => dispatch({ type: 'reset' })
    // eslint-disable-next-line
  }, [mapItemId])

  return (
    <>
      {typeof document !== 'undefined' ? (
        <div
          id={mapItemId}
          style={{ ...defaultStyle, ...style }}
          className={className}
        />
      ) : null}
    </>
  )
}
