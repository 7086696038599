import gpxToString from './gpxToString'
import {
  GPXPoint,
  GPXType,
  GPXTrk,
  GPXRte,
  GPXWayPoint,
  GPXMetadata,
  GPXTrkseg,
} from './types'

function getPts(pointsNode: Element[]): GPXPoint[] {
  return pointsNode.reduce((acc: GPXPoint[], trackPointNode) => {
    const lngStr = trackPointNode.getAttribute('lon')
    const latStr = trackPointNode.getAttribute('lat')
    if (latStr === null || lngStr === null) return acc
    const lng = parseFloat(lngStr)
    const lat = parseFloat(latStr)
    const eleStr = trackPointNode.getAttribute('ele')
    const eleNumber = eleStr && parseFloat(eleStr)
    const ele =
      eleNumber === null || eleNumber === '' || Number.isNaN(eleNumber)
        ? undefined
        : eleNumber

    const timeStr = trackPointNode.getAttribute('time')
    if (Number.isNaN(lng) || Number.isNaN(lat)) return acc
    return [
      ...acc,
      {
        lat,
        lon: lng,
        ...(ele && { ele }),
        ...(timeStr && { time: new Date(timeStr) }),
      },
    ]
  }, [])
}

function toLatLngLiteral({ lat, lon }: GPXPoint): google.maps.LatLngLiteral {
  return { lat, lng: lon }
}

function toGpxLatLng({ lat, lng }: google.maps.LatLngLiteral): GPXPoint {
  return { lat, lon: lng }
}

function getElementValue(parent: Element, needle: string) {
  let elem = parent.querySelector(' :scope > ' + needle)
  if (elem != null) {
    return elem.innerHTML
  }
  return elem
}

export default class GPX implements GPXType {
  version: string = '1.1'
  private _trk: GPXTrk[] | undefined = undefined
  private _rte: GPXRte[] | undefined = undefined
  private _wpt: GPXWayPoint[] | undefined = undefined
  private _metadata: GPXMetadata | undefined = undefined
  creator: string = 'Digimap'
  doc: XMLDocument | null = null

  parseFromString(str: string) {
    try {
      const xml: XMLDocument = new DOMParser().parseFromString(str, 'text/xml')
      this.doc = xml

      const gpxNode = xml.querySelector('gpx') as Element
      // const version =
      //   gpxNode.getAttribute('version') || gpxNode.getAttribute('Version')
      this.creator =
        gpxNode.getAttribute('creator') || gpxNode.getAttribute('Creator') || ''
    } catch (error) {
      console.warn('Gpx parseFromString error', error)
    }
    return this
  }

  get metadata(): GPXMetadata {
    if (this._metadata === undefined) {
      if (this.doc === null) throw Error('Provide a valid xml document first.')
      const metadataNodes: Element[] = Array.from(
        this.doc.getElementsByTagName('metadata')
      )
      if (metadataNodes.length === 1) {
        this._metadata = ['name', 'time', 'desc'].reduce((acc, nodeName) => {
          const nodes = metadataNodes[0].getElementsByTagName(nodeName)
          if (nodes.length === 1) {
            // @todo link node has attributes, handle it differently
            if (
              nodes[0].getAttributeNames().length === 0 &&
              nodes[0].textContent
            ) {
              return { ...acc, [nodeName]: nodes[0].textContent }
            }
          }
          return acc
        }, {})
      } else {
        this._metadata = {}
      }
    }
    return this._metadata
  }

  set metadata(_metadata: GPXMetadata) {
    this._metadata = _metadata
  }

  set trk(_trk: GPXTrk[]) {
    this._trk = _trk
  }

  get trk(): GPXTrk[] {
    if (this._trk === undefined) {
      if (!this.doc) throw Error('Provide a valid xml document first.')
      const trackNodes = Array.from(this.doc.getElementsByTagName('trk'))
      const tracks: GPXTrk[] = trackNodes
        .map(trkNode => {
          const trackSegmentNodes = Array.from(
            trkNode.getElementsByTagName('trkseg')
          )
          const name = getElementValue(trkNode, 'name')

          const trkSegs: GPXTrkseg[] = trackSegmentNodes
            .map(trackSegmentNode => {
              const trackPointNodes = Array.from(
                trackSegmentNode.getElementsByTagName('trkpt')
              )
              const trkpt = getPts(trackPointNodes)
              return { trkpt }
            })
            .filter(t => t.trkpt.length !== 0)

          return { trkseg: trkSegs, ...(name && { name }) }
        })
        .filter(t => t.trkseg.length !== 0)

      this._trk = tracks
    }
    return this._trk
  }

  get rte(): GPXRte[] {
    if (this._rte === undefined) {
      if (!this.doc) throw Error('Provide a valid xml document first.')
      const routeNodes = Array.from(this.doc.getElementsByTagName('rte'))
      const routes: GPXRte[] = routeNodes
        .map(routeNode => {
          const routePointNodes = Array.from(
            routeNode.getElementsByTagName('rtept')
          )

          const rtept = getPts(routePointNodes)
          return { rtept }
        })
        .filter(r => r.rtept.length !== 0)
      this._rte = routes
    }
    return this._rte
  }

  getName(): string | undefined {
    return (
      this.metadata.name ||
      (this.trk.length === 1 ? this.trk[0].name : undefined)
    )
  }

  getTracksLength() {
    return this.trk.length
  }

  getTracksPaths(): google.maps.LatLngLiteral[][] {
    return this.trk.map(({ trkseg }) => {
      return trkseg.reduce((acc: google.maps.LatLngLiteral[], ts) => {
        const points = ts.trkpt.map(toLatLngLiteral)
        return [...acc, ...points]
      }, [])
    })
  }

  setTrackAndName(name: string, paths: google.maps.LatLngLiteral[]): void {
    this.metadata = {
      name,
    }
    this.trk = [
      {
        trkseg: [
          {
            trkpt: paths.map(toGpxLatLng),
          },
        ],
      },
    ]
  }

  getRoutesLength() {
    return this.rte.length
  }

  get wpt() {
    if (this._wpt === undefined) {
      if (!this.doc) throw Error('Provide a valid xml document first.')
      const nodes = Array.from(this.doc.getElementsByTagName('wpt'))
      this._wpt = getPts(nodes)
    }
    return this._wpt
  }

  getWayPointsLength() {
    return this.wpt.length
  }

  toString() {
    return gpxToString(this)
  }
}
