import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => {
  return {
    '@global': {
      '.full-height-without-toolbar': {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        [theme.breakpoints.up('sm')]: {
          height: `calc(100vh - ${
            //@ts-ignore
            theme.mixins.toolbar[theme.breakpoints.up('sm')]['minHeight']
          }px)`,
        },
        [`${theme.breakpoints.up('xs')}`]: {
          height: `calc(100vh - ${
            //@ts-ignore
            theme.mixins.toolbar[`${theme.breakpoints.up('xs')}`]['minHeight']
          }x)`,
        },
      },
    },
  }
})

export default function GlobalStyles(props: { children: any }) {
  useStyles()
  return props.children
}
