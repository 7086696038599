import React, { useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { getBalladByIdOrSlug } from '../../@digimap/lib/ballad'
import { BalladContext } from '../../state/ballads/BalladProvider'
import { balladActions } from '../../state/ballads/balladReducer'
import Ballad from './AdminBalladDetails'

interface AdminBalladDetailsContainerProps
  extends RouteComponentProps<{
    idOrSlug: string
  }> {}
export default function AdminBalladDetailsContainer(
  props: AdminBalladDetailsContainerProps
) {
  const { ballads, balladDispatch } = useContext(BalladContext)
  const ballad = getBalladByIdOrSlug(props.match.params.idOrSlug, ballads)

  if (!ballad) return <Redirect to='/not-found' />

  return (
    <Ballad
      ballad={ballad}
      onDelete={(balladId) => {
        balladDispatch(balladActions.deleteBallad(balladId))
        props.history.push(`/admin/ballads`)
      }}
      onDraftToggle={(balladId, draft) => {
        balladDispatch(balladActions.setDraft(balladId, draft))
      }}
    />
  )
}
