import { ControlPosition } from 'leaflet'
import React from 'react'
import CustomControl from '../leaflet-react/components/CustomControl'
import PublicTransportLineSelect from '../PublicTransportLineSelect'

interface LeafletPublicTransportLinesSelectControlProps {
  onChange: (line: string | null) => void
  position: ControlPosition
  value: string | null
}

export default function LeafletPublicTransportLinesSelectControl(
  props: LeafletPublicTransportLinesSelectControlProps
) {
  return (
    <CustomControl position={props.position}>
      <PublicTransportLineSelect
        onChange={props.onChange}
        value={props.value}
      />
    </CustomControl>
  )
}
