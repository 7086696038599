import Grid from '@mui/material/Grid'
import LeafletMap from '../../../components/leaflet-react/components/LeafletMap'
import { DEFAULT_MAP_OPTIONS } from '../../../constants/leaflet'
import GpxFilesList from './GpxFilesList'
import { GpxContext } from '../../../state/gpx/GpxProvider'
import { useContext } from 'react'

export default function GpxHistoryList() {
  const { gpx: gpxFiles } = useContext(GpxContext)

  return (
    <Grid container spacing={0} className='full-height-without-toolbar'>
      <Grid item xs={12} md={4} style={{ overflow: 'auto', height: '100%' }}>
        <GpxFilesList gpxFiles={gpxFiles} />
      </Grid>

      <Grid item xs={12} md={8}>
        <LeafletMap opts={DEFAULT_MAP_OPTIONS} />
      </Grid>
    </Grid>
  )
}
