import { RouteComponentProps } from 'react-router-dom'

import GpxHistoryList from './components/GpxHistoryList'

interface AdminGpxHistoryContainerProps extends RouteComponentProps<{}> {}

export default function AdminGpxHistoryContainer(
  props: AdminGpxHistoryContainerProps
) {
  return <GpxHistoryList />
}
