/* global google */
import { WayPoint } from '../../../@digimap/types'

const MAX_WAYPOINTS = 25

const getDirectionsResult = async (
  allDirectionsWaypoints: google.maps.DirectionsWaypoint[],
  type: string
): Promise<google.maps.DirectionsResult> => {
  const directionsService = new google.maps.DirectionsService()

  if (allDirectionsWaypoints.length < 2)
    return Promise.reject(
      `Cannot request direction for only ${allDirectionsWaypoints.length} waypoints`
    )
  const directionsWaypoints = allDirectionsWaypoints.slice()
  const originDirectionWayPoint = directionsWaypoints.shift() as google.maps.DirectionsWaypoint
  const destinationDirectionWayPoint = directionsWaypoints.pop() as google.maps.DirectionsWaypoint

  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin: originDirectionWayPoint.location,
        destination: destinationDirectionWayPoint.location,
        waypoints: directionsWaypoints,
        optimizeWaypoints: false,
        travelMode:
          type === 'ride'
            ? google.maps.TravelMode.BICYCLING
            : google.maps.TravelMode.WALKING,
        //provideRouteAlternatives: true
      },
      function (directionsResult, directionsStatus) {
        if (directionsStatus === google.maps.DirectionsStatus.OK) {
          resolve(directionsResult)
        } else {
          reject(`Directions request failed due to ${directionsStatus}`)
        }
      }
    )
  })
}

function createDirectionsWayPointsBatches(wayPoints: WayPoint[]) {
  const waypts = wayPoints.map(
    ({
      lat,
      lng,
    }: google.maps.LatLngLiteral): google.maps.DirectionsWaypoint => {
      return {
        location: `${lat},${lng}`,
        stopover: true,
      }
    }
  )

  // bacth of 25 DirectionsWaypoints with overlay destination -> origin
  const batches: google.maps.DirectionsWaypoint[][] = []
  let startIndex = 0
  const len = waypts.length
  while (startIndex < len - 1) {
    const endIndex = Math.min(startIndex + MAX_WAYPOINTS, len)
    batches.push(waypts.slice(startIndex, endIndex))
    startIndex = endIndex - 1
  }
  return batches
}

export default async function getDirections(
  wayPoints: WayPoint[],
  type: string
): Promise<google.maps.DirectionsResult[]> {
  const wayPointsBatches = createDirectionsWayPointsBatches(wayPoints)

  const promises = wayPointsBatches.map(async (batch) => {
    return await getDirectionsResult(batch, type)
  })

  const directionsResults = await Promise.all(promises)
  return directionsResults
}
