/* global google */
import { useContext, useEffect, useState } from 'react'
import { GoogleMapLayer, LayerProps } from '../common/types'
import { GoogleMapContext } from '../contexts/GoogleMapContext'

const Layer = ({ type, opts }: LayerProps) => {
  const layerId = `${type}-layer`
  const { state, dispatch } = useContext(GoogleMapContext)
  const [layer, setLayer] = useState<GoogleMapLayer | undefined>(undefined)

  useEffect(() => {
    if (state.map === undefined) return
    const layerNameToClass = {
      bicycling: google.maps.BicyclingLayer,
      traffic: google.maps.TrafficLayer,
      transit: google.maps.TransitLayer,
    }
    const layer =
      type === 'traffic'
        ? new layerNameToClass[type](opts)
        : new layerNameToClass[type]()
    layer.setMap(state.map)
    setLayer(layer)

    // Add the layer to state.objects
    dispatch({
      type: 'add_object',
      object: layer,
      id: layerId,
    })

    // Remove the layer when the component is unmounted
    return () => dispatch({ type: 'remove_object', id: layerId })
    // eslint-disable-next-line
  }, [state.map])

  useEffect(() => {
    if (type !== 'traffic' || opts === undefined || layer === undefined) return
    ;(layer as google.maps.TrafficLayer).setOptions(opts)
    // eslint-disable-next-line
  }, [opts])

  return null
}

Layer.displayName = 'layer'

export default Layer
