import { GeoFile } from '../types/geoFiles'
import { useState } from 'react'

const useGeoFile = (initial: GeoFile[]) => {
  const [geoFiles, setGeoFiles] = useState<GeoFile[]>(initial)

  const setGeoFileVisible = (filename: string, visible: boolean) => {
    const newGpxFilesUI = geoFiles.map(f => {
      if (f.filename === filename) {
        f.visible = visible
      }
      return f
    })
    setGeoFiles(newGpxFilesUI)
  }

  return { geoFiles, setGeoFileVisible, setGeoFiles }
}

export default useGeoFile
