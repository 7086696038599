import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { OSMElement, whitelistTags } from '../lib/overpass/helpers/osmElement'
import OverpassService from '../lib/overpass/overpassService'

export default function useOverpass(MapContext: any) {
  const { state } = useContext(MapContext)
  const [POIs, setPOIs] = useState<OSMElement[]>([])
  const overpassServiceRef = useRef<OverpassService | null>(null)
  const [showPOIs, setShowPOIs] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchPOIs = useCallback(() => {
    if (state.map && overpassServiceRef.current) {
      const bounds = state.map.getBounds()
      overpassServiceRef.current.prepareRequest(
        state.map.getZoom(),
        bounds === null ? undefined : bounds
      )
    }
    setShowPOIs(true)
  }, [state.map])

  useEffect(() => {
    if (state.map === undefined) return

    if (overpassServiceRef.current !== null) return

    overpassServiceRef.current = new OverpassService({
      onlyNew: true,
      expandBounds: false,
      minZoom: 1,
      query: `(
          node["tourism"~"^(artwork|attraction|museum|theme_park)$"]["name"]({{bbox}});
          way["tourism"~"^(artwork|attraction|museum|theme_park)$"]["name"]({{bbox}});
          relation["tourism"~"^(artwork|attraction|museum|theme_park)$"]["name"]({{bbox}});
          node["tourism"="information"]["board_type"="history"]({{bbox}});
          way["tourism"="information"]["board_type"="history"]({{bbox}});
          relation["tourism"="information"]["board_type"="history"]({{bbox}});
          node["tourism"="yes"]["wikipedia"]["name"]({{bbox}});
          way["tourism"="yes"]["wikipedia"]["name"]({{bbox}});
          relation["tourism"="yes"]["wikipedia"]["name"]({{bbox}});
          node["tourism"="viewpoint"]({{bbox}});
          way["tourism"="viewpoint"]({{bbox}});
          relation["tourism"="viewpoint"]({{bbox}});
          node["heritage"~"^(1|2|3)$"]({{bbox}});
          way["heritage"~"^(1|2|3)$"]({{bbox}});
          relation["heritage"~"^(1|2|3)$"]({{bbox}});
          node["historic"]["historic"!="memorial"]["historic"!="lavoir"]["historic"!="wayside_cross"]({{bbox}});
          way["historic"]["historic"!="memorial"]["historic"!="lavoir"]["historic"!="wayside_cross"]({{bbox}});
          relation["historic"]["historic"!="memorial"]["historic"!="lavoir"]["historic"!="wayside_cross"]({{bbox}});
        );
       out qt center;`,
      onSuccess: (newPOIs) => {
        setPOIs((prevPOIs) => [...prevPOIs, ...newPOIs])
      },
      onBeforeRequest: () => setLoading(true),
      onAfterRequest: () => setLoading(false),
      whitelistTags,
    })

    return () => {
      if (overpassServiceRef.current !== null) {
        overpassServiceRef.current.clear()
        overpassServiceRef.current = null
      }
    }
  }, [state.map])

  return { fetchPOIs, POIs, showPOIs, setShowPOIs, loadingPOIs: loading }
}
