import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useContext, useState } from 'react'
import { balladStatuses, BALLAD_STATUS } from '../../../@digimap/lib/ballad'
import { isBalladCompleted } from '../../../@digimap/lib/user'
import { Ballad } from '../../../@digimap/types'
import LeafletMap from '../../../components/leaflet-react/components/LeafletMap'
import BalladSilhouette from '../../../components/leaflet/BalladSilhouette'
import LeafletPublicTransportLinesSelectControl from '../../../components/leaflet/PublicTransportLineSelectControl'
import LeafletSelectControl from '../../../components/leaflet/SelectControl'
import { DEFAULT_MAP_OPTIONS } from '../../../constants/leaflet'
import { BalladContext } from '../../../state/ballads/BalladProvider'
import { PublicTransportContext } from '../../../state/publicTransport/PublicTransportProvider'
import { UserContext } from '../../../state/user/UserProvider'
import useFilterableBallads from '../hooks/useFilterableBallads'
import BalladList from './BalladList'

const distanceSelectOptions = [
  { label: 'All distances', value: '' },
  { label: '<= 20 km', value: '0-20' },
  { label: '20 to 40 km', value: '20-40' },
  { label: '40 to 70 km', value: '40-70' },
  { label: '70 to 100 km', value: '70-100' },
  { label: '> 100 km', value: '100-' },
]

interface FilterableBalladsMapListProps {
  onTabClick: (
    balladStatus: BALLAD_STATUS | undefined,
    balladType?: string
  ) => void
  onBalladClick: (ballad: Ballad) => void
  balladStatus?: string
  balladType?: string
  showIfCompleted: boolean
  displayDraft: boolean
}

export default function FilterableBalladsMapList(
  props: FilterableBalladsMapListProps
) {
  const { onTabClick, onBalladClick, balladStatus, balladType, displayDraft } =
    props

  const { ballads: allBallads } = useContext(BalladContext)
  const { groupedByLines } = useContext(PublicTransportContext)
  const { completedIds } = useContext(UserContext)

  const theme = useTheme()
  const hidden = useMediaQuery(theme.breakpoints.down('sm'))

  const [highlight, setHighlight] = useState<null | string>(null)

  const {
    ballads,
    publicTransportLineFilter,
    setPublicTransportLineFilter,
    distanceFilter,
    setDistanceFilter,
  } = useFilterableBallads(
    allBallads,
    groupedByLines,
    distanceSelectOptions,
    displayDraft,
    balladStatus,
    balladType
  )

  const onBalladMouseEnterMemo = useCallback((ballad: Ballad) => {
    setHighlight(ballad._id)
  }, [])

  const onBalladMouseLeaveMemo = useCallback(() => {
    setHighlight(null)
  }, [])

  const onDistanceFilterSelectMemo = useCallback(
    (distanceStr: string) => {
      setDistanceFilter(distanceStr)
    },
    [setDistanceFilter]
  )

  const onPublicTransportLineFilterSelectMemo = useCallback(
    (line: string | null) => {
      setPublicTransportLineFilter(line)
    },
    [setPublicTransportLineFilter]
  )

  // @todo if double rendering, remove setTabsValue and use useMemo to get tabsValue
  const [tabsValue, setTabsValue] = useState(
    balladStatuses.findIndex((t) => t.status === balladStatus)
  )

  return (
    <Grid container spacing={0} className='full-height-without-toolbar'>
      {!hidden && (
        <Grid item xs={12} md={4} style={{ overflow: 'auto', height: '100%' }}>
          <Tabs
            value={tabsValue}
            onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
              setTabsValue(newValue)
              const balladStatus = balladStatuses[newValue].status
              onTabClick(balladStatus, balladType)
            }}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'>
            {balladStatuses.map(({ icon, label }) => (
              <Tab
                icon={<Icon>{icon}</Icon>}
                aria-label={label}
                key={label}
                style={{ minWidth: '56px' }} // @todo remove hack to prevent icons to become huge
              />
            ))}
          </Tabs>
          <BalladList
            ballads={ballads}
            onBalladClick={onBalladClick}
            onBalladMouseEnter={onBalladMouseEnterMemo}
            onBalladMouseLeave={onBalladMouseLeaveMemo}
          />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <LeafletMap opts={DEFAULT_MAP_OPTIONS} />
        <LeafletSelectControl
          options={distanceSelectOptions}
          value={distanceFilter}
          position='topright'
          onChange={onDistanceFilterSelectMemo}
        />
        <LeafletPublicTransportLinesSelectControl
          value={publicTransportLineFilter}
          position='topright'
          onChange={onPublicTransportLineFilterSelectMemo}
        />
        {ballads.map((b) => (
          <BalladSilhouette
            onClick={onBalladClick}
            onMouseOver={onBalladMouseEnterMemo}
            onMouseOut={onBalladMouseLeaveMemo}
            ballad={b}
            isCompletedByUser={
              props.showIfCompleted
                ? isBalladCompleted(b, completedIds)
                : undefined
            }
            key={b.slug}
            isHighlighted={highlight === b._id}
          />
        ))}
      </Grid>
    </Grid>
  )
}

FilterableBalladsMapList.defaultProps = {
  displayDraft: false,
}

FilterableBalladsMapList.displayName = 'FilterableBalladsMapList'
