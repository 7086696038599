import { PublicTransportStationFeatureCollection } from '../@digimap/types/'
import {
  PublicTransportBodyRequest,
  PublicTransportStationsGroupedByLinesResponse,
  UpdateNoReturnResponse,
} from '../@digimap/types/api'
import apiFetch from './apiFetch'

export async function fetchPublicTransportStations(ids: string[] | undefined) {
  return await apiFetch<void, PublicTransportStationFeatureCollection>(
    ids ? `publicTransport/${ids.join(',')}` : 'publicTransport'
  )
}

export async function fetchPublicTransportStationsGroupedByLines() {
  return await apiFetch<void, PublicTransportStationsGroupedByLinesResponse>(
    'publicTransport/lines'
  )
}

export async function updateBalladPublicTransportStations(
  balladId: string,
  { startIds, endIds }: PublicTransportBodyRequest
) {
  if (!Array.isArray(startIds) && !Array.isArray(endIds)) {
    throw Error(`Please provide at least an array of start ids or end ids`)
  }

  return await apiFetch<PublicTransportBodyRequest, UpdateNoReturnResponse>(
    `ballads/${balladId}/publicTransport`,
    'PUT',
    {
      ...(Array.isArray(startIds) && { startIds }),
      ...(Array.isArray(endIds) && { endIds }),
    }
  )
}
