import { WayPoint } from '../../../@digimap/types'

export default function getGoogleMapsDirectionsUrl(
  points: WayPoint[],
  loop: boolean
) {
  const pts = points.map(
    ({ lat, lng }: google.maps.LatLngLiteral) => `${lat},${lng}`
  )
  if (loop) pts.push(pts[0])
  return `https://www.google.fr/maps/dir/${pts.join(
    '/'
  )}/data=!3m1!4b1!4m2!4m1!3e1`
}
