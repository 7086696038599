import { RouteComponentProps } from 'react-router-dom'
import UploadGpxForm from './UploadGpxForm'

interface BalladPageProps extends RouteComponentProps<{}> {}
function UploadGpxFormContainer(props: BalladPageProps) {
  return (
    <UploadGpxForm
      onSuccess={() => {
        props.history.push(`/admin/gpx`)
      }}
    />
  )
}

export default UploadGpxFormContainer
