import { Gpx } from '../../@digimap/types'
import { GpxResponse } from '../../@digimap/types/api'

// --- TYPES

enum ACTION_TYPES {
  SET_GPX = 'SET_GPX',
}

export interface GpxState {
  gpx: Gpx[]
}

// --- ACTIONS

export const gpxActions = {
  reset: function ({ gpx }: GpxResponse) {
    return {
      type: ACTION_TYPES.SET_GPX as ACTION_TYPES.SET_GPX,
      gpx,
    }
  },
}

export type GpxAction = ReturnType<typeof gpxActions.reset>

// --- REDUCER

export const initialState: GpxState = {
  gpx: [],
}

function reducer(state: GpxState = initialState, action: GpxAction): GpxState {
  switch (action.type) {
    case ACTION_TYPES.SET_GPX:
      return {
        gpx: action.gpx,
      }

    default:
      return state
  }
}

export default reducer
