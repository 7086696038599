import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Switch from '@mui/material/Switch'
import GPX from '../../../lib/GPX/Gpx'

interface GeoFilesListItemProps {
  content: GPX | Object
  filename: string
  visible?: boolean
  onToggleVisible: (filename: string, visible: boolean) => void
}

export default function GeoFilesListItem({
  content,
  filename,
  visible = true,
  onToggleVisible,
}: GeoFilesListItemProps) {
  let name = filename
  let summary = ''
  if (content instanceof GPX) {
    name = content.getName() || filename
    summary = `${content.getTracksLength()} tracks / ${content.getRoutesLength()} routes / ${content.getWayPointsLength()} wayPoints`
  }

  const onSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onToggleVisible(filename, checked)
  }

  return (
    <ListItem>
      <ListItemText
        id={`switch-list-label-${name}`}
        primary={name}
        secondary={summary}
      />
      <ListItemSecondaryAction>
        <Switch
          edge='end'
          onChange={onSwitch}
          checked={visible}
          inputProps={{ 'aria-labelledby': `switch-list-label-${name}` }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
