import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import ContentEditable from 'react-contenteditable'
import { WayPoint } from '../../../@digimap/types'

const reorder = (list: WayPoint[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getAlphabetMarker = (index: number): string => {
  return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index % 26]
}

const isNameAdequate = (name?: string, commune?: string) => {
  if (!name || name.length === 0) return true
  if (name.indexOf('POI:') === 0) return true
  if (name.indexOf('STATION:') === 0) return true
  if (name === commune) return true
  return false
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  background: isDragging ? 'lightgreen' : 'inherit',
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
})

interface ReorderableWayPointsListProps {
  wayPoints: WayPoint[]
  canDrag: boolean
  // onSwap: (startIndex: number, endIndex: number) => void
  onReorder: (items: WayPoint[]) => void
  onRemove: (index: number) => void
  onRename: (index: number, name: string) => void
}
const ReorderList = (props: ReorderableWayPointsListProps) => {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      props.wayPoints,
      result.source.index,
      result.destination.index
    )
    props.onReorder(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {props.wayPoints.map((item, index) => (
              <Draggable
                isDragDisabled={!props.canDrag}
                key={`${item.name || 'untitled'}-${index}`}
                draggableId={`${item.name || 'untitled'}-${index}`}
                index={index}>
                {(provided, snapshot) => (
                  <ListItem
                    dense
                    ContainerComponent='div'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    {props.canDrag && (
                      <ListItemIcon>
                        <Icon>drag_handle</Icon>
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={
                        <ContentEditable
                          html={item.name || '∅'}
                          onChange={() => {}}
                          onBlur={(e) => {
                            props.onRename(
                              index,
                              e.target.innerHTML === '∅'
                                ? ''
                                : e.target.innerHTML.trim()
                            )
                          }}
                        />
                      }
                      secondaryTypographyProps={{
                        style: {
                          color: isNameAdequate(item.name, item.commune)
                            ? '#0000008a'
                            : '#FF0000',
                        },
                      }}
                      secondary={`${getAlphabetMarker(index)} - ${
                        item.commune || ''
                      }`}
                    />
                    {props.canDrag && (
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            props.onRemove(index)
                          }}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default ReorderList
