import { ClientUser, RegisteredUser } from '../../@digimap/types'
import { FetchUserResponse } from '../../@digimap/types/api'

enum ACTION_TYPES {
  SET_USER = 'SET_USER',
  ADD_COMPLETED = 'ADD_COMPLETED',
  REMOVE_COMPLETED = 'REMOVE_COMPLETED',
}

// --- ACTIONS

export const userActions = {
  reset: function ({ user, completedIds, from }: FetchUserResponse) {
    return {
      type: ACTION_TYPES.SET_USER as ACTION_TYPES.SET_USER,
      user,
      completedIds,
      from,
    }
  },
  markBalladAsCompleted: function (completedId: string) {
    return {
      type: ACTION_TYPES.ADD_COMPLETED as ACTION_TYPES.ADD_COMPLETED,
      completedId,
    }
  },
  unmarkBalladAsCompleted: function (completedId: string) {
    return {
      type: ACTION_TYPES.REMOVE_COMPLETED as ACTION_TYPES.REMOVE_COMPLETED,
      completedId,
    }
  },
}

// --- TYPES

export type UserAction =
  | ReturnType<typeof userActions.reset>
  | ReturnType<typeof userActions.markBalladAsCompleted>
  | ReturnType<typeof userActions.unmarkBalladAsCompleted>

export type UserState = (RegisteredUser | ClientUser) & {
  completedIds: string[]
  from?: { lat: number; lng: number }
}

// --- REDUCER

const clientUser: ClientUser = {
  role: 'client',
}

export const initialState: UserState = {
  ...clientUser,
  completedIds: [],
}

function reducer(
  state: UserState = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case ACTION_TYPES.SET_USER:
      // @ts-ignore
      return {
        ...initialState,
        ...action.user,
        ...(action.completedIds && { completedIds: action.completedIds }),
        ...(action.from && { from: action.from }),
      }
    case ACTION_TYPES.ADD_COMPLETED:
      return state.completedIds.includes(action.completedId)
        ? state
        : {
            ...state,
            completedIds: [...state.completedIds, action.completedId],
          }

    case ACTION_TYPES.REMOVE_COMPLETED:
      const filteredCompletedIds = state.completedIds.filter(
        (id) => id !== action.completedId
      )
      return filteredCompletedIds.length === state.completedIds.length
        ? state
        : {
            ...state,
            completedIds: filteredCompletedIds,
          }

    default:
      return state
  }
}

export default reducer
