import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { getBalladByIdOrSlug } from '../../@digimap/lib/ballad'
import { BalladType } from '../../@digimap/types'
import { createBallad, fetchBallad, updateBallad } from '../../services'
import { BalladContext } from '../../state/ballads/BalladProvider'
import { balladActions } from '../../state/ballads/balladReducer'
import BaseBalladForm from './BaseBalladForm'

interface AdminBalladFormContainerProps
  extends RouteComponentProps<{
    idOrSlug?: string
  }> {}
export default function AdminBalladFormContainer(
  props: AdminBalladFormContainerProps
) {
  const { enqueueSnackbar } = useSnackbar()
  const { ballads, balladDispatch } = useContext(BalladContext)

  let ballad
  if (props.match.params.idOrSlug) {
    ballad = getBalladByIdOrSlug(props.match.params.idOrSlug, ballads)
  } else {
    ballad = {
      slug: '',
      name: '',
      type: BalladType.RIDE,
      loop: true,
      draft: true,
    }
  }

  if (!ballad) return <Redirect to='/not-found' />

  return (
    <BaseBalladForm
      ballad={ballad}
      onSubmit={async (formData, _id) => {
        let balladId: string
        if (_id) {
          const { updatedId } = await updateBallad(_id, formData)
          balladId = updatedId
          enqueueSnackbar('Ballad successfully updated', { variant: 'success' })
          const balladResponse = await fetchBallad(updatedId)
          await balladDispatch(balladActions.updateBallad(balladResponse))
        } else {
          const { insertedId } = await createBallad(formData)
          balladId = insertedId
          enqueueSnackbar('Ballad successfully created', { variant: 'success' })
          const balladResponse = await fetchBallad(insertedId)
          await balladDispatch(balladActions.addBallad(balladResponse))
        }
        props.history.push(`/admin/ballads/${balladId}`)
      }}
    />
  )
}
