/* global google */
import L from 'leaflet'

/**
 * Remove the trailing slash from an URL and return it
 */
export function removeTrailingSlash(url: string): string {
  return url.replace(/\/*$/, '')
}

/**
 * Increase the dimension of the bounds
 */
export function buildLargerBounds(
  bounds: L.LatLngBounds | google.maps.LatLngBounds,
  increaseBounds: boolean = true
): L.LatLngBounds {
  const ne =
    bounds instanceof L.LatLngBounds
      ? bounds.getNorthEast()
      : bounds.getNorthEast().toJSON()
  const sw =
    bounds instanceof L.LatLngBounds
      ? bounds.getSouthWest()
      : bounds.getSouthWest().toJSON()

  //@ts-ignore
  const width = Math.abs(ne.lng - sw.lng)
  //@ts-ignore
  const height = Math.abs(ne.lat - sw.lat)
  const biggestDimension = width > height ? width : height
  const halfBiggestDimentions = increaseBounds ? biggestDimension / 2 : 0

  return L.latLngBounds(
    L.latLng(
      sw.lat - halfBiggestDimentions,
      sw.lng - halfBiggestDimentions
    ).wrap(),
    L.latLng(
      ne.lat + halfBiggestDimentions,
      ne.lng + halfBiggestDimentions
    ).wrap()
  )
}
