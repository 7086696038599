import { Ballad } from '../../../@digimap/types'

const isBalladStartingOrEndingAt = (
  ballad: Ballad,
  publicTransportStationId: string
): boolean => {
  if (
    ballad.publicTransportStartIds &&
    ballad.publicTransportStartIds.some((id) => publicTransportStationId === id)
  ) {
    return true
  }
  if (
    ballad.publicTransportEndIds &&
    ballad.publicTransportEndIds.some((id) => publicTransportStationId === id)
  ) {
    return true
  }
  return false
}

export default isBalladStartingOrEndingAt
