import { useContext, useEffect, useState } from 'react'
import { fetchUser } from '../../services'
import { mayBeAuthenticated } from '../../services/apiFetch'
import { UserContext } from './UserProvider'
import { userActions } from './userReducer'

export default function UserFetcher(props: { children: any }) {
  const { userDispatch } = useContext(UserContext)
  const [, setLoading] = useState(true)

  useEffect(() => {
    const fetchAndSetUser = async () => {
      try {
        const user = await fetchUser()
        userDispatch(userActions.reset(user))
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    if (mayBeAuthenticated()) {
      fetchAndSetUser()
    }
    // eslint-disable-next-line
  }, [])

  return props.children
}
