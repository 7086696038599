import { ImageOverlay } from 'leaflet'
import { useContext, useEffect, useState } from 'react'
import { Overlay } from '../../@digimap/types'
import { CDN_ENDPOINT } from '../../constants/endpoints'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface BalladOverlayProps {
  image: Overlay
  opacity: number
}

export default function BalladOverlay({ image, opacity }: BalladOverlayProps) {
  const { state } = useContext(LeafletContext)
  const { filename, bounds } = image
  const [overlay, setOverlay] = useState<ImageOverlay | undefined>()

  useEffect(() => {
    if (state.map === undefined) return
    if (!Array.isArray(bounds)) return

    const overlay = new ImageOverlay(`${CDN_ENDPOINT}/${filename}`, bounds)
    overlay.addTo(state.map)
    setOverlay(overlay)

    return () => {
      overlay.remove()
    }
  }, [bounds, filename, image, state.map])

  useEffect(() => {
    if (overlay) overlay.setOpacity(opacity / 100)
  }, [opacity, overlay])

  return null
}
