import React, { useReducer } from 'react'
import userReducer, { initialState, UserAction, UserState } from './userReducer'

const UserContext = React.createContext<
  UserState & {
    userDispatch: React.Dispatch<UserAction>
  }
>({ ...initialState, userDispatch: () => {} })

function UserProvider(props: { children: React.ReactNode }) {
  const [userState, userDispatch] = useReducer(userReducer, initialState)
  return (
    <UserContext.Provider value={{ ...userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}

UserProvider.displayName = 'UserProvider'
UserProvider.whyDidYouRender = {
  logOnDifferentValues: true,
}

export { UserContext, UserProvider }
