/* global google */
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import React, { useContext, useEffect } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { createFeatureCollection } from '../../../@digimap/types/geojsonUtils'
import { PublicTransportStationFeature } from '../../../@digimap/types/publicTransport'
import CityMapperLink from '../../../components/CityMapper/CityMapperLink'
import { GoogleMapContext } from '../../../components/googlemaps-react/contexts/GoogleMapContext'
import getPublicTransportLineColor from '../../../lib/getPublicTransportLineColor'

interface ReorderablePublicTransportListProps {
  stations?: PublicTransportStationFeature[]
  visible: boolean
  onReorder: (items: PublicTransportStationFeature[]) => void
  onRemove: (item: PublicTransportStationFeature) => void
  isStart: boolean
}

const reorder = (
  list: PublicTransportStationFeature[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  background: isDragging ? 'lightgreen' : 'inherit',
  ...draggableStyle,
})

export default function ReorderablePublicTransportList({
  stations,
  visible,
  onReorder,
  onRemove,
  isStart,
}: ReorderablePublicTransportListProps) {
  const { state } = useContext(GoogleMapContext)

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    if (!stations) return

    const newItems = reorder(
      stations,
      result.source.index,
      result.destination.index
    )

    onReorder(newItems)
  }

  useEffect(() => {
    if (!state.map) return
    if (!stations) return

    const stationsWithMain = stations.map((station, index) => {
      const { properties } = station
      const newProperties = { ...properties, ...{ main: index === 0 } }
      return { ...station, ...{ properties: newProperties } }
    })

    const features = state.map.data.addGeoJson(
      createFeatureCollection(stationsWithMain)
    )

    state.map.data.setStyle((feature) => {
      return {
        icon: {
          path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
          fillColor: getPublicTransportLineColor(feature.getProperty('line')),
          fillOpacity: 0.8,
          strokeColor: '#000',
          strokeWeight: 1,
          scale: feature.getProperty('main') ? 0.7 : 0.4,
        },
      }
    })

    const infowindow = new google.maps.InfoWindow({})
    const listenerHandle = state.map.data.addListener(
      'click',
      function (event) {
        if (
          event.feature.getProperty('line') === undefined &&
          event.feature.getProperty('name') === undefined
        ) {
          return
        }
        const myHTML = `${event.feature.getProperty(
          'name'
        )} / ${event.feature.getProperty('line')}`
        infowindow.setContent(
          '<div style="width:150px; text-align: center;">' + myHTML + '</div>'
        )
        infowindow.setPosition(event.feature.getGeometry().get())
        infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) })
        infowindow.open(state.map)
      }
    )
    return () => {
      listenerHandle.remove()
      infowindow.close()
      features.forEach((f) => {
        if (f && state.map !== undefined) {
          state.map.data.remove(f)
        }
      })
    }
  }, [state.map, stations])

  useEffect(() => {
    if (!state.map) return
    state.map.data.setMap(visible ? state.map : null)
  }, [state.map, visible])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {stations &&
              stations.map((station, index) => (
                <Draggable
                  key={`${station.properties.name || 'untitled'}-${index}`}
                  draggableId={`${
                    station.properties.name || 'untitled'
                  }-${index}`}
                  index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      dense
                      ContainerComponent='div'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}>
                      <ListItemIcon>
                        <Icon>drag_handle</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <CityMapperLink
                            feature={station}
                            type={isStart ? 'outbound' : 'inbound'}
                          />
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            onRemove(station)
                          }}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
