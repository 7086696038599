import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { balladStatuses, getStatus } from '../../../@digimap/lib/ballad'
import { Ballad } from '../../../@digimap/types'

interface BalladListProps {
  ballads: Ballad[]
  onBalladClick: (ballad: Ballad) => void
  onBalladMouseEnter: (ballad: Ballad) => void
  onBalladMouseLeave: (ballad: Ballad) => void
}

function BalladList(props: BalladListProps) {
  function renderListItem(item: Ballad) {
    const balladStatus = getStatus(item)
    const matchingBalladStatus = balladStatuses.find(
      (b) => b.status === balladStatus
    )
    let icon = matchingBalladStatus ? matchingBalladStatus.icon : ''

    return (
      <ListItem
        key={item._id}
        divider
        button
        onClick={() => {
          props.onBalladClick(item)
        }}
        onMouseEnter={() => {
          props.onBalladMouseEnter(item)
        }}
        onMouseLeave={(e) => {
          props.onBalladMouseLeave(item)
        }}>
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={item.name} secondary={item.slug} />
      </ListItem>
    )
  }

  return <List>{props.ballads.map(renderListItem)}</List>
}

export default BalladList
