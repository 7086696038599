import { Ballad, convertBalladDatabaseToBallad } from '../../@digimap/types'
import { BalladResponse, FetchBalladsResponse } from '../../@digimap/types/api'

// --- TYPES

enum ACTION_TYPES {
  SET_BALLADS = 'SET_BALLADS',
  ADD_BALLAD = 'ADD_BALLAD',
  UPDATE_BALLAD = 'UPDATE_BALLAD',
  DELETE_BALLAD = 'DELETE_BALLAD',
  TOGGLE_DRAFT = 'TOGGLE_DRAFT',
}

export interface BalladState {
  ballads: Ballad[]
}

// --- ACTIONS

export const balladActions = {
  reset: function({ ballads, overlays }: FetchBalladsResponse) {
    return {
      type: ACTION_TYPES.SET_BALLADS as ACTION_TYPES.SET_BALLADS,
      ballads,
      overlays,
    }
  },
  addBallad: function({ ballad, overlays }: BalladResponse) {
    return {
      type: ACTION_TYPES.ADD_BALLAD as ACTION_TYPES.ADD_BALLAD,
      ballad,
      overlays,
    }
  },
  updateBallad: function({ ballad, overlays }: BalladResponse) {
    return {
      type: ACTION_TYPES.UPDATE_BALLAD as ACTION_TYPES.UPDATE_BALLAD,
      ballad,
      overlays,
    }
  },

  setDraft: function(balladId: string, draft: boolean) {
    return {
      type: ACTION_TYPES.TOGGLE_DRAFT as ACTION_TYPES.TOGGLE_DRAFT,
      balladId,
      draft,
    }
  },
  deleteBallad: function(balladId: string) {
    return {
      type: ACTION_TYPES.DELETE_BALLAD as ACTION_TYPES.DELETE_BALLAD,
      balladId,
    }
  },
}

export type BalladAction =
  | ReturnType<typeof balladActions.reset>
  | ReturnType<typeof balladActions.addBallad>
  | ReturnType<typeof balladActions.updateBallad>
  | ReturnType<typeof balladActions.deleteBallad>
  | ReturnType<typeof balladActions.setDraft>

// --- REDUCER

export const initialState: BalladState = {
  ballads: [],
}

function reducer(
  state: BalladState = initialState,
  action: BalladAction
): BalladState {
  switch (action.type) {
    case ACTION_TYPES.SET_BALLADS:
      return {
        ballads: convertBalladDatabaseToBallad(action.ballads, action.overlays),
      }

    case ACTION_TYPES.ADD_BALLAD:
      const newBallad = convertBalladDatabaseToBallad(
        [action.ballad],
        action.overlays
      )[0]
      const already = state.ballads.some(
        (ballad) => ballad._id === newBallad._id
      )
      return already
        ? state
        : { ...state, ballads: [...state.ballads, newBallad] }

    case ACTION_TYPES.UPDATE_BALLAD:
      const updatedBallad = convertBalladDatabaseToBallad(
        [action.ballad],
        action.overlays
      )[0]
      const newBallads = state.ballads.map((ballad) => {
        if (ballad._id === updatedBallad._id) {
          return updatedBallad
        }
        return ballad
      })
      return { ...state, ballads: newBallads }

    case ACTION_TYPES.DELETE_BALLAD:
      return {
        ballads: state.ballads.filter((b) => b._id !== action.balladId),
      }

    case ACTION_TYPES.TOGGLE_DRAFT:
      return {
        ballads: state.ballads.map((b) => {
          if (b._id === action.balladId) {
            return { ...b, draft: action.draft }
          }
          return b
        }),
      }
    default:
      return state
  }
}

export default reducer
