import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { makeStyles } from '@mui/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { isAdmin, isClient } from '../@digimap/lib/user'
import { mayBeAuthenticated } from '../services/apiFetch'
import logout from '../services/logout'
import { UserContext } from '../state/user/UserProvider'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
})

export default function AppBarAndDrawer() {
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [state, setState] = useState({
    left: false,
  })

  const theme = useTheme()
  const hidden = useMediaQuery(theme.breakpoints.down('xs'))

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, left: open })
    }

  const sideList = () => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem component={Link} button key='Points of Interest' to='/'>
          <ListItemIcon>
            <Icon>local_library</Icon>
          </ListItemIcon>
          <ListItemText primary='POIs' />
        </ListItem>
        {!isClient(userContext) && (
          <>
            <ListItem component={Link} button key='All Ballads' to='/ballads'>
              <ListItemIcon>
                <Icon>streetview</Icon>
              </ListItemIcon>
              <ListItemText primary='All Ballads' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='All Hikes'
              to='/ballads/hike'>
              <ListItemIcon>
                <Icon>directions_walk</Icon>
              </ListItemIcon>
              <ListItemText primary='All Hikes' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='All Rides'
              to='/ballads/ride'>
              <ListItemIcon>
                <Icon>directions_bike</Icon>
              </ListItemIcon>
              <ListItemText primary='All Rides' />
            </ListItem>
          </>
        )}
        <ListItem component={Link} button key='Drag' to='/drag'>
          <ListItemIcon>
            <Icon>map</Icon>
          </ListItemIcon>
          <ListItemText primary='Drag' />
        </ListItem>
      </List>
      {isAdmin(userContext) && !hidden && (
        <>
          <Divider />
          <List>
            <ListItem
              component={Link}
              button
              key='Admin / Ballads'
              to='/admin/ballads'>
              <ListItemIcon>
                <Icon>satellite</Icon>
              </ListItemIcon>
              <ListItemText primary='Admin / Ballads' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='Admin / Hike'
              to='/admin/ballads/hike'>
              <ListItemIcon>
                <Icon>directions_walk</Icon>
              </ListItemIcon>
              <ListItemText primary='Admin / Hike' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='Admin / Ride'
              to='/admin/ballads/ride'>
              <ListItemIcon>
                <Icon>directions_bike</Icon>
              </ListItemIcon>
              <ListItemText primary='Admin / Ride' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='Create Ballad'
              to='/admin/ballads/create'>
              <ListItemIcon>
                <Icon>plus_one</Icon>
              </ListItemIcon>
              <ListItemText primary='Create Ballad' />
            </ListItem>
            <ListItem component={Link} button key='Admin / Gpx' to='/admin/gpx'>
              <ListItemIcon>
                <Icon>route</Icon>
              </ListItemIcon>
              <ListItemText primary='Admin / GPX' />
            </ListItem>
            <ListItem
              component={Link}
              button
              key='Upload GPX'
              to='/admin/gpx/create'>
              <ListItemIcon>
                <Icon>plus_one</Icon>
              </ListItemIcon>
              <ListItemText primary='Upload Gpx' />
            </ListItem>
          </List>
        </>
      )}
    </div>
  )

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer(true)}>
            <Icon>menu</Icon>
          </IconButton>
          <Typography variant='h6' noWrap>
            Any Plans for the Weekend?
          </Typography>
          <div className={classes.grow} />
          <IconButton
            edge='end'
            color='inherit'
            aria-label='login'
            onClick={logout}
            href='/login'>
            <Icon>{mayBeAuthenticated() ? 'person' : 'person_outline'}</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={state.left} onClose={toggleDrawer(false)}>
        {sideList()}
      </Drawer>
      <Toolbar />
    </>
  )
}
