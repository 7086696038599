import {
  NearestCommunesResponse,
  NearestCommunesUrlParams,
} from '../@digimap/types/api'
import apiFetch from './apiFetch'

export async function fetchNearestCommunes({
  lat,
  lng,
}: NearestCommunesUrlParams) {
  return await apiFetch<void, NearestCommunesResponse>(
    `communes/nearest/${lat},${lng}`
  )
}
