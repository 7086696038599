import { useContext, useEffect, useState } from 'react'
import { fetchPublicTransportStationsGroupedByLines } from '../../services'
import { PublicTransportContext } from './PublicTransportProvider'
import { publicTransportActions } from './publicTransportReducer'

export default function PublicTransportFetcher(props: { children: any }) {
  const { publicTransportDispatch } = useContext(PublicTransportContext)
  const [, setLoading] = useState(true)

  useEffect(() => {
    const fetchAndSetPublicTransport = async () => {
      try {
        const publicTransportStationsGroupedByLines = await fetchPublicTransportStationsGroupedByLines()
        publicTransportDispatch(
          publicTransportActions.reset(publicTransportStationsGroupedByLines)
        )
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    fetchAndSetPublicTransport()
    // eslint-disable-next-line
  }, [])

  return props.children || null
}
