import { LatLngBounds } from 'leaflet'
import { Ballad, Overlay } from '../@digimap/types'

export default function getUnionBounds(ballad: Ballad): LatLngBounds | null {
  return ballad.images.reduce((bounds: null | LatLngBounds, image: Overlay) => {
    if (image.bounds) {
      if (bounds === null) {
        bounds = new LatLngBounds(image.bounds)
      } else {
        bounds.extend(new LatLngBounds(image.bounds))
      }
    }
    return bounds
  }, null)
}
