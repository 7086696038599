import Grid from '@mui/material/Grid'
import React, { useContext } from 'react'
import { isAdmin } from '../../@digimap/lib/user'
import LeafletMap from '../../components/leaflet-react/components/LeafletMap'
import { DEFAULT_MAP_OPTIONS } from '../../constants/leaflet'
import {
  freeMapOverlayProviders,
  freeMapProviders,
} from '../../constants/leafletMapProviders'
import useGeoFile from '../../hooks/useGeoFile'
import { UserContext } from '../../state/user/UserProvider'
import FilesDropZone from './components/FilesDropZone'
import GeoFilesList from './components/GeoFilesList'
import readGeoFiles from './lib/readGeoFiles'

export default function GeoFilesContainer() {
  const { geoFiles, setGeoFiles, setGeoFileVisible } = useGeoFile([])
  const userContext = useContext(UserContext)
  return (
    <Grid container spacing={0} className='full-height-without-toolbar'>
      <Grid item xs={3} container direction='column'>
        <Grid item>
          <GeoFilesList
            geoFiles={geoFiles}
            onGeoFileVisibilityChange={setGeoFileVisible}
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <FilesDropZone
            dropHandler={async (files: FileList) => {
              const geoF = await readGeoFiles(files)
              setGeoFiles((state) => [...state, ...geoF])
            }}>
            Drop Files Here
          </FilesDropZone>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <LeafletMap
          opts={DEFAULT_MAP_OPTIONS}
          mapProviders={isAdmin(userContext) ? undefined : freeMapProviders}
          overlayLayersProviders={
            isAdmin(userContext) ? undefined : freeMapOverlayProviders
          }
        />
      </Grid>
    </Grid>
  )
}
