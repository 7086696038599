import L from 'leaflet'
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { LeafletContext } from '../contexts/LeafletContext'

class DivControl extends L.Control {
  onAdd(map: L.Map): HTMLElement {
    const container = L.DomUtil.create('div', 'leaflet-bar')
    container.style.background = '#FFFFFF'
    L.DomEvent.disableClickPropagation(container)
    return container
  }
}

interface CustomControlProps {
  position: L.ControlPosition
  children: React.ReactNode
}
const CustomControl = ({
  position = 'topright',
  children,
}: CustomControlProps): React.ReactPortal | null => {
  const { state } = useContext(LeafletContext)
  const [container, setContainer] = useState<HTMLElement>()

  // Add the custom control to the map
  useEffect(() => {
    if (state.map === undefined) return
    const control = new DivControl({ position })
    control.addTo(state.map)
    setContainer(control.getContainer())
  }, [position, state.map])

  return container ? ReactDOM.createPortal(children, container) : null
}

CustomControl.displayName = 'CustomControl'

export default CustomControl
