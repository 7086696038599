import { Overlay } from '../@digimap/types'
import {
  UpdateNoReturnResponse,
  UpdateOverlayBodyRequest,
} from '../@digimap/types/api'
import apiFetch from './apiFetch'

export async function updateOverlayPoints({
  _id,
  mapPoints,
  imagePoints,
  bounds,
}: Overlay) {
  return await apiFetch<UpdateOverlayBodyRequest, UpdateNoReturnResponse>(
    `overlays/${_id}`,
    'PUT',
    {
      mapPoints,
      imagePoints,
      bounds,
    }
  )
}
