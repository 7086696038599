import { useState, useEffect } from 'react'
import loadjs from 'loadjs'
import {
  GOOGLE_MAP_BASE_URI,
  GOOGLE_MAP_LIBRARY_NAMES,
} from '../common/constants'

interface GoogleAPIProps {
  apiKey: string
  useDrawing: boolean
  usePlaces: boolean
  useVisualization: boolean
  useGeometry: boolean
  language?: string
  region?: string
}

const useGoogleAPI = ({
  apiKey,
  useDrawing,
  useGeometry,
  usePlaces,
  useVisualization,
  language,
  region,
}: GoogleAPIProps) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    // Do not load script in SSR moode
    if (typeof document === 'undefined') return

    // Construct the library param
    const librariesMapping = {
      drawing: useDrawing,
      geometry: useGeometry,
      places: usePlaces,
      visualization: useVisualization,
    }
    const libraries = GOOGLE_MAP_LIBRARY_NAMES.filter(
      (library) => librariesMapping[library]
    ).join(',')

    const libraryParam = libraries === '' ? '' : `&libraries=${libraries}`
    const languageParam = language === undefined ? '' : `&language=${language}`
    const regionParam = region === undefined ? '' : `&region=${region}`

    const googleMapScriptUri = `${GOOGLE_MAP_BASE_URI}?key=${apiKey}${libraryParam}${languageParam}${regionParam}`

    if (!loadjs.isDefined('gmap')) loadjs(googleMapScriptUri, 'gmap')

    loadjs.ready('gmap', {
      success: () => {
        setLoaded(true)
      },
      error: () => {
        loadjs.reset()
        console.error('Unable to fetch Google Map sdk')
      },
    })
    // eslint-disable-next-line
  }, [
    // @todo reload script when a parameter change
    // apiKey,
    // language,
    // region,
    // useDrawing,
    // useGeometry,
    // usePlaces,
    // useVisualization,
  ])
  return loaded
}

export default useGoogleAPI
