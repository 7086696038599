import L, { IconOptions, LatLngExpression, MarkerOptions } from 'leaflet'
import { useContext, useEffect } from 'react'
import { LeafletContext } from '../contexts/LeafletContext'
interface MarkerProps {
  latlng: LatLngExpression
  icon?: IconOptions
  options?: MarkerOptions
}
export default function Marker({ latlng, icon, options }: MarkerProps) {
  const { state } = useContext(LeafletContext)

  useEffect(() => {
    if (state.map === undefined) return
    const opts = options || {}
    // @todo icon should come from props.options
    if (icon) {
      opts.icon = L.icon(icon)
    }
    const marker = L.marker(latlng, opts).addTo(state.map)

    return () => {
      // @todo should I remove the icon too?
      marker.remove()
    }
  }, [icon, latlng, options, state.map])
  return null
}

Marker.displayName = 'LeafletMarker'
