import { LatLngBounds } from 'leaflet'
import Clipper from 'js-clipper'

export default class LatLngBoundsList {
  requestedBounds: LatLngBounds[] = []

  add(bounds: LatLngBounds) {
    this.requestedBounds.push(bounds)
  }

  clear() {
    this.requestedBounds = []
  }

  includes(bounds: LatLngBounds): boolean {
    if (this.requestedBounds.length === 0) {
      return false
    }

    const subjectClips = LatLngBoundsList.buildClipsFromBoundsList([bounds])
    const knownClips = LatLngBoundsList.buildClipsFromBoundsList(
      this.requestedBounds
    )
    const clipper = new Clipper.Clipper()
    const solutionPolyTree = new Clipper.PolyTree()

    clipper.AddPaths(subjectClips, Clipper.PolyType.ptSubject, true)
    clipper.AddPaths(knownClips, Clipper.PolyType.ptClip, true)

    clipper.Execute(
      Clipper.ClipType.ctDifference,
      solutionPolyTree,
      Clipper.PolyFillType.pftNonZero,
      Clipper.PolyFillType.pftNonZero
    )

    const solutionExPolygons = Clipper.JS.PolyTreeToExPolygons(solutionPolyTree)

    if (solutionExPolygons.length === 0) {
      return true
    }

    return false
  }

  static buildClipsFromBoundsList(
    bounds: LatLngBounds[]
  ): { X: number; Y: number }[][] {
    return bounds.map((bound) => [
      {
        X: bound.getSouthWest().lng * 1000000,
        Y: bound.getSouthWest().lat * 1000000,
      },
      {
        X: bound.getSouthWest().lng * 1000000,
        Y: bound.getNorthEast().lat * 1000000,
      },
      {
        X: bound.getNorthEast().lng * 1000000,
        Y: bound.getNorthEast().lat * 1000000,
      },
      {
        X: bound.getNorthEast().lng * 1000000,
        Y: bound.getSouthWest().lat * 1000000,
      },
    ])
  }
}
