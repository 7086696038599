import { WayPoint } from '../../../@digimap/types'

enum ACTION_TYPES {
  SET_WAYPOINTS = 'SET_WAYPOINTS',
  ADD_WAYPOINT = 'ADD_WAYPOINT',
  INSERT_WAYPOINT = 'INSERT_WAYPOINT',
  REMOVE_WAYPOINT = 'REMOVE_WAYPOINT',
  UPDATE_WAYPOINT = 'UPDATE_WAYPOINT',
  REVERSE_WAYPOINTS = 'REVERSE_WAYPOINTS',
  REMOVE_LAST_WAYPOINT = 'REMOVE_LAST_WAYPOINT',
  ROTATE_WAYPOINTS = 'ROTATE_WAYPOINTS',
  RENAME_WAYPOINT = 'RENAME_WAYPOINT',
}

export const initialState: WayPointsState = []

type WayPointsState = WayPoint[]

export function setWayPoints(wayPoints: WayPoint[]) {
  return {
    type: ACTION_TYPES.SET_WAYPOINTS as ACTION_TYPES.SET_WAYPOINTS,
    wayPoints,
  }
}

export function addWayPoint(wayPoint: WayPoint) {
  return {
    type: ACTION_TYPES.ADD_WAYPOINT as ACTION_TYPES.ADD_WAYPOINT,
    wayPoint,
  }
}

export function insertWayPointAt(index: number, wayPoint: WayPoint) {
  return {
    type: ACTION_TYPES.INSERT_WAYPOINT as ACTION_TYPES.INSERT_WAYPOINT,
    wayPoint,
    index,
  }
}

export function removeWayPointAt(index: number) {
  return {
    type: ACTION_TYPES.REMOVE_WAYPOINT as ACTION_TYPES.REMOVE_WAYPOINT,
    index,
  }
}

export function renameWayPointAt(index: number, name: string) {
  return {
    type: ACTION_TYPES.RENAME_WAYPOINT as ACTION_TYPES.RENAME_WAYPOINT,
    name,
    index,
  }
}

export function removeLastWayPoint() {
  return {
    type: ACTION_TYPES.REMOVE_LAST_WAYPOINT as ACTION_TYPES.REMOVE_LAST_WAYPOINT,
  }
}

export function updateWayPointAt(index: number, wayPoint: WayPoint) {
  return {
    type: ACTION_TYPES.UPDATE_WAYPOINT as ACTION_TYPES.UPDATE_WAYPOINT,
    wayPoint,
    index,
  }
}

export function rotateWayPoints(rotation: number) {
  return {
    type: ACTION_TYPES.ROTATE_WAYPOINTS as ACTION_TYPES.ROTATE_WAYPOINTS,
    rotation,
  }
}

export function reverseWayPoints(isLoop: boolean) {
  return {
    type: ACTION_TYPES.REVERSE_WAYPOINTS as ACTION_TYPES.REVERSE_WAYPOINTS,
    isLoop,
  }
}

function reducer(
  state: WayPointsState = initialState,
  action: WaypointsAction
): WayPointsState {
  switch (action.type) {
    case ACTION_TYPES.SET_WAYPOINTS:
      return action.wayPoints
    case ACTION_TYPES.ADD_WAYPOINT:
      return [...state, action.wayPoint]
    case ACTION_TYPES.REMOVE_LAST_WAYPOINT:
      return state.slice(0, -1)
    case ACTION_TYPES.REMOVE_WAYPOINT:
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)]
    case ACTION_TYPES.RENAME_WAYPOINT:
      return state.map((wp, index) => {
        if (index === action.index) {
          const { name, ...rest } = wp
          return { ...rest, ...(action.name && { name: action.name }) }
        }
        return wp
      })
    case ACTION_TYPES.UPDATE_WAYPOINT:
      return [
        ...state.slice(0, action.index),
        { ...state[action.index], ...action.wayPoint },
        ...state.slice(action.index + 1),
      ]
    case ACTION_TYPES.INSERT_WAYPOINT:
      return [
        ...state.slice(0, action.index),
        action.wayPoint,
        ...state.slice(action.index),
      ]
    case ACTION_TYPES.REVERSE_WAYPOINTS:
      if (!Array.isArray(state) || state.length < 2) {
        return state
      }
      return action.isLoop
        ? [state[0], ...state.slice(1).reverse()]
        : state.slice().reverse()
    case ACTION_TYPES.ROTATE_WAYPOINTS:
      const rotation = action.rotation % state.length
      return [...state.slice(rotation), ...state.slice(0, rotation)]
    default:
      return state
  }
}

export default reducer

// -- TYPES

type WaypointsAction =
  | ReturnType<typeof setWayPoints>
  | ReturnType<typeof addWayPoint>
  | ReturnType<typeof insertWayPointAt>
  | ReturnType<typeof removeWayPointAt>
  | ReturnType<typeof renameWayPointAt>
  | ReturnType<typeof removeLastWayPoint>
  | ReturnType<typeof updateWayPointAt>
  | ReturnType<typeof rotateWayPoints>
  | ReturnType<typeof reverseWayPoints>
