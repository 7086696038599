import { ImagePoint } from '../../../@digimap/types'

enum ACTION_TYPES {
  SET_IMAGE_POINT = 'SET_IMAGE_POINT',
  MOVE_IMAGE_POINT = 'MOVE_IMAGE_POINT',
}

type ImagePointsState = ImagePoint[]

export const initialState: ImagePointsState = []

export interface ImagePointDiff {
  topDiff: number
  leftDiff: number
}

interface ImagePointAddAction {
  type: ACTION_TYPES.SET_IMAGE_POINT
  point: ImagePoint
  index?: number
}

interface ImagePointMoveAction {
  type: ACTION_TYPES.MOVE_IMAGE_POINT
  diff: ImagePointDiff
  index: number
}

type ImagePointsAction = ImagePointAddAction | ImagePointMoveAction

// --- ACTIONS

export function addPointOnImage({
  top,
  left,
}: ImagePoint): ImagePointAddAction {
  return {
    type: ACTION_TYPES.SET_IMAGE_POINT,
    point: {
      top,
      left,
    },
  }
}

export function movePointOnImage(
  { topDiff, leftDiff }: ImagePointDiff,
  index: number
): ImagePointMoveAction {
  return {
    type: ACTION_TYPES.MOVE_IMAGE_POINT,
    diff: {
      topDiff,
      leftDiff,
    },
    index,
  }
}

// --- REDUCER

export const setImagePoint = (
  points: (ImagePoint | null)[],
  { left, top }: ImagePoint,
  index?: number
) => {
  if (typeof index !== 'number') {
    index = points.length
  }
  const gap = index < points.length ? 0 : index - points.length
  const fillArray = Array(gap).fill(null)
  return [
    ...points.slice(0, index),
    ...fillArray,
    {
      ...points[index],
      ...{
        left,
        top,
      },
    },
    ...points.slice(index + 1),
  ]
}

const moveImagePoint = (
  points: ImagePoint[],
  { leftDiff = 0, topDiff = 0 }: ImagePointDiff,
  index: number
) => {
  if (index < points.length) {
    const point = points[index]
    return [
      ...points.slice(0, index),
      {
        left: point.left + leftDiff,
        top: point.top + topDiff,
      },
      ...points.slice(index + 1),
    ]
  }
  return points
}

function reducer(
  state: ImagePointsState = initialState,
  action: ImagePointsAction
): ImagePointsState {
  switch (action.type) {
    case ACTION_TYPES.MOVE_IMAGE_POINT:
      return moveImagePoint(state, action.diff, action.index)
    case ACTION_TYPES.SET_IMAGE_POINT:
      return setImagePoint(state, action.point, action.index)

    default:
      return state
  }
}

export default reducer
