import Button from '@mui/material/Button'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { BalladType } from '../../@digimap/types'
import { InvalidField } from '../../@digimap/types/api'
import { getErrorMessage } from '../../lib/errors'
import { uploadAndPersistGpxFile } from '../../services'

interface UploadGpxFormProps {
  onSuccess: () => void
}

export default function UploadGpxForm({ onSuccess }: UploadGpxFormProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [type, setType] = useState<BalladType>(BalladType.RIDE)
  const onTypeChange = (event: React.MouseEvent, newType: BalladType) => {
    setType(newType)
  }
  const [formErrors, setFormErrors] = useState(new Map())

  // @todo duplicated code, create hook
  function handleFormErrors(e: any) {
    if (typeof e === 'object' && e !== null && Array.isArray(e.invalidFields)) {
      const invalidFields: InvalidField[] = e.invalidFields
      const formErrors = invalidFields.reduce((acc, { field, reason }) => {
        const value = field || 'global'
        acc.set(value, [...(acc.get(value) || []), reason])
        return acc
      }, new Map() as Map<string, string[]>)
      const globalFormErrors = formErrors.get('global')
      if (globalFormErrors) {
        const globalErrors = globalFormErrors.join('\n')
        enqueueSnackbar(globalErrors, { variant: 'error' })
      }
      setFormErrors(formErrors)
    }
  }

  const submitForm = async (e: React.MouseEvent) => {
    e.preventDefault()

    const formData = new FormData(document.forms[0])

    const intermediateFormDataKeys = ['balladSlug1', 'balladSlug2']
    intermediateFormDataKeys.forEach((formDataKey) => {
      const balladSlug = formData.get(formDataKey) as string
      if (balladSlug && balladSlug.trim() !== '') {
        formData.append('ballads[]', balladSlug)
      }
      formData.delete(formDataKey)
    })

    formData.append('type', type)

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    try {
      await uploadAndPersistGpxFile(formData)
      enqueueSnackbar('Gpx file successfully uploaded', { variant: 'success' })
      onSuccess()
    } catch (e) {
      handleFormErrors(e)
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
    }
  }
  return (
    <form>
      <Typography variant='h6' gutterBottom>
        Upload a Gpx File
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={onTypeChange}
            size='small'>
            <ToggleButton value={BalladType.RIDE}>
              <Icon>directions_bike</Icon>
            </ToggleButton>
            <ToggleButton value={BalladType.HIKE}>
              <Icon>directions_walking</Icon>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={formErrors.has('name')}
            name='name'
            label='Name'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={formErrors.has('date')}
            type='date'
            name='date'
            label='date'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='balladSlug1'
            error={formErrors.has('balladSlug1')}
            label='Ballad Slug #1'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='balladSlug2'
            error={formErrors.has('balladSlug2')}
            label='Ballad Slug #2'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Upload gpx file:</FormLabel>
          <input type='file' name='gpx' accept='.gpx' />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={submitForm}>
            Upload
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
