import { Ballad } from '../types'
import { hasBounds } from './overlay'

export enum BALLAD_STATUS {
  WIP = 'wip',
  OVERLAYED = 'with-bounds',
  ROUTED = 'with-track',
}

export const balladStatuses = [
  {
    status: undefined,
    icon: 'all_inclusive',
    label: 'All',
  },
  {
    status: BALLAD_STATUS.ROUTED,
    icon: 'directions',
    label: 'Routed',
  },
  {
    status: BALLAD_STATUS.OVERLAYED,
    icon: 'location_on',
    label: 'Positioned',
  },
  {
    status: BALLAD_STATUS.WIP,
    icon: 'location_off',
    label: 'Not Positioned Yet',
  },
]

export const getStatus = (ballad: Ballad): BALLAD_STATUS => {
  if (isRouted(ballad)) {
    return BALLAD_STATUS.ROUTED
  } else if (isFullyOverlayed(ballad)) {
    return BALLAD_STATUS.OVERLAYED
  }
  return BALLAD_STATUS.WIP
}

export const isRouted = (ballad: Ballad): boolean => Boolean(ballad.track)

export const isFullyOverlayed = (ballad: Ballad): boolean => {
  if (!Array.isArray(ballad.images)) {
    return false
  }
  return ballad.images.every(hasBounds)
}

// @todo store ballad by ids
export function getBalladById(
  id: string,
  ballads: Ballad[]
): Ballad | undefined {
  return ballads.find((b) => b._id === id)
}

export function getBalladByIdOrSlug(
  idOrSlug: string,
  ballads: Ballad[]
): Ballad | undefined {
  return ballads.find((b) => b._id === idOrSlug || b.slug === idOrSlug)
}
