/* global google */
import React, { useContext, useEffect, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { DEFAULT_MAP_OPTIONS, DEFAULT_MAP_STYLE } from '../common/constants'
import { MapBoxProps } from '../common/types'
import { GoogleMapContext } from '../contexts/GoogleMapContext'
import { useGoogleAPI, useGoogleListener, useMemoizedOptions } from '../hooks'

const MapBox = ({
  apiKey = '',
  language,
  region,
  className,
  style = DEFAULT_MAP_STYLE,
  opts = DEFAULT_MAP_OPTIONS,
  useDrawing = false,
  useGeometry = false,
  usePlaces = false,
  useVisualization = false,
  LoadedComponent = null,
  LoadingComponent = <p>Loading...</p>,
  onBoundsChanged,
  onCenterChanged,
  onClick,
  onDoubleClick,
  onDrag,
  onDragEnd,
  onDragStart,
  onHeadingChanged,
  onIdle,
  onMapTypeIdChanged,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onProjectionChanged,
  onRightClick,
  onTilesLoaded,
  onTiltChanged,
  onZoomChanged,
}: MapBoxProps) => {
  // Get access to the Google Map context
  const { dispatch } = useContext(GoogleMapContext)
  const [prevOpts, setPrevOpts] = useState('')
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined)

  // Generate a random id for the DOM node where Google Map will be inserted
  const [mapItemId] = useState(`map-${uuidv1()}`)

  const loaded = useGoogleAPI({
    apiKey: apiKey,
    useDrawing,
    useGeometry,
    usePlaces,
    useVisualization,
    language,
    region,
  })

  // Load Google Map
  useEffect(() => {
    if (!loaded) return

    const map = new google.maps.Map(
      document.getElementById(mapItemId) as HTMLElement,
      opts
    )
    setMap(map)
    setPrevOpts(JSON.stringify(opts))

    dispatch({ type: 'init_map', map })
    return () => dispatch({ type: 'reset' })
    // eslint-disable-next-line
  }, [dispatch, loaded, mapItemId]) // should only change when loaded changes

  // Register event listeners
  useGoogleListener(map, [
    { name: 'bounds_changed', handler: onBoundsChanged },
    { name: 'center_changed', handler: onCenterChanged },
    { name: 'click', handler: onClick },
    { name: 'dblclick', handler: onDoubleClick },
    { name: 'drag', handler: onDrag },
    { name: 'dragend', handler: onDragEnd },
    { name: 'dragstart', handler: onDragStart },
    { name: 'heading_changed', handler: onHeadingChanged },
    { name: 'idle', handler: onIdle },
    { name: 'maptypeid_changed', handler: onMapTypeIdChanged },
    { name: 'mousemove', handler: onMouseMove },
    { name: 'mouseout', handler: onMouseOut },
    { name: 'mouseover', handler: onMouseOver },
    { name: 'projection_changed', handler: onProjectionChanged },
    { name: 'rightclick', handler: onRightClick },
    { name: 'tilesloaded', handler: onTilesLoaded },
    { name: 'tilt_changed', handler: onTiltChanged },
    { name: 'zoom_changed', handler: onZoomChanged },
  ])

  // Modify the google.maps.Map object when component props change
  useMemoizedOptions(map, opts, prevOpts, setPrevOpts)

  // Render <MapBox>
  return (
    <>
      {loaded ? LoadedComponent : LoadingComponent}
      {typeof document !== 'undefined' ? (
        <div id={mapItemId} style={style} className={className} />
      ) : null}
    </>
  )
}

MapBox.displayName = 'MapBox'

export default MapBox
