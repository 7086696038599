import GPX from '../../../lib/GPX/Gpx'
import { GeoFile } from '../../../types/geoFiles'

function readFileAsync(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = reject

    reader.readAsText(file)
  })
}

export default async function readGeoFiles(
  files: FileList
): Promise<GeoFile[]> {
  const geoFiles: GeoFile[] = []
  for (let file of Array.from(files)) {
    const content = await readFileAsync(file)
    if (file.type === 'application/json' || /\.json$/.test(file.name)) {
      try {
        const geoJson: Object = JSON.parse(content)
        geoFiles.push({
          filename: file.name,
          content: geoJson,
          visible: true,
        })
      } catch (e) {
        console.error('Error while parsing/handling geoJson data', e)
      }
    } else if (file.type === 'text/xml' || /\.gpx$/.test(file.name)) {
      try {
        const gpx = new GPX().parseFromString(content)
        geoFiles.push({
          filename: file.name,
          content: gpx,
          contentStr: content,
          visible: true,
        })
      } catch (e) {
        console.error('Error while parsing/handling gpx data', e)
      }
    }
  }
  return geoFiles
}
