/* global google */
import { useEffect, useContext, useState } from 'react'
import { GoogleMapContext } from '../contexts/GoogleMapContext'

interface DirectionsRendererProps {
  directionsResult: google.maps.DirectionsResult
  draggable: boolean
}
const DirectionsRenderer = ({
  directionsResult,
  draggable = false,
}: DirectionsRendererProps) => {
  const { state } = useContext(GoogleMapContext)
  const [directionsRenderer, setDirectionsRenderer] = useState<
    google.maps.DirectionsRenderer | undefined
  >(undefined)
  useEffect(() => {
    if (state.map === undefined) return
    const directionsRenderer = new google.maps.DirectionsRenderer({
      map: state.map,
      draggable,
    })
    setDirectionsRenderer(directionsRenderer)
    return () => {
      directionsRenderer.setMap(null)
    }
  }, [draggable, state.map])

  useEffect(() => {
    if (directionsRenderer && directionsResult) {
      directionsRenderer.setDirections(directionsResult)
    }
  }, [directionsRenderer, directionsResult])

  return null
}

export default DirectionsRenderer
