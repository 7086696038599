import L from 'leaflet'
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'
import { useContext, useEffect } from 'react'
import { LeafletContext } from '../contexts/LeafletContext'
import './LocateControl.css'

interface LeafletLocateControlProps {
  options: L.Control.LocateOptions
}
export default function LeafletLocateControl({
  options,
}: LeafletLocateControlProps) {
  const { state } = useContext(LeafletContext)

  useEffect(() => {
    if (!state.map) return
    const control = L.control
      .locate({
        ...options,
        icon: 'locate-icon material-icons MuiIcon-fontSizeSmall',
        iconLoading: 'locate-icon-spin material-icons fa-spin',
      })
      .addTo(state.map)
    return () => {
      control.remove()
    }
    // eslint-disable-next-line
  }, [state.map])

  return null
}
