/* global google */
import React, { useContext, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { v1 as uuidv1 } from 'uuid'
import { DEFAULT_SEARCH_BOX_OPTIONS } from '../common/constants'
import { BasicSearchBoxProps } from '../common/types'
import { GoogleMapContext } from '../contexts/GoogleMapContext'
import { useGoogleListener } from '../hooks'

const BasicSearchBox = ({
  id,
  opts = DEFAULT_SEARCH_BOX_OPTIONS,
  onPlacesChanged,
  bindingPosition,
  ...restProps
}: BasicSearchBoxProps) => {
  const { state, dispatch } = useContext(GoogleMapContext)
  const [searchBox, setSearchBox] = useState<
    google.maps.places.SearchBox | undefined
  >(undefined)
  const [searchBoxId] = useState(id ? id : `search-box-${uuidv1()}`)

  // Create google.maps.places.SearchBox
  useEffect(() => {
    if (state.map === undefined) return
    const inputNode = (bindingPosition
      ? document
          .createRange()
          .createContextualFragment(
            ReactDOMServer.renderToString(
              <input id={searchBoxId} {...restProps} />
            )
          ).firstElementChild
      : document.getElementById(searchBoxId)) as HTMLInputElement
    const searchBox = new google.maps.places.SearchBox(inputNode, opts)
    setSearchBox(searchBox)
    dispatch({ type: 'add_object', object: searchBox, id: searchBoxId })
    if (bindingPosition)
      state.map.controls[google.maps.ControlPosition[bindingPosition]].push(
        inputNode
      )
    return () => dispatch({ type: 'remove_object', id: searchBoxId })
    // eslint-disable-next-line
  }, [bindingPosition, dispatch, /*  restProps, */ searchBoxId, state.map])
  // @todo restProps is different each time

  // Register google map event listeners
  useGoogleListener(searchBox, [
    { name: 'places_changed', handler: onPlacesChanged },
  ])

  // Modify the google.maps.places.SearchBox object when component props change
  useEffect(() => {
    if (searchBox === undefined || opts.bounds === undefined) return
    searchBox.setBounds(opts.bounds)
  }, [opts.bounds, searchBox])

  return bindingPosition ? null : <input id={searchBoxId} {...restProps} />
}

BasicSearchBox.displayName = 'SearchBox'

export default BasicSearchBox
