import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import React from 'react'
import useOverpass from '../../hooks/useOverpass'
import CustomControl from '../leaflet-react/components/CustomControl'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'
import LeafletPOIs from './POIs'

const useStyles = makeStyles({
  root: {
    padding: 5,
    borderRadius: 0,
  },
})

export default function LeafletPOIsControl() {
  const classes = useStyles()
  const { fetchPOIs, POIs, showPOIs, setShowPOIs, loadingPOIs } =
    useOverpass(LeafletContext)

  return (
    <>
      <CustomControl position='topright'>
        <IconButton
          classes={classes}
          disableRipple={true}
          disableFocusRipple={true}
          aria-label='searchPOIs'
          disabled={loadingPOIs}
          onClick={fetchPOIs}>
          <Icon fontSize='small'>{loadingPOIs ? 'autorenew' : 'beenhere'}</Icon>
        </IconButton>
        <Divider />
        <IconButton
          classes={classes}
          disableRipple={true}
          disableFocusRipple={true}
          aria-label='showPOIs'
          disabled={POIs.length === 0}
          onClick={() => {
            setShowPOIs((value) => !value)
          }}>
          <Icon fontSize='small'>{showPOIs ? 'layers_clear' : 'layers'}</Icon>
        </IconButton>
      </CustomControl>
      {showPOIs && <LeafletPOIs POIs={POIs} />}
    </>
  )
}
