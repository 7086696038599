/* global google */
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import {
  CommunesDatabase,
  createCommunesFeatureCollection,
} from '../../@digimap/types'
import { fetchNearestCommunes } from '../../services'
import { GoogleMapContext } from '../googlemaps-react/contexts/GoogleMapContext'
import { getErrorMessage } from '../../lib/errors'

interface FranceCommunesLayerProps {
  visible: boolean
}

export default function FranceCommunesLayer({
  visible,
}: FranceCommunesLayerProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useContext(GoogleMapContext)
  const [communes, setCommunes] = useState<CommunesDatabase[] | null>(null)

  useEffect(() => {
    if (!state.map) return
    if (!visible) return
    if (communes !== null) return
    async function fetchCommunesData() {
      if (state.map) {
        const center = state.map.getCenter()
        try {
          const communes = await fetchNearestCommunes({
            lat: center.lat(),
            lng: center.lng(),
          })
          setCommunes(communes.nearestCommunes)
        } catch (e) {
          enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
        }
      }
    }
    fetchCommunesData()
  }, [communes, enqueueSnackbar, state.map, visible])

  useEffect(() => {
    if (!state.map) return
    if (communes === null) return
    const features = state.map.data.addGeoJson(
      createCommunesFeatureCollection(communes)
    )

    const infowindow = new google.maps.InfoWindow({})
    const listenerHandle = state.map.data.addListener(
      'click',
      function (event) {
        if (
          event.feature.getProperty('code') === undefined &&
          event.feature.getProperty('nom') === undefined
        ) {
          return
        }
        const myHTML = `${event.feature.getProperty(
          'code'
        )} ${event.feature.getProperty('nom')}`
        infowindow.setContent(
          '<div style="width:150px; text-align: center;">' + myHTML + '</div>'
        )

        infowindow.setPosition(event.latLng)
        // infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) })
        infowindow.open(state.map)
      }
    )
    return () => {
      listenerHandle.remove()
      infowindow.close()
      features.forEach((f) => {
        if (f && state.map !== undefined) {
          state.map.data.remove(f)
        }
      })
    }
  }, [communes, state.map])

  useEffect(() => {
    if (!state.map) return
    if (communes === null) return
    state.map.data.setMap(visible ? state.map : null)
  }, [communes, state.map, visible])

  return null
}
