import React, { useReducer } from 'react'
import gpxReducer, { GpxAction, GpxState, initialState } from './gpxReducer'

const GpxContext = React.createContext<
  GpxState & {
    gpxDispatch: React.Dispatch<GpxAction>
  }
>({
  ...initialState,
  gpxDispatch: () => {},
})

function GpxProvider(props: { children: React.ReactNode }) {
  const [gpxState, gpxDispatch] = useReducer(gpxReducer, initialState)

  return (
    <GpxContext.Provider value={{ ...gpxState, gpxDispatch }}>
      {props.children}
    </GpxContext.Provider>
  )
}

GpxProvider.displayName = 'GpxProvider'
GpxProvider.whyDidYouRender = {
  logOnDifferentValues: true,
}

export { GpxContext, GpxProvider }
