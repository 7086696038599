import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/lab/ToggleButton'
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import {
  BalladType,
  BaseBallad,
  isExistingBallad,
  NewBaseBallad,
} from '../../@digimap/types'
import { InvalidField } from '../../@digimap/types/api'
import { encodePath } from '../../lib/polylineEncoding'
import simplifyPath from '../../lib/simplifyPath'
import { GpxFile } from '../../types/geoFiles'
import readGeoFiles from '../GeoFilesPage/lib/readGeoFiles'
import { getErrorMessage } from '../../lib/errors'

interface BaseBalladFormProps {
  ballad: BaseBallad | NewBaseBallad
  onSubmit: (formData: FormData, _id?: string) => void
}
export default function BaseBalladForm({
  ballad,
  onSubmit,
}: BaseBalladFormProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [type, setType] = useState(ballad.type)
  const onTypeChange = (event: React.MouseEvent, newType: BalladType) => {
    setType(newType)
  }

  const [uploadedFilesType, setUploadedFilesType] = useState<
    'gpx' | 'images' | undefined
  >()

  const [encodedPath, setEncodedPath] = useState<string | undefined>()

  const [formErrors, setFormErrors] = useState(new Map())

  function handleFormErrors(e: any) {
    if (typeof e === 'object' && e !== null && Array.isArray(e.invalidFields)) {
      const invalidFields: InvalidField[] = e.invalidFields
      const formErrors = invalidFields.reduce((acc, { field, reason }) => {
        const value = field || 'global'
        acc.set(value, [...(acc.get(value) || []), reason])
        return acc
      }, new Map() as Map<string, string[]>)
      const globalFormErrors = formErrors.get('global')
      if (globalFormErrors) {
        const globalErrors = globalFormErrors.join('\n')
        enqueueSnackbar(globalErrors, { variant: 'error' })
      }
      setFormErrors(formErrors)
    }
  }

  const handleGpxFile = async (files: FileList | null) => {
    if (files) {
      const geoFiles = await readGeoFiles(files)
      if (geoFiles && geoFiles.length) {
        const { content: gpxObj } = geoFiles[0] as GpxFile
        const paths = gpxObj.getTracksPaths()
        if (paths.length === 0) {
          throw Error('Gpx files should have at least one track')
        }
        if (paths.length > 1) {
          throw Error('Gpx files should have only one track')
        }
        setUploadedFilesType('gpx')
        setEncodedPath(encodePath(simplifyPath(paths[0])))
      }
    } else {
      setUploadedFilesType((uploadedFileType) => {
        return uploadedFilesType === 'gpx' ? undefined : uploadedFileType
      })
      setEncodedPath(undefined)
    }
  }

  const submitForm = async (e: React.MouseEvent) => {
    e.preventDefault()

    const formData = new FormData(document.forms[0])
    if (encodedPath) {
      formData.append('encodedPath', encodedPath)
      formData.delete('gpx')
    }

    formData.append('type', type)
    try {
      await onSubmit(
        formData,
        isExistingBallad(ballad) ? ballad._id : undefined
      )
    } catch (e) {
      handleFormErrors(e)
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
    }
  }

  return (
    <form>
      <Typography variant='h6' gutterBottom>
        {isExistingBallad(ballad) ? 'Update Ballad' : 'Create a ballad'}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={onTypeChange}
            size='small'>
            <ToggleButton value={BalladType.RIDE}>
              <Icon>directions_bike</Icon>
            </ToggleButton>
            <ToggleButton value={BalladType.HIKE}>
              <Icon>directions_walking</Icon>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='loop'
                defaultChecked={ballad.loop}
              />
            }
            label='Loop'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={formErrors.has('name')}
            name='name'
            label='Name'
            defaultValue={ballad.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={formErrors.has('slug')}
            name='slug'
            label='Slug'
            defaultValue={ballad.slug}
            fullWidth
            helperText='PF_V_IDF_ / PF_V_FR_ / IGN_V_IDF_ / IGN_V_FR_ / TG_PP_ / TG_EP_ / TG_QHP_ / TG_PJ_ / TG_HDS_/ ..'
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name='distance'
            label='Distance'
            error={formErrors.has('distance')}
            defaultValue={ballad.distance}
            fullWidth
            helperText='In meters'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='duration'
            label='Duration'
            error={formErrors.has('duration')}
            defaultValue={ballad.duration || ''}
            fullWidth
            helperText='In minutes'
          />
        </Grid>

        {!isExistingBallad(ballad) && (
          <Grid item xs={12}>
            <FormLabel error={formErrors.has('images')}>
              Upload overlay images:
            </FormLabel>
            <input
              type='file'
              name='images'
              accept='image/jpeg'
              multiple
              onChange={(e) => {
                if (e.target.files) {
                  console.log('files', e.target.files)
                  setUploadedFilesType('images')
                } else {
                }
              }}
              disabled={uploadedFilesType === 'gpx'}
            />
          </Grid>
        )}
        {!isExistingBallad(ballad) && (
          <Grid item xs={12}>
            <FormLabel error={formErrors.has('gpx')}>
              Upload gpx file:
            </FormLabel>
            <input
              type='file'
              name='gpx'
              accept='.gpx'
              onChange={(e) => {
                handleGpxFile(e.target.files)
              }}
              disabled={uploadedFilesType === 'images'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={submitForm}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
