import L from 'leaflet'
import { useContext, useEffect, useRef } from 'react'
import {
  getIconUrl,
  getInfoTable,
  isOSMNodeElement,
  OSMElement,
} from '../../lib/overpass/helpers/osmElement'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface LeafletPOIsProps {
  POIs: OSMElement[]
}

export default function LeafletPOIs({ POIs }: LeafletPOIsProps) {
  const { state } = useContext(LeafletContext)
  const featureGroupRef = useRef<L.FeatureGroup | null>(null)

  useEffect(() => {
    if (state.map === undefined) return

    featureGroupRef.current = L.featureGroup().addTo(state.map)
    return () => {
      if (state.map) {
        if (featureGroupRef.current !== null) {
          featureGroupRef.current.clearLayers()
          state.map.removeLayer(featureGroupRef.current)
        }
      }
    }
  }, [state.map])

  useEffect(() => {
    if (featureGroupRef.current === null) return

    POIs.forEach((element) => {
      const latLng = isOSMNodeElement(element)
        ? L.latLng(element.lat, element.lon)
        : L.latLng(element.center.lat, element.center.lon)

      const marker = L.marker(latLng, {
        icon: L.icon({
          iconUrl: getIconUrl(element),
          iconSize: [32, 37],
          iconAnchor: [16, 35], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -37], // point from which the popup should open relative to the iconAnchor
        }),
      })

      marker.bindPopup(L.popup().setContent(getInfoTable(element)))

      if (featureGroupRef.current !== null) {
        featureGroupRef.current.addLayer(marker)
      }
    })
  }, [POIs])

  return null
}
