import { Ballad, ClientUser, RegisteredUser } from '../types'

/**
 * Is user an admin
 * @category user
 */
export function isAdmin(user: ClientUser | RegisteredUser) {
  return user.role === 'admin'
}

/**
 * Is user a client
 * @category user
 */
export function isClient(user: ClientUser | RegisteredUser) {
  return user.role === 'client'
}

export function isBalladCompleted(
  ballad: Ballad,
  completedIds: string[]
): boolean {
  return completedIds.includes(ballad._id)
}
