import React, { useReducer } from 'react'

interface LeafletState {
  map: L.Map | undefined
}

type LeafletAction = LeafletInitMapAction | LeafletResetAction

interface LeafletInitMapAction {
  type: 'init_map'
  map: L.Map
}
interface LeafletResetAction {
  type: 'reset'
}

const initialState = (): LeafletState => ({
  map: undefined,
})

const LeafletContext = React.createContext<{
  state: LeafletState
  dispatch: React.Dispatch<LeafletAction>
}>({
  state: initialState(),
  dispatch: () => {},
})

const reducer = (state: LeafletState, action: LeafletAction) => {
  switch (action.type) {
    case 'reset':
      return initialState()

    case 'init_map':
      if (state.map !== undefined)
        throw new Error('There can only be one map instance in a context')

      return { ...state, map: action.map }

    default:
      return state
  }
}

interface LeafletProviderProps {
  children: React.ReactNode
}

const LeafletProvider = ({ children }: LeafletProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState())

  return (
    <LeafletContext.Provider value={{ state, dispatch }}>
      {children}
    </LeafletContext.Provider>
  )
}

const LeafletConsumer = LeafletContext.Consumer

export { LeafletContext, LeafletProvider, LeafletConsumer }
