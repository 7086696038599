import Cookies from 'js-cookie'
import {
  RequestAccessResponse,
  RequestAccessTokenRequestBody,
} from '../../../@digimap/types/api'
import { ACCESS_TOKEN } from '../../../constants/cookies'
import { AUTH_ENDPOINT } from '../../../constants/endpoints'

export default async function requestAccessToken({
  email,
  password,
}: RequestAccessTokenRequestBody): Promise<RequestAccessResponse> {
  const res = await fetch(`${AUTH_ENDPOINT}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  })

  const responseData = await res.json()

  if (res.status !== 200 || responseData.error) {
    return Promise.reject(responseData.error)
  }

  if (!responseData.access_token) {
    return Promise.reject({ message: 'Missing access_token field' })
  }

  Cookies.set(ACCESS_TOKEN, responseData.access_token, { expires: 7 })
  return Promise.resolve(responseData)
}
