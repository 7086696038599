import { useContext, useEffect } from 'react'
import { Ballad } from '../../../@digimap/types'
import { LeafletContext } from '../../../components/leaflet-react/contexts/LeafletContext'
import getUnionBounds from '../../../lib/getUnionBounds'

// if ballad has a track, fitBounds => track
// if ballad has positioned overlays, fitBounds => union bounds
export default function useMapFitBalladBounds(ballad: Ballad) {
  const { state } = useContext(LeafletContext)

  useEffect(() => {
    if (!state.map) return

    const unionBounds = getUnionBounds(ballad)
    if (unionBounds) {
      state.map.fitBounds(unionBounds)
    }
  }, [ballad, state.map])

  return
}
