import { useEffect, useContext, useRef } from 'react'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'
import L from 'leaflet'
import { Feature } from 'geojson'
interface GeoJsonProps {
  content: Feature
  centerAndZoom: boolean
  visible: boolean
}
export default function GeoJson({
  content,
  visible,
  centerAndZoom,
}: GeoJsonProps) {
  const { state } = useContext(LeafletContext)
  const layerRef = useRef<L.GeoJSON | null>(null)

  useEffect(() => {
    if (!state.map) return
    layerRef.current = L.geoJSON(content).addTo(state.map)
    return () => {
      if (layerRef && layerRef.current) {
        layerRef.current.remove()
        layerRef.current = null
      }
    }
  }, [content, state.map])

  useEffect(() => {
    if (!state.map) return
    if (!layerRef || !layerRef.current) return
    if (!visible) {
      state.map.removeLayer(layerRef.current)
    } else {
      state.map.addLayer(layerRef.current)
    }
  }, [state.map, visible])

  useEffect(() => {
    if (!state.map) return
    if (!layerRef || !layerRef.current) return
    if (!centerAndZoom) {
      state.map.fitBounds(layerRef.current.getBounds())
    } else {
      state.map.fitBounds(layerRef.current.getBounds())
    }
  }, [centerAndZoom, state.map])
  return null
}
