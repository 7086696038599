import L from 'leaflet'
import 'leaflet-gpx'
import { useContext, useEffect, useState } from 'react'
import getRandomColor from '../../lib/getRandomColor'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface GpxProps {
  content: string
  centerAndZoom: boolean
  visible: boolean
}
export default function Gpx({ content, visible, centerAndZoom }: GpxProps) {
  const { state } = useContext(LeafletContext)
  const [layerRef, setLayer] = useState<L.GPX | null>(null)

  useEffect(() => {
    const layer = new L.GPX(content, {
      marker_options: {
        startIconUrl: undefined,
        endIconUrl: undefined,
        shadowUrl: undefined,
      },
      polyline_options: {
        color: getRandomColor(),
      },
    })
    setLayer(layer)
    return () => {
      setLayer(null)
    }
  }, [content, state.map])

  useEffect(() => {
    if (!state.map) return
    if (!layerRef) return
    if (!visible) {
      state.map.removeLayer(layerRef)
    } else {
      state.map.addLayer(layerRef)
    }
    return () => {
      layerRef && layerRef.remove()
    }
  }, [state.map, visible, layerRef])

  useEffect(() => {
    if (!state.map) return
    if (!layerRef) return
    if (centerAndZoom) {
      state.map.fitBounds(layerRef.getBounds())
    }
    return () => {}
  }, [centerAndZoom, state.map, layerRef])
  return null
}

Gpx.displayName = 'LeafletGpx'

Gpx.defaultProps = {
  visible: true,
  centerAndZoom: false,
}
