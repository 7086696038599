import { DEFAULT_CENTER } from '../../../constants/leaflet'

function getNextSaturday(date: Date, hours: number) {
  const saturdayIndex = 6
  date.setDate(date.getDate() - date.getDay() + saturdayIndex)
  date.setHours(hours, 0, 0)
  return date.toISOString()
}

export function getCityMapperLink({
  lat,
  lng,
  name,
  type,
  fromLat = DEFAULT_CENTER.lat,
  fromLng = DEFAULT_CENTER.lng,
}: {
  lat: number
  lng: number
  name: string
  type: 'outbound' | 'inbound'
  fromLat?: number
  fromLng?: number
}) {
  const url = 'https://citymapper.com/directions'

  // @todo: add real user information
  const fromCoords = `${fromLat},${fromLng}`
  const toCoords = `${lat},${lng}`
  const fromName = 'home'
  const toName = name

  const searchParams = new URLSearchParams({
    arrival_time: getNextSaturday(new Date(), type === 'inbound' ? 17 : 10),
    startcoord: type === 'inbound' ? toCoords : fromCoords,
    endcoord: type === 'inbound' ? fromCoords : toCoords,
    startName: type === 'inbound' ? toName : fromName,
    endName: type === 'inbound' ? fromName : toName,
  })

  return `${url}?${searchParams.toString()}`
}
