import React from 'react'
import { Overlay } from '../../@digimap/types'
import BalladOverlay from './BalladOverlay'

interface BalladOverlaysProps {
  images: Overlay[]
  opacity: number
}

export default function BalladOverlays(props: BalladOverlaysProps) {
  return (
    <>
      {props.images.map((image) => (
        <BalladOverlay
          image={image}
          key={image.filename}
          opacity={props.opacity}
        />
      ))}
    </>
  )
}
