import {
  ImagePoint,
  LatLngBoundsLiteral,
  LatLngLiteral,
  Overlay,
  Overlayable,
} from '../types'

export const MAX_POINTS = 2

/**
 * Whether the overlay has been positioned
 */
export const isOverlayable = ({
  mapPoints,
  imagePoints,
  width,
  height,
}: Overlayable): boolean => {
  return Boolean(
    imagePoints.length === MAX_POINTS &&
      mapPoints.length === MAX_POINTS &&
      width &&
      height
  )
}

interface CoordPoint {
  x: number
  y: number
}

class CoordPoint {
  constructor({ left, top }: ImagePoint) {
    this.x = left
    this.y = top * -1
  }
}

export const hasBounds = (overlay: Overlay) => Array.isArray(overlay.bounds)

export const getOverlayLatLngBoundsLiteral = ({
  imagePoints,
  mapPoints,
  width,
  height,
}: Overlayable): LatLngBoundsLiteral => {
  // Bounds: SW and NE
  const SWCoordPoint = new CoordPoint({ left: 0, top: height })
  const NECoordPoint = new CoordPoint({ left: width, top: 0 })

  const A = new CoordPoint(imagePoints[0])
  const B = new CoordPoint(imagePoints[1])

  const ALatLngPoint: LatLngLiteral = mapPoints[0]
  const BLatLngPoint: LatLngLiteral = mapPoints[1]

  const deltaSW: CoordPoint = {
    x: B.x - SWCoordPoint.x,
    y: B.y - SWCoordPoint.y,
  }

  const deltaNE: CoordPoint = {
    x: B.x - NECoordPoint.x,
    y: B.y - NECoordPoint.y,
  }

  const deltaBtoA: CoordPoint & LatLngLiteral = {
    x: B.x - A.x,
    y: B.y - A.y,
    lat: BLatLngPoint.lat - ALatLngPoint.lat,
    lng: BLatLngPoint.lng - ALatLngPoint.lng,
  }

  const deltaSWLatLngPoint: LatLngLiteral = {
    lat: (deltaSW.y / deltaBtoA.y) * deltaBtoA.lat,
    lng: (deltaSW.x / deltaBtoA.x) * deltaBtoA.lng,
  }

  const SWLatLngPoint: LatLngLiteral = {
    lat: BLatLngPoint.lat - deltaSWLatLngPoint.lat,
    lng: BLatLngPoint.lng - deltaSWLatLngPoint.lng,
  }

  const deltaNELatLngPoint: LatLngLiteral = {
    lat: (deltaNE.y / deltaBtoA.y) * deltaBtoA.lat,
    lng: (deltaNE.x / deltaBtoA.x) * deltaBtoA.lng,
  }

  const NELatLngPoint: LatLngLiteral = {
    lat: BLatLngPoint.lat - deltaNELatLngPoint.lat,
    lng: BLatLngPoint.lng - deltaNELatLngPoint.lng,
  }

  return [
    [SWLatLngPoint.lat, SWLatLngPoint.lng],
    [NELatLngPoint.lat, NELatLngPoint.lng],
  ]
}
