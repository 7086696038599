import React, { useRef } from 'react'
import { ImagePoint, Overlay } from '../../../../@digimap/types'
import { CDN_ENDPOINT } from '../../../../constants/endpoints'
import { ImagePointDiff } from '../../state/imagePoints'

const imageMarker = {
  width: 32,
  height: 32,
}

const getScrollCoords = () => {
  const scrollContainer = document.getElementById('imageScrollContainer')
  return {
    scrollLeft: scrollContainer ? scrollContainer.scrollLeft : 0,
    scrollTop: scrollContainer ? scrollContainer.scrollTop : 0,
  }
}

interface OverlayImagePointsPositionerProps {
  imagePoints: ImagePoint[]
  maxPoints: number
  overlay: Overlay
  onAdd: (imagePoint: ImagePoint) => void
  onMove: (imagePointDiff: ImagePointDiff, index: number) => void
}
export default function OverlayImagePointsPositioner({
  imagePoints,
  maxPoints,
  overlay,
  onAdd,
  onMove,
}: OverlayImagePointsPositionerProps) {
  let atDragStart = useRef<{
    scrollLeft: number
    scrollTop: number
    clientX: number
    clientY: number
    index: number
  }>()
  return (
    <div
      style={{ position: 'relative', margin: 0, padding: 0 }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        if (atDragStart.current) {
          const scrollCoords = getScrollCoords()
          const scrollDiff = {
            x: atDragStart.current.scrollLeft - scrollCoords.scrollLeft,
            y: atDragStart.current.scrollTop - scrollCoords.scrollTop,
          }
          const xyDiff = {
            x: e.clientX - atDragStart.current.clientX,
            y: e.clientY - atDragStart.current.clientY,
          }
          onMove(
            {
              leftDiff: xyDiff.x - scrollDiff.x,
              topDiff: xyDiff.y - scrollDiff.y,
            },
            atDragStart.current.index
          )
        }
      }}
      onClick={(e: React.MouseEvent) => {
        if (imagePoints.length < maxPoints) {
          const {
            top: offsetTop,
            left: offsetLeft,
          } = e.currentTarget.getBoundingClientRect()
          const left = Math.round(e.clientX - offsetLeft)
          const top = Math.round(e.clientY - offsetTop)
          onAdd({ top, left })
        }
      }}>
      {imagePoints.map((imagePoint, index) => {
        return (
          <img
            key={`image-point-${index + 1}`}
            alt={`Marker #${index + 1}`}
            src={`/marker${index + 1}.png`}
            style={{
              position: 'absolute',
              display: imagePoint ? 'block' : 'none',
              top: imagePoint ? `${imagePoint.top - imageMarker.height}px` : 0,
              left: imagePoint
                ? `${imagePoint.left - imageMarker.width / 2}px`
                : 0,
            }}
            draggable
            onDragStart={(e: React.DragEvent) => {
              atDragStart.current = {
                ...getScrollCoords(),
                clientX: e.clientX,
                clientY: e.clientY,
                index,
              }
            }}
          />
        )
      })}
      <img
        style={{
          cursor: imagePoints.length < maxPoints ? 'crosshair' : 'default',
        }}
        src={`${CDN_ENDPOINT}/${overlay.filename}`}
        alt={overlay.filename}
      />
    </div>
  )
}
