import { LatLngBounds } from 'leaflet'
import LatLngBoundsList from './helpers/LatLngBoundsList'
import { OSMElement } from './helpers/osmElement'
import { buildLargerBounds, removeTrailingSlash } from './helpers/util'

const defaultOptions = {
  minZoom: 15,
  endpoint: 'https://overpass-api.de/api',
  query: `(
    node({{bbox}})[historic];
    node({{bbox}})[tourism];
  );
  out qt;`,
  timeout: 30 * 1000, // Milliseconds
  whitelistTags: [] as string[] | null,
  onSuccess: (response: OSMElement[]) => {},
  onError: (request: Promise<any>) => {},
  onTimeout: (request: Promise<any>) => {},
  onBeforeRequest: () => {},
  onAfterRequest: () => {},
  retryOnTimeout: false,
  onlyNew: true,
  expandBounds: true,
}

type OverpassOptions = typeof defaultOptions

class OverpassService {
  elementsSet: Set<number>
  requestInProgress: boolean
  endpoint: string
  nextRequestBounds: LatLngBounds | null = null
  options: OverpassOptions
  requestedBoundsList: LatLngBoundsList

  constructor(options: Partial<OverpassOptions>) {
    this.options = { ...defaultOptions, ...options }
    this.endpoint = removeTrailingSlash(this.options.endpoint as string)
    this.requestedBoundsList = new LatLngBoundsList()
    this.requestInProgress = false
    this.elementsSet = new Set()
  }

  prepareRequest(
    zoom: number,
    bounds?: google.maps.LatLngBounds | LatLngBounds
  ) {
    if (!bounds) return
    if (zoom < this.options.minZoom) {
      return
    }
    const requestBounds = buildLargerBounds(bounds, this.options.expandBounds)

    if (this.requestInProgress) {
      this.setNextRequestBounds(requestBounds)
    } else {
      this.setNextRequestBounds(null)
      this.sendRequest(requestBounds)
    }
  }

  private sendRequest(bounds: LatLngBounds) {
    if (this.requestedBoundsList.includes(bounds)) {
      this.requestInProgress = false
      return
    }

    this.requestInProgress = true
    this.options.onBeforeRequest()

    const controller = new AbortController()
    const signal = controller.signal
    const request = this.buildRequestPromise(bounds, signal)
    const requestTimeout = setTimeout(() => {
      controller.abort()
      this.onRequestTimeout(request, bounds)
    }, this.options.timeout)

    request
      .then((response: any) => {
        clearTimeout(requestTimeout)
        this.requestedBoundsList.add(bounds)
        this.options.onSuccess(this.handleResponse(response))
      })
      .catch(() => {
        clearTimeout(requestTimeout)
        this.options.onError(request)
      })
      .then(() => this.onRequestCompleteCallback())
  }

  clear() {
    this.requestedBoundsList.clear()
    this.elementsSet.clear()
    this.requestInProgress = false
  }

  private onRequestTimeout(request: any, bounds: LatLngBounds) {
    this.options.onTimeout(request)

    if (this.options.retryOnTimeout) {
      this.sendRequest(bounds)
    } else {
      this.onRequestCompleteCallback()
    }
  }

  private onRequestCompleteCallback() {
    this.options.onAfterRequest()
    const requestBounds = this.getNextRequestBounds()
    if (requestBounds !== null) {
      this.setNextRequestBounds(null)
      this.sendRequest(requestBounds)
    } else {
      this.requestInProgress = false
    }
  }

  private buildRequestPromise(
    bounds: LatLngBounds,
    signal: AbortSignal
  ): Promise<any> {
    const query = this.buildOverpassQueryFromQueryAndBounds(
      this.options.query,
      bounds
    )
    const url = `${this.endpoint}/interpreter?data=${query}`

    return fetch(url, { method: 'GET', signal }).then((response) =>
      response.json()
    )
  }

  private buildOverpassQueryFromQueryAndBounds(
    query: string,
    bounds: LatLngBounds
  ): string {
    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()
    const coordinates = [sw.lat, sw.lng, ne.lat, ne.lng].join(',')
    return query
      .replace(/\s*\/\/.*/g, '')
      .replace(/\s*\/\*[\s\S]*\*\/\s*/g, '')
      .replace(/^\s*(\[.*\];)?\s*/g, '[out:json];')
      .replace(/(\{\{bbox\}\})/g, coordinates)
  }

  private setNextRequestBounds(nextRequestBounds: LatLngBounds | null) {
    this.nextRequestBounds = nextRequestBounds
  }

  private getNextRequestBounds() {
    return this.nextRequestBounds
  }

  private handleResponse(data: { elements: OSMElement[] }): OSMElement[] {
    return data.elements.reduce((acc: OSMElement[], element) => {
      // @ts-ignore
      if (typeof element.lat === 'undefined' && !element.center) {
        return acc
      }

      if (this.options.onlyNew === true && this.elementsSet.has(element.id)) {
        return acc
      }

      this.elementsSet.add(element.id)

      const { whitelistTags } = this.options
      const newTags = Array.isArray(whitelistTags)
        ? Object.keys(element.tags).reduce((tags, key) => {
            if (whitelistTags.includes(key)) {
              return { ...tags, [key]: element.tags[key] }
            }
            return tags
          }, {})
        : element.tags
      return [...acc, { ...element, tags: newTags }]
    }, [])
  }
}

export default OverpassService
