import { ControlPosition } from 'leaflet'
import React, { ChangeEvent, useCallback } from 'react'
import CustomControl from '../leaflet-react/components/CustomControl'

interface SelectControlProps {
  onChange: (value: string) => void
  position: ControlPosition
  options: { label: string; value: string }[]
  value: string
}

export default function SelectControl({
  onChange,
  position,
  options,
  value,
}: SelectControlProps) {
  const onChangeMemo = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      return onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <CustomControl position={position}>
      <select
        style={{ width: 120, padding: 4 }}
        value={value || options[0].value}
        onChange={onChangeMemo}>
        {options.map(({ label, value }) => {
          return (
            <option key={label} value={value}>
              {label}
            </option>
          )
        })}
      </select>
    </CustomControl>
  )
}

SelectControl.displayName = 'SelectControl'

SelectControl.defaultProps = {
  position: 'topright',
}
