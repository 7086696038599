import { Feature, FeatureCollection, Point } from 'geojson'

type PublicTransportStationDatabaseProperties = {
  _id: string
  name: string
  line: string
}
export type PublicTransportStationDatabase = {
  geometry: Point
} & PublicTransportStationDatabaseProperties

export type PublicTransportStationFeatureProperties = PublicTransportStationDatabaseProperties & {
  dist?: number
}

type WithStartEndExtraProperties = {
  main: boolean
  start: boolean
  end: boolean
}

export type PublicTransportStationWithStartEndFeatureProperties = PublicTransportStationDatabaseProperties &
  WithStartEndExtraProperties

export type PublicTransportStationFeature = Feature<
  Point,
  PublicTransportStationFeatureProperties
>

export type PublicTransportStationWithStartEndFeature = Feature<
  Point,
  PublicTransportStationWithStartEndFeatureProperties
>

export type PublicTransportStationFeatureCollection = FeatureCollection<
  Point,
  PublicTransportStationFeatureProperties
>

export type PublicTransportStationWithStartEndFeatureCollection = FeatureCollection<
  Point,
  PublicTransportStationWithStartEndFeatureProperties
>

export type PublicTransportStationsGroupedByLines = { [line: string]: string[] }

export function createPublicTransportFeatureCollection(
  publicTransportStations: PublicTransportStationDatabase[]
): PublicTransportStationFeatureCollection {
  return {
    type: 'FeatureCollection',
    features: publicTransportStations.map((publicTransportStation) => {
      const { geometry, ...properties } = publicTransportStation
      return {
        type: 'Feature',
        geometry,
        properties,
      }
    }),
  }
}

export function addStartEndToPublicTransportFeature(
  feature: PublicTransportStationFeature,
  { main, start, end }: WithStartEndExtraProperties
): PublicTransportStationWithStartEndFeature {
  return {
    ...feature,
    properties: {
      ...feature.properties,
      main,
      start,
      end,
    },
  }
}
