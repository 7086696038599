import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { getErrorMessage } from '../../lib/errors'
import { fetchAllGpxFiles } from '../../services'
import { GpxContext } from './GpxProvider'
import { gpxActions } from './gpxReducer'

export default function GpxFetcher(props: { children: any }) {
  const { enqueueSnackbar } = useSnackbar()
  const { gpxDispatch } = useContext(GpxContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAndSetGpxFiles = async () => {
      try {
        const { gpx } = await fetchAllGpxFiles()
        gpxDispatch(gpxActions.reset({ gpx }))
      } catch (e) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
      } finally {
        setLoading(false)
      }
    }
    fetchAndSetGpxFiles()
  }, [enqueueSnackbar, gpxDispatch])

  if (loading) return null
  return props.children
}
