import Typography from '@mui/material/Typography'
import L from 'leaflet'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { createFeatureCollection } from '../../@digimap/types/geojsonUtils'
import {
  addStartEndToPublicTransportFeature,
  PublicTransportStationWithStartEndFeature,
  PublicTransportStationWithStartEndFeatureCollection,
} from '../../@digimap/types/publicTransport'
import { getErrorMessage } from '../../lib/errors'
import getPublicTransportLineColor from '../../lib/getPublicTransportLineColor'
import { fetchPublicTransportStations } from '../../services'
import CityMapperLink from '../CityMapper/CityMapperLink'
import { LeafletContext } from '../leaflet-react/contexts/LeafletContext'

interface PublicTransportStationsProps {
  startIds?: string[]
  endIds?: string[]
  visible: boolean
}

export default function PublicTransport({
  startIds = [],
  endIds = [],
  visible = false,
}: PublicTransportStationsProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useContext(LeafletContext)
  const layerRef = useRef<L.GeoJSON | null>(null)
  const [stations, setStations] = useState<
    PublicTransportStationWithStartEndFeatureCollection | undefined
  >()
  const [start, setStart] = useState<
    PublicTransportStationWithStartEndFeature | undefined
  >()
  const [end, setEnd] = useState<
    PublicTransportStationWithStartEndFeature | undefined
  >()

  useEffect(() => {
    const fetchAndSetPublicTransportStations = async (
      startIds: string[],
      endIds: string[]
    ) => {
      try {
        const results = await fetchPublicTransportStations([
          ...startIds,
          ...endIds,
        ])

        let mainStartStation:
          | PublicTransportStationWithStartEndFeature
          | undefined
        let mainEndStation:
          | PublicTransportStationWithStartEndFeature
          | undefined
        const featuresWithStartEnd = results.features.reduce((acc, station) => {
          const id = station.properties._id

          const isStart = startIds.includes(id)
          const isEnd = endIds.includes(id) || endIds.length === 0
          const isMain = [startIds[0], endIds[0]].includes(id)

          if (!isStart && !isEnd) return acc

          const stationWithStartEnd = addStartEndToPublicTransportFeature(
            station,
            {
              main: isMain,
              start: isStart,
              end: isEnd,
            }
          )

          if (isStart && isMain) {
            mainStartStation = stationWithStartEnd
          }
          if (isEnd && isMain) {
            mainEndStation = stationWithStartEnd
          }

          return [...acc, stationWithStartEnd]
        }, [] as PublicTransportStationWithStartEndFeature[])

        const featureCollection: PublicTransportStationWithStartEndFeatureCollection =
          createFeatureCollection(featuresWithStartEnd)

        setStations(featureCollection)

        setStart(mainStartStation)
        setEnd(mainEndStation)
      } catch (e) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
      }
    }
    if (startIds.length !== 0 || endIds.length !== 0) {
      fetchAndSetPublicTransportStations(startIds, endIds)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!state.map) return
    layerRef.current = L.geoJSON(stations, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          radius: feature.properties.main ? 8 : 4,
          fillColor: getPublicTransportLineColor(feature.properties.line),
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: feature.properties.main ? 0.8 : 0.2,
        })
      },

      onEachFeature: function (feature, layer: L.Layer) {
        if (feature.properties && feature.properties.name) {
          layer.bindPopup(feature.properties.name)
        }
      },
    }).addTo(state.map)
    return () => {
      if (layerRef && layerRef.current) {
        layerRef.current.remove()
        layerRef.current = null
      }
    }
  }, [state.map, stations])

  useEffect(() => {
    if (!state.map) return
    if (!layerRef || !layerRef.current) return
    if (!visible) {
      state.map.removeLayer(layerRef.current)
    } else {
      state.map.addLayer(layerRef.current)
    }
  }, [state.map, visible])

  return (
    <>
      {start && (
        <Typography component='p'>
          Start: <CityMapperLink feature={start} type='outbound' />
        </Typography>
      )}
      {end && (
        <Typography component='p'>
          End:
          <CityMapperLink feature={end} type='inbound' />
        </Typography>
      )}
    </>
  )
}
