import Grid from '@mui/material/Grid'
import React, { useContext } from 'react'
import { isAdmin } from '../../@digimap/lib/user'
import LeafletMap from '../../components/leaflet-react/components/LeafletMap'
import LeafletLocateControl from '../../components/leaflet-react/components/LocateControl'
import LeafletPOIsControl from '../../components/leaflet/POIsControl'
import { DEFAULT_MAP_OPTIONS } from '../../constants/leaflet'
import {
  freeMapProviders,
  freeMapOverlayProviders,
} from '../../constants/leafletMapProviders'
import { UserContext } from '../../state/user/UserProvider'

export default function POIsContainer() {
  const userContext = useContext(UserContext)

  return (
    <Grid container spacing={0} className='full-height-without-toolbar'>
      <Grid item xs={12}>
        <LeafletMap
          opts={DEFAULT_MAP_OPTIONS}
          mapProviders={isAdmin(userContext) ? undefined : freeMapProviders}
          overlayLayersProviders={
            isAdmin(userContext) ? undefined : freeMapOverlayProviders
          }
        />
        <LeafletLocateControl
          options={{
            position: 'topleft',
            setView: 'once',
            locateOptions: {
              maxZoom: 16,
              enableHighAccuracy: true,
            },
          }}
        />
        <LeafletPOIsControl />
      </Grid>
    </Grid>
  )
}
