import React, { useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { getBalladById } from '../../@digimap/lib/ballad'
import { isBalladCompleted } from '../../@digimap/lib/user'
import { BalladContext } from '../../state/ballads/BalladProvider'
import { UserContext } from '../../state/user/UserProvider'
import { userActions } from '../../state/user/userReducer'
import BalladDetails from './BalladDetails'

interface BalladPageProps
  extends RouteComponentProps<{
    id: string
  }> {}
function BalladDetailsContainer(props: BalladPageProps) {
  const { ballads } = useContext(BalladContext)
  const { completedIds, userDispatch } = useContext(UserContext)
  const ballad = getBalladById(props.match.params.id, ballads)
  const onCompletedChange = useCallback(
    (balladId: string, completed: boolean) => {
      if (completed) {
        userDispatch(userActions.markBalladAsCompleted(balladId))
      } else {
        userDispatch(userActions.unmarkBalladAsCompleted(balladId))
      }
    },
    [userDispatch]
  )

  if (!ballad) return <Redirect to='/not-found' />

  return (
    <BalladDetails
      ballad={ballad}
      completed={isBalladCompleted(ballad, completedIds)}
      onCompletedChange={onCompletedChange}
    />
  )
}

BalladDetailsContainer.displayName = 'BalladDetailsContainer'

export default BalladDetailsContainer
