/* global google */
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { isRouted } from '../../@digimap/lib/ballad'
import { Ballad, WayPoint } from '../../@digimap/types'
import { PublicTransportStationFeature } from '../../@digimap/types/publicTransport'
import { GoogleMapState } from '../../components/googlemaps-react/common/types'
import BicyclingLayer from '../../components/googlemaps-react/components/BicyclingLayer'
import CustomControl from '../../components/googlemaps-react/components/CustomControl'
import DirectionsRenderer from '../../components/googlemaps-react/components/DirectionsRenderer'
import EditablePolyline from '../../components/googlemaps-react/components/EditablePolyline'
import MapBox from '../../components/googlemaps-react/components/MapBox'
import GoogleMapsMarker from '../../components/googlemaps-react/components/Marker'
import GoogleMapsPolyline from '../../components/googlemaps-react/components/Polyline'
import StandaloneSearchBox from '../../components/googlemaps-react/components/StandaloneSearchBox'
import { GoogleMapContext } from '../../components/googlemaps-react/contexts/GoogleMapContext'
import FranceCommunesLayer from '../../components/googlemaps/FranceCommunes'
import ImageOverlay from '../../components/googlemaps/ImageOverlay'
import GoogleMapsPOIs from '../../components/googlemaps/POIs'
import {
  DEFAULT_MAP_OPTIONS,
  GOOGLE_MAPS_API_KEY,
} from '../../constants/googleMaps'
import useOverpass from '../../hooks/useOverpass'
import { getErrorMessage } from '../../lib/errors'
import { decodePath } from '../../lib/polylineEncoding'
import {
  deleteBallad,
  fetchPublicTransportStations,
  getBalladClosestPublicTransportStations,
  toggleBalladDraft,
  updateBalladPublicTransportStations,
  updateBalladTrack,
  updateBalladWayPoints,
} from '../../services'
import DeleteButton from './components/DeleteButton'
import PublicTransportSuggestionsList from './components/PublicTransportSuggestionsList'
import {
  default as PublicTransportReordableList,
  default as ReorderablePublicTransportList,
} from './components/ReorderablePublicTransportList'
import ReorderableWayPointsList from './components/ReorderableWayPointsList'
import {
  getDirectionsResultsDistanceAndDuration,
  getDirectionsResultsEncodedRoute,
} from './lib/directionsResultsInfos'
import { formatDistance, formatDuration } from './lib/format'
import getDirections from './lib/getDirections'
import wayPointsReducer, {
  addWayPoint,
  insertWayPointAt,
  removeLastWayPoint,
  removeWayPointAt,
  renameWayPointAt,
  reverseWayPoints,
  rotateWayPoints,
  setWayPoints,
  updateWayPointAt,
} from './state/wayPointReducer'

const WAYPOINTS_EDITABLE_POLYLINE = 'waypoints-editable-polyline'

const SEARCH_BOX_ID = 'search-box'
const getSearchBox = (
  state: GoogleMapState
): google.maps.places.SearchBox | undefined => {
  return state.objects.get(SEARCH_BOX_ID) as
    | google.maps.places.SearchBox
    | undefined
}

interface AdminBalladDetailsProps {
  ballad: Ballad
  onDelete: (balladId: string) => void
  onDraftToggle: (balladId: string, draft: boolean) => void
}

export default function AdminBalladDetails(props: AdminBalladDetailsProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useContext(GoogleMapContext)
  const { ballad } = props

  const [routePoints, setRoutePoints] = useState<google.maps.LatLngLiteral[]>(
    []
  )

  const [hasRoute, setHasRoute] = useState(isRouted(ballad))
  const [showRoute, setShowRoute] = useState(hasRoute)
  const [showCommunes, setShowCommunes] = useState(false)

  useMemo(() => {
    if (hasRoute && ballad.track) {
      const points = decodePath(ballad.track)

      // Can show route only if route has more than 2 points
      if (points.length >= 2) {
        setRoutePoints(points)
        setShowRoute(true)
      } else {
        setHasRoute(false)
        console.warn('Route has less than 2 points!')
      }
    }
  }, [ballad.track, hasRoute])

  const [wayPoints, wayPointsDispatch] = useReducer(
    wayPointsReducer,
    ballad.wayPoints || []
  )
  // Show WayPoints if there are wayPoints but no route
  const [showWayPoints, setShowWayPoints] = useState(
    !showRoute && Boolean(wayPoints.length)
  )
  const [editWayPoints, setEditWayPoints] = useState(false)

  const [distance, setDistance] = useState(ballad.distance)
  const [duration, setDuration] = useState(ballad.duration)
  const [directionsResults, setDirectionsResults] = useState<
    google.maps.DirectionsResult[]
  >([])
  const [showDirections, setShowDirections] = useState(
    Boolean(directionsResults.length)
  )

  useMemo(() => {
    if (directionsResults.length) {
      const { distance, duration } =
        getDirectionsResultsDistanceAndDuration(directionsResults)
      setDistance(distance)
      setDuration(duration)
    }
  }, [directionsResults])

  async function requestDirections(wayPoints: WayPoint[], type: string) {
    const wp = ballad.loop ? [...wayPoints, wayPoints[0]] : wayPoints
    const directionsResults = await getDirections(wp, type)
    setShowDirections(true)
    setDirectionsResults(directionsResults)
  }

  const [publicTransportSuggestions, setPublicTransportSuggestions] =
    useState<Object | null>(null)

  const [selectedPublicTransportStarts, setSelectedPublicTransportStarts] =
    useState<PublicTransportStationFeature[]>([])

  const [selectedPublicTransportEnds, setSelectedPublicTransportEnds] =
    useState<PublicTransportStationFeature[]>([])

  useEffect(() => {
    const fetchAndSetPublicTransportStations = async (
      ids: string[],
      isStart: boolean
    ) => {
      try {
        const { features } = await fetchPublicTransportStations(ids)
        const hashedResults: {
          [key: string]: PublicTransportStationFeature
        } = features.reduce((prev, s) => {
          return { ...prev, ...{ [s.properties._id]: s } }
        }, {})

        const sortedStations = ids.map((id, index) => {
          const { properties } = hashedResults[id]
          const newProperties = { ...properties, ...{ main: index === 0 } }
          return { ...hashedResults[id], ...{ properties: newProperties } }
        })
        if (isStart) {
          setSelectedPublicTransportStarts(sortedStations)
        } else {
          setSelectedPublicTransportEnds(sortedStations)
        }
      } catch (e) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
      }
    }
    if (
      ballad.publicTransportStartIds &&
      ballad.publicTransportStartIds.length > 0
    ) {
      fetchAndSetPublicTransportStations(ballad.publicTransportStartIds, true)
    }
    if (
      ballad.publicTransportEndIds &&
      ballad.publicTransportEndIds.length > 0
    ) {
      fetchAndSetPublicTransportStations(ballad.publicTransportEndIds, false)
    }
    // eslint-disable-next-line
  }, [])

  const { fetchPOIs, POIs, showPOIs, setShowPOIs } =
    useOverpass(GoogleMapContext)

  const [draft, setDraft] = useState<boolean>(props.ballad.draft)

  return (
    <Grid container spacing={0} className='full-height-without-toolbar'>
      <Grid item xs={4} style={{ overflow: 'auto', height: '100%' }}>
        <div style={{ height: '100%' }}>
          <Typography variant='h4' component='h1'>
            <Link to={`/ballads/${ballad._id}`}>{ballad.name}</Link>
          </Typography>
          {distance && (
            <Typography component='p'>
              Distance: {formatDistance(distance)}
            </Typography>
          )}
          {duration && (
            <Typography component='p'>
              Duration: {formatDuration(duration)}
            </Typography>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={draft}
                onChange={async (e) => {
                  setDraft(e.target.checked)
                  try {
                    const { ballad: updatedBallad } = await toggleBalladDraft(
                      ballad._id,
                      e.target.checked
                    )
                    props.onDraftToggle(updatedBallad._id, updatedBallad.draft)
                    enqueueSnackbar('Draft value successfully toggled', {
                      variant: 'success',
                    })
                  } catch (e) {
                    enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                  }
                }}
                value='draft'
                color='primary'
              />
            }
            label='Is Draft'
          />
          <Button variant='outlined' size='small' onClick={fetchPOIs}>
            Fetch POIs
          </Button>
          <DeleteButton
            onAgree={async () => {
              try {
                await deleteBallad(ballad._id)
                enqueueSnackbar('Ballad successfully deleted', {
                  variant: 'success',
                })
                props.onDelete(ballad._id)
              } catch (e) {
                enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
              }
            }}
          />
          <Button
            variant='outlined'
            size='small'
            href={`${window.location.href}/edit`}>
            Edit
          </Button>

          <Typography variant='h5' component='h3'>
            Overlays
          </Typography>
          <section>
            {ballad.images.map((overlay, index) => (
              <Link
                key={overlay.filename}
                to={`/admin/ballads/${ballad.slug}/overlay/${index + 1}`}>
                Overlay {index + 1}
              </Link>
            ))}
          </section>

          <Typography variant='h5' component='h3'>
            Directions
          </Typography>
          <section>
            <Button
              variant='outlined'
              size='small'
              disabled={wayPoints.length <= 2}
              onClick={() => requestDirections(wayPoints, ballad.type)}>
              Get Directions
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              size='small'
              disabled={directionsResults.length === 0}
              onClick={() => {
                const route =
                  getDirectionsResultsEncodedRoute(directionsResults)
                try {
                  // @todo await ?
                  updateBalladTrack(
                    ballad._id,
                    route,
                    distance as number,
                    duration as number
                  )
                  enqueueSnackbar('Route successfully saved', {
                    variant: 'success',
                  })
                } catch (e) {
                  enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                }
              }}>
              Save As Route
            </Button>
          </section>

          <Typography variant='h5' component='h3'>
            WayPoints
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={editWayPoints}
                onChange={(e) => {
                  setEditWayPoints(e.target.checked)
                  if (e.target.checked) {
                    setShowWayPoints(true)
                  }
                }}
                value='editWayPoints'
                color='primary'
              />
            }
            label='Edit Mode'
          />
          {editWayPoints && (
            <Button
              variant='outlined'
              size='small'
              disabled={wayPoints.length < 2}
              onClick={async () => {
                try {
                  const { ballad: updatedBallad } = await updateBalladWayPoints(
                    ballad._id,
                    wayPoints
                  )
                  wayPointsDispatch(setWayPoints(updatedBallad.wayPoints))
                  enqueueSnackbar('Waypoints successfully saved', {
                    variant: 'success',
                  })
                } catch (e) {
                  enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                }
              }}>
              Save WayPoints
            </Button>
          )}
          {editWayPoints && (
            <Button
              variant='outlined'
              size='small'
              disabled={wayPoints.length < 2}
              onClick={() =>
                wayPointsDispatch(reverseWayPoints(Boolean(ballad.loop)))
              }>
              Reverse WayPoints
            </Button>
          )}
          {editWayPoints && (
            <Button
              variant='outlined'
              size='small'
              disabled={wayPoints.length < 2}
              onClick={() => wayPointsDispatch(rotateWayPoints(1))}>
              Rotate WayPoints
            </Button>
          )}
          {editWayPoints && (
            <Button
              variant='outlined'
              size='small'
              disabled={wayPoints.length < 2}
              onClick={() => wayPointsDispatch(removeLastWayPoint())}>
              Remove Last WayPoint
            </Button>
          )}
          {editWayPoints && (
            <StandaloneSearchBox
              id={SEARCH_BOX_ID}
              placeholder='Search and add Waypoint'
              // bindingPosition="TOP_LEFT"
              style={{
                margin: '4px',
                padding: '10px',
                width: '300px',
              }}
              onPlacesChanged={() => {
                const searchBox = getSearchBox(state)
                if (!searchBox) return
                const places = searchBox.getPlaces()
                if (places.length === 0) {
                  return
                }

                if (
                  places.length === 1 &&
                  places[0].geometry &&
                  places[0].geometry.location
                ) {
                  const { lat, lng } = places[0].geometry.location
                  wayPointsDispatch(
                    addWayPoint({
                      lat: lat(),
                      lng: lng(),
                      name: places[0].name,
                    })
                  )
                }
              }}
            />
          )}
          <ReorderableWayPointsList
            wayPoints={wayPoints}
            canDrag={editWayPoints}
            onReorder={(wayPoints) => {
              wayPointsDispatch(setWayPoints(wayPoints))
            }}
            onRemove={(index) => {
              wayPointsDispatch(removeWayPointAt(index))
            }}
            onRename={(index, name) => {
              wayPointsDispatch(renameWayPointAt(index, name))
            }}
          />
          <Typography variant='h5' component='h3'>
            Public Transport
          </Typography>
          <Button
            variant='outlined'
            size='small'
            onClick={async () => {
              const startIds = selectedPublicTransportStarts.length
                ? selectedPublicTransportStarts.map((s) => s.properties._id)
                : undefined
              const endIds = selectedPublicTransportEnds.length
                ? selectedPublicTransportEnds.map((s) => s.properties._id)
                : undefined
              if (startIds || endIds) {
                try {
                  await updateBalladPublicTransportStations(ballad._id, {
                    ...(startIds && { startIds }),
                    ...(endIds && { endIds }),
                  })
                  enqueueSnackbar('Public Transport successfully saved', {
                    variant: 'success',
                  })
                } catch (e) {
                  enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                }
              }
            }}>
            Save Public Transport
          </Button>
          <Typography variant='h6' component='h4'>
            Start
          </Typography>
          <PublicTransportReordableList
            isStart={true}
            stations={selectedPublicTransportStarts}
            onReorder={(newStations) => {
              setSelectedPublicTransportStarts(newStations)
            }}
            onRemove={(selected: PublicTransportStationFeature) => {
              setSelectedPublicTransportStarts((prevSelected) =>
                prevSelected.filter(
                  (s) => s.properties._id !== selected.properties._id
                )
              )
            }}
            visible={true}
          />
          {ballad.loop === false && (
            <>
              <Typography variant='h6' component='h4'>
                End
              </Typography>
              <ReorderablePublicTransportList
                isStart={false}
                stations={selectedPublicTransportEnds}
                onReorder={(newStations) => {
                  setSelectedPublicTransportEnds(newStations)
                }}
                onRemove={(selected: PublicTransportStationFeature) => {
                  setSelectedPublicTransportEnds((prevSelected) =>
                    prevSelected.filter(
                      (s) => s.properties._id !== selected.properties._id
                    )
                  )
                }}
                visible={true}
              />
            </>
          )}
          {Array.isArray(publicTransportSuggestions) &&
          publicTransportSuggestions.length > 0 ? (
            <>
              <Typography variant='h6' component='h4'>
                Public Transport Start Suggestions:
              </Typography>
              <PublicTransportSuggestionsList
                stations={publicTransportSuggestions}
                selected={selectedPublicTransportStarts}
                onAdd={(selected: PublicTransportStationFeature) => {
                  setSelectedPublicTransportStarts((prevSelected) => [
                    ...prevSelected,
                    selected,
                  ])
                }}
                onRemove={(selected: PublicTransportStationFeature) => {
                  setSelectedPublicTransportStarts((prevSelected) =>
                    prevSelected.filter(
                      (s) => s.properties._id !== selected.properties._id
                    )
                  )
                }}
              />
              {ballad.loop === false && (
                <>
                  <Typography variant='h6' component='h4'>
                    Public Transport End Suggestions:
                  </Typography>
                  <PublicTransportSuggestionsList
                    stations={publicTransportSuggestions}
                    selected={selectedPublicTransportEnds}
                    onAdd={(selected: PublicTransportStationFeature) => {
                      setSelectedPublicTransportEnds((prevSelected) => [
                        ...prevSelected,
                        selected,
                      ])
                    }}
                    onRemove={(selected: PublicTransportStationFeature) => {
                      setSelectedPublicTransportEnds((prevSelected) =>
                        prevSelected.filter(
                          (s) => s.properties._id === selected.properties._id
                        )
                      )
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              variant='outlined'
              size='small'
              onClick={async () => {
                try {
                  const { nearestPath } =
                    await getBalladClosestPublicTransportStations(ballad._id)
                  setPublicTransportSuggestions(nearestPath)
                } catch (e) {
                  enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                }
              }}>
              Get Suggestions
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={8}>
        <MapBox
          region='FR'
          language='fr'
          apiKey={GOOGLE_MAPS_API_KEY}
          opts={{
            ...DEFAULT_MAP_OPTIONS,
            ...{
              mapTypeControl: true,
              mapTypeControlOptions: {
                // style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                style: 2, //google.maps.MapTypeControlStyle.DROPDOWN_MENU, google.maps.MapTypeControlStyle.HORIZONTAL_BAR
                mapTypeIds: ['roadmap', 'terrain', 'satellite'],
              },
            },
          }}
          onBoundsChanged={() => {
            const searchBox = getSearchBox(state)
            if (state.map && searchBox) {
              const mapBounds = state.map.getBounds()
              if (mapBounds) searchBox.setBounds(mapBounds)
            }
          }}
          usePlaces
        />
        <CustomControl>
          <Paper style={{ margin: 12, padding: 8 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showWayPoints}
                  onChange={(e) => {
                    setShowWayPoints(e.target.checked)
                  }}
                  value='showWayPoints'
                  color='primary'
                />
              }
              label='WayPoints'
            />
            <FormControlLabel
              disabled={!hasRoute}
              control={
                <Switch
                  checked={showRoute}
                  onChange={(e) => {
                    setShowRoute(e.target.checked)
                  }}
                  value='showRoute'
                  color='primary'
                />
              }
              label='Route'
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={directionsResults.length === 0}
                  checked={showDirections}
                  onChange={(e) => {
                    setShowDirections(e.target.checked)
                  }}
                  value='showDirections'
                  color='primary'
                />
              }
              label='Directions'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showPOIs}
                  onChange={(e) => {
                    setShowPOIs(e.target.checked)
                  }}
                  value='showPOIs'
                  color='primary'
                />
              }
              label='POIs'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showCommunes}
                  onChange={(e) => {
                    setShowCommunes(e.target.checked)
                  }}
                  value='showCommunes'
                  color='primary'
                />
              }
              label='Communes'
            />
          </Paper>
        </CustomControl>
        {ballad.images.map((image) => (
          <ImageOverlay image={image} key={image.filename} />
        ))}
        {ballad.type === 'ride' && <BicyclingLayer />}
        {showPOIs && <GoogleMapsPOIs POIs={POIs} />}
        <EditablePolyline
          id={WAYPOINTS_EDITABLE_POLYLINE}
          opts={{
            path: wayPoints.map(({ lat, lng }) => ({ lat, lng })),
            visible: showWayPoints,
            strokeColor: '#474753',
            editable: editWayPoints,
          }}
          onRightClick={(e: google.maps.PolyMouseEvent) => {
            if (e.vertex) {
              const polyline = state.objects.get(
                WAYPOINTS_EDITABLE_POLYLINE
              ) as google.maps.Polyline
              polyline.getPath().removeAt(e.vertex)
            }
          }}
          onPathSetAt={(index, latLng) => {
            wayPointsDispatch(updateWayPointAt(index, latLng))
          }}
          onPathInsertAt={(index, latLng) => {
            wayPointsDispatch(insertWayPointAt(index, latLng))
          }}
          onPathRemoveAt={(index) => {
            wayPointsDispatch(removeWayPointAt(index))
          }}
        />
        {showRoute && (
          <>
            <GoogleMapsPolyline
              key='route-polyline'
              opts={{
                path: routePoints,
                visible: showRoute,
                strokeColor: '#FF0000',
                icons: [10, 20, 30, 40, 50, 60, 70, 80, 90].map((offset) => ({
                  icon: {
                    path: 1,
                    fillOpacity: 1,
                  },
                  offset: `${offset}%`,
                })),
              }}
            />
            <GoogleMapsMarker
              key='route-start'
              opts={{
                position: routePoints[0],
                icon: '/route-start.png',
              }}
            />
            <GoogleMapsMarker
              key='route-end'
              opts={{
                position: routePoints[routePoints.length - 1],
                icon: '/route-end.png',
              }}
            />
          </>
        )}
        {showDirections &&
          directionsResults.map((directionsResult, index) => (
            <DirectionsRenderer
              key={`directions-result-${index}`}
              directionsResult={directionsResult}
              draggable={false}
            />
          ))}
        <FranceCommunesLayer visible={showCommunes} />
      </Grid>
    </Grid>
  )
}
