import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import MUILink from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LatLngLiteral } from 'leaflet'
import { useSnackbar } from 'notistack'
import { useContext, useMemo, useState } from 'react'
import { isRouted } from '../../@digimap/lib/ballad'
import { Ballad } from '../../@digimap/types'
import DownloadGpxButton from '../../components/DownloadGpxButton'
import CustomControl from '../../components/leaflet-react/components/CustomControl'
import LeafletMap from '../../components/leaflet-react/components/LeafletMap'
import LeafletLocateControl from '../../components/leaflet-react/components/LocateControl'
import BalladOverlays from '../../components/leaflet/BalladOverlays'
import BalladPublicTransport from '../../components/leaflet/BalladPublicTransport'
import LeafletBalladRoute from '../../components/leaflet/BalladRoute'
import LeafletPOIsControl from '../../components/leaflet/POIsControl'
import { DEFAULT_MAP_OPTIONS } from '../../constants/leaflet'
import { getErrorMessage } from '../../lib/errors'
import { decodePath } from '../../lib/polylineEncoding'
import { toggleBalladCompleted } from '../../services'
import {
  formatDistance,
  formatDuration,
} from '../AdminBalladDetailsPage/lib/format'
import getGoogleMapsDirectionsUrl from '../AdminBalladDetailsPage/lib/getGoogleMapsDirectionsUrl'
import useMapFitBalladBounds from './hooks/useMapFitBalladBounds'
import { GpxContext } from '../../state/gpx/GpxProvider'
import GpxFilesList from '../AdminGpxHistoryPage/components/GpxFilesList'

interface BalladDetailsProps {
  ballad: Ballad
  completed: boolean
  onCompletedChange: (balladId: string, completed: boolean) => void
}

export default function BalladDetails(props: BalladDetailsProps) {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const breakpointsUp = useMediaQuery(theme.breakpoints.up('md'))

  const { ballad } = props

  const [hasRoute] = useState(isRouted(ballad))
  const [showRoute, setShowRoute] = useState(hasRoute)
  const [routePoints, setRoutePoints] = useState<LatLngLiteral[]>([])

  const [displayOverlay] = useState(ballad.type === 'hike')
  const [overlayOpacity, setOverlayOpacity] = useState(100)

  const [completed, setCompleted] = useState<boolean>(props.completed)

  useMapFitBalladBounds(ballad)

  useMemo(() => {
    if (ballad.track) {
      const points = decodePath(ballad.track)
      if (points.length >= 2) {
        setRoutePoints(points)
        setShowRoute(true)
      } else {
        enqueueSnackbar('Route has less than 3 points', { variant: 'error' })
      }
    }
  }, [ballad.track, enqueueSnackbar])

  const { gpx: gpxFiles } = useContext(GpxContext)
  const balladGpxFiles = gpxFiles.filter(({ ballads }) => {
    if (!ballads) return false
    return ballads.includes(ballad._id)
  })

  return (
    <Grid
      container
      spacing={0}
      direction={breakpointsUp ? 'row' : 'column'}
      className='full-height-without-toolbar'>
      <Grid item>
        <Typography variant='h5' component='h1'>
          {ballad.name}
        </Typography>
        {ballad.distance && (
          <Typography component='p'>
            Distance: {formatDistance(ballad.distance)}
          </Typography>
        )}
        {ballad.duration && (
          <Typography component='p'>
            Duration: {formatDuration(ballad.duration)}
          </Typography>
        )}
        <BalladPublicTransport
          startIds={ballad.publicTransportStartIds}
          endIds={ballad.publicTransportEndIds}
          visible={true}
        />
        {isRouted(ballad) && (
          <div>
            <DownloadGpxButton
              path={routePoints}
              name={ballad.name}
              disabled={!Boolean(routePoints.length)}
            />
            {ballad.wayPoints && (
              <MUILink
                href={getGoogleMapsDirectionsUrl(ballad.wayPoints, ballad.loop)}
                target='_blank'>
                See on Google Maps
              </MUILink>
            )}
          </div>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={completed}
              onChange={async (e) => {
                const newCompleted = e.target.checked
                setCompleted(newCompleted)
                try {
                  props.onCompletedChange(ballad._id, newCompleted)
                  await toggleBalladCompleted(ballad._id, newCompleted)
                  enqueueSnackbar('Ballad completion successfully toggled', {
                    variant: 'success',
                  })
                } catch (e) {
                  enqueueSnackbar(getErrorMessage(e), { variant: 'error' })
                }
              }}
              value='completed'
              color='primary'
            />
          }
          label='Completed'
        />
        {balladGpxFiles && (
          <GpxFilesList gpxFiles={balladGpxFiles} defaultVisibility={false} />
        )}
      </Grid>
      <Grid item style={{ flexGrow: 1, display: 'flex' }}>
        <LeafletMap
          opts={DEFAULT_MAP_OPTIONS}
          style={{ flex: '1', height: 'auto' }}
        />
        {showRoute && (
          <LeafletBalladRoute
            latlngs={routePoints}
            options={{
              color: '#FF5722',
            }}
          />
        )}
        <LeafletPOIsControl />
        <LeafletLocateControl
          options={{
            position: 'topleft',
            setView: false,
            locateOptions: {
              maxZoom: 16,
              enableHighAccuracy: true,
            },
          }}
        />
        {displayOverlay && (
          <CustomControl position='bottomleft'>
            <Paper
              style={
                breakpointsUp
                  ? { padding: '2px 6px 0px 6px' }
                  : { padding: '10px 6px 8px 6px' }
              }>
              <input
                style={{ padding: '2px' }}
                type='range'
                min={0}
                max={100}
                step={10}
                value={overlayOpacity}
                onChange={(event) => {
                  const value = +event.target.value
                  if (typeof value === 'number') {
                    setOverlayOpacity(value)
                  }
                }}
              />
            </Paper>
          </CustomControl>
        )}

        {displayOverlay && (
          <BalladOverlays images={ballad.images} opacity={overlayOpacity} />
        )}
      </Grid>
    </Grid>
  )
}

BalladDetails.displayName = 'BalladDetails'
BalladDetails.whyDidYouRender = true
