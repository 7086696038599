import List from '@mui/material/List'
import { Gpx } from '../../../@digimap/types'
import LeafletGpx from '../../../components/leaflet/Gpx'
import { GPX_ENDPOINT } from '../../../constants/endpoints'
import useVisible from '../../../hooks/useVisible'
import GpxFilesListItem from './GpxFilesListItem'

interface GpxFilesListProps {
  gpxFiles: Gpx[]
  defaultVisibility?: boolean
}

export default function GpxFilesList({
  gpxFiles,
  defaultVisibility = true,
}: GpxFilesListProps) {
  const { isVisible, setLayerVisible } = useVisible(
    gpxFiles.map(({ filename }) => filename),
    defaultVisibility
  )

  return (
    <>
      <List>
        {gpxFiles.map((gpxFile) => (
          <GpxFilesListItem
            key={gpxFile._id}
            gpxFile={gpxFile}
            visible={isVisible(gpxFile.filename)}
            onToggleVisible={setLayerVisible}
          />
        ))}
      </List>
      {gpxFiles.map((gpxFile) => {
        return (
          <LeafletGpx
            key={gpxFile.filename}
            content={`${GPX_ENDPOINT}/${gpxFile.filename}`}
            centerAndZoom={false}
            visible={isVisible(gpxFile.filename)}
          />
        )
      })}
    </>
  )
}
