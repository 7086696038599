import React, { useReducer } from 'react'
import publicTransportReducer, {
  initialState,
  PublicTransportAction,
  PublicTransportState,
} from './publicTransportReducer'

const PublicTransportContext = React.createContext<
  PublicTransportState & {
    publicTransportDispatch: React.Dispatch<PublicTransportAction>
  }
>({ ...initialState, publicTransportDispatch: () => {} })

function PublicTransportProvider(props: {
  children: React.ReactNode | React.ReactNodeArray
}) {
  const [publicTransportState, publicTransportDispatch] = useReducer(
    publicTransportReducer,
    initialState
  )

  return (
    <PublicTransportContext.Provider
      value={{ ...publicTransportState, publicTransportDispatch }}>
      {props.children}
    </PublicTransportContext.Provider>
  )
}
PublicTransportProvider.displayName = 'PublicTransportProvider'
PublicTransportProvider.whyDidYouRender = {
  logOnDifferentValues: true,
}

export { PublicTransportContext, PublicTransportProvider }
