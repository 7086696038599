import { FeatureCollection, Polygon } from 'geojson'

type CommunesProperties = {
  _id: string
  nom: string
  code: string
}
export type CommunesDatabase = {
  geometry: Polygon
} & CommunesProperties

export type CommunesFeatureCollection = FeatureCollection<
  Polygon,
  CommunesProperties
>

export function createCommunesFeatureCollection(
  communes: CommunesDatabase[]
): CommunesFeatureCollection {
  return {
    type: 'FeatureCollection',
    features: communes.map((c) => ({
      type: 'Feature',
      geometry: c.geometry,
      properties: {
        nom: c.nom,
        code: c.code,
        _id: c._id,
      },
    })),
  }
}
