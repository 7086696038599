import { Ballad } from '../../../@digimap/types'

const isBalladDistanceBetween = (
  ballad: Ballad,
  distanceFilter: string
): boolean => {
  const [distanceMin, distanceMax] = distanceFilter.split('-')

  if (ballad.distance === undefined) return false
  const balladDistance = ballad.distance / 1000
  const distanceMinOk = !distanceMin || balladDistance >= +distanceMin
  const distanceMaxOk = !distanceMax || balladDistance <= +distanceMax
  return distanceMinOk && distanceMaxOk
}

export default isBalladDistanceBetween
