export default function getPublicTransportLineColor(line: string) {
  switch (line) {
    case 'RER A':
      return '#D1302F'
    case 'RER B':
      return '#427DBD'
    case 'RER C':
      return '#FCD946'
    case 'RER D':
      return '#5E9620'
    case 'RER E':
      return '#BD76A1'
    case 'LIGNE H':
      return '#7B4339'
    case 'LIGNE J':
      return '#CDCD00'
    case 'LIGNE K':
      return '#C7B300'
    case 'LIGNE L':
      return '#7584BC'
    case 'LIGNE N':
      return '#00A092'
    case 'LIGNE P':
      return '#F0B600'
    case 'LIGNE R':
      return '#E4B4D1'
    case 'LIGNE U':
      return '#D60058'
    default:
      return '#000000'
  }
}
