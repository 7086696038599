interface OSMBaseElement {
  id: number
  tags: {
    [key: string]: string | number | undefined
    name?: string
    wikipedia?: string
    website?: string
    heritage?: string
    wikidata?: string
    tourism?: string
    historic?: string
  }
}

interface OSMNodeElement extends OSMBaseElement {
  type: 'node'
  lat: number
  lon: number
}

interface OSMWayOrRelationElement extends OSMBaseElement {
  type: 'way' | 'relation'
  center: {
    lat: number
    lon: number
  }
}

export type OSMElement = OSMNodeElement | OSMWayOrRelationElement

export const isOSMNodeElement = (
  element: OSMElement
): element is OSMNodeElement => {
  return element.type === 'node'
}

const whiteListedTableTags = [
  'heritage',
  'amenity',
  'tourism',
  'historic',
  'wikidata',
  'wikipedia',
  'website',
]

export const whitelistTags = ['name', ...whiteListedTableTags]

function getAnchorElement(name: string, link: string) {
  const anchor = document.createElement('a')
  anchor.href = link
  anchor.target = '_blank'
  anchor.appendChild(document.createTextNode(name))
  return anchor
}
function getWikipediaLink(wikipediaTag: string) {
  const name = wikipediaTag
  const link = `https://www.wikipedia.org/wiki/${wikipediaTag}`
  return getAnchorElement(name, link)
}

function getWebsiteLink(websiteTag: string) {
  const name = websiteTag.replace(/^https?:\/\//, '').substring(0, 24)
  return getAnchorElement(name, websiteTag)
}

function getOSMLink(element: OSMElement) {
  const { id, type } = element
  const name = 'OSM'
  const link = `https://www.openstreetmap.org/${type}/${id}`
  return getAnchorElement(name, link)
}

function getWikidataLink(wikidataTag: string) {
  // It is also possible to get the wikidata as json value
  // `https://www.wikidata.org/wiki/Special:EntityData/${wikidataTag}.json`
  return getAnchorElement(
    'wikidata link',
    `https://www.wikidata.org/wiki/${wikidataTag}`
  )
}

function getNameTextNode(name: string) {
  const div = document.createElement('div')
  div.style.marginBottom = '15px'
  div.style.textAlign = 'center'
  div.style.fontWeight = 'bold'
  div.appendChild(document.createTextNode(name))
  return div
}

export function getIconUrl({ tags }: OSMElement) {
  let iconUrl = '/pois/default.png'
  if (tags.heritage === '1') {
    iconUrl = '/pois/historic-worldheritage.png'
  } else if (tags.historic === 'castle') {
    iconUrl = '/pois/historic-castle.png'
  } else if (tags.tourism === 'viewpoint') {
    iconUrl = '/pois/tourism-viewpoint.png'
  } else if (tags.tourism === 'museum') {
    iconUrl = '/pois/tourism-museum.png'
  } else if (tags.tourism === 'artwork') {
    if (tags.artwork_type === 'statue') {
      iconUrl = '/pois/tourism-artwork-statue.png'
    } else {
      iconUrl = '/pois/tourism-artwork.png'
    }
  } else if (tags.tourism === 'gallery') {
    iconUrl = '/pois/tourism-gallery.png'
  } else if (tags.tourism === 'information') {
    iconUrl = '/pois/tourism-information.png'
  } else if (tags.tourism === 'attraction') {
    iconUrl = '/pois/tourism-attraction.png'
  } else if (tags.tourism === 'theme_park') {
    iconUrl = '/pois/tourism-theme_park.png'
  } else if (tags.historic === 'monument') {
    iconUrl = '/pois/historic-memorial-or-monument.png'
  } else if (tags.amenity === 'place_of_worship') {
    iconUrl = '/pois/amenity-place-of-worship.png'
  } else if (tags.historic === 'tomb') {
    iconUrl = '/pois/historic-tomb.png'
  } else if (tags.heritage === '2' || tags.heritage === '3') {
    iconUrl = '/pois/historic-heritage.png'
  }
  return iconUrl
}

export function getInfoTable(element: OSMElement) {
  const { tags } = element
  const name = tags.name || tags.wikipedia || tags.website || 'Unnamed'

  const table = document.createElement('table')
  table.style.border = '1px solid #ccc'
  table.style.borderSpacing = '0'
  table.style.borderCollapse = 'separate'

  const sortedAndFilteredKeys = [
    'osm',
    ...Object.keys(tags)
      .filter((k) => whiteListedTableTags.includes(k))
      .sort()
      .reverse(),
  ]
  let odd = true
  for (const key of sortedAndFilteredKeys) {
    odd = !odd
    const row = table.insertRow(0)
    const cell1 = row.insertCell(0)
    const cell2 = row.insertCell(1)

    row.style.background = odd ? '#efefef' : '#fff'
    cell1.style.padding = '5px 10px'
    cell2.style.padding = '5px 10px'

    cell1.appendChild(document.createTextNode(key))

    let cell2Value: Text | HTMLAnchorElement = document.createTextNode('')
    const tagValue = tags[key]
    if (key === 'wikipedia' && tags.wikipedia) {
      cell2Value = getWikipediaLink(tags.wikipedia)
    } else if (key === 'wikidata' && tags.wikidata) {
      cell2Value = getWikidataLink(tags.wikidata)
    } else if (key === 'osm') {
      cell2Value = getOSMLink(element)
    } else if (key === 'website' && tags.website) {
      cell2Value = getWebsiteLink(tags.website)
    } else if (tagValue) {
      cell2Value = document.createTextNode(tagValue.toString())
    }
    cell2.appendChild(cell2Value)
  }

  const div = document.createElement('div')
  div.appendChild(getNameTextNode(name))
  div.appendChild(table)

  return div
}
